import { Auth } from 'aws-amplify';
import React from 'react';
var accountId = '';
class AuthService extends React.Component{

    async getCurrentUser() {        
        try{
            const userProfileData = await Auth.currentAuthenticatedUser();
            //console.log("User group of logged-in user: ",userProfileData.signInUserSession.accessToken.payload['cognito:groups']);
            //console.log("Username of logged-in user: ",userProfileData.username);
            accountId = userProfileData.signInUserSession.accessToken.payload['cognito:groups'][0]            
            //console.log("set state tenantNameState:",accountId);
            var userList = {'accountId':accountId,'userName':userProfileData.username};
            return userList;
        }catch(err) {
            console.log(err);           
        }
    }
}
export default new AuthService();