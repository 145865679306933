import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Modal, Select, Input } from 'antd';
import SharedService from '../../../services/SharedService';
import '../../ota-update/otaUpdate.scss';
const { Option } = Select;

var moment = require('moment'); // require

export class DownloadResponse extends React.Component {
    formRef = React.createRef();
    constructor(props) {

        super(props)
        this.state = {
            visible: false,
            groupName: '',
            deviceList: [],
        };
    }

    onCancel = () => {
        this.setState({ visible: false });
        this.setState({ groupName: '' });
        this.props.parentCallback(false);
    }

    onChange(e) {
        this.setState({ groupName: e.target.value });
        this.checkFieldValidation();
    }

    //Submit function
    createDeviceGroup = () => {
        console.log('in create');
        setTimeout(() => {
            this.setState({ visible: false });
            this.props.parentCallback(false);
        }, 3000);
        let actionType = 'add';
        console.log('groupName:: ', this.state.groupName, ' --Selected Device Name:: ', this.state.selectedDeviceUdi);
        try {
            SharedService.createDeviceGroup(this.state.groupName, this.state.selectedDeviceUdi, actionType)
                .then((createJobResponse) => {
                    console.log('createJobResponse:: ', createJobResponse);
                });

        } catch (err) {
            console.log('Error occured Create Device Group:: ', err);
        }
    }

    handleDeviceSelect(e) {
        console.log('device list--',e)
        this.setState({ selectedDeviceUdi: e })   
    }

    //Submit function
    closeModal = () => {
        this.setState({ visible: false });
        this.props.parentCallback(false);
    }

    handleGroupNameChange(e) {
        console.log('group', e.target.value)
        this.setState({ groupName: e.target.value });
    }

    componentDidUpdate(prevProps) {
        console.log('props device', this.props.deviceListData)
        if (prevProps.visible !== this.props.visible) {
            this.setState({ visible: this.props.visible });
        }
    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                <Modal
                    centered={true}
                    open={this.state.visible}
                    title='Create Device Group'
                    headStyle={{ backgroundColor: 'grey' }}
                    onOk={this.createDeviceGroup}
                    onCancel={this.onCancel}
                    width={400}
                    bodyStyle={{
                        height: 220
                    }}
                    footer={[
                        <Button key="back" onClick={this.onCancel}>
                            Cancel
                        </Button>,
                        <>&nbsp;
                            <Link to='/deviceGroup'><Button key="submit" type="primary" loading={this.state.loading} onClick={this.createDeviceGroup}>
                                Submit
                            </Button></Link>
                        </>
                    ]}
                >

                    <Form layout="vertical" requiredMark={true} name="control-ref" >

                        <Form.Item
                            className="col-xl-6 col-lg-6 col-md-6 col-sm-12"
                            name="Group Name" label="Group Name" rules={[{ required: true, },]} >
                            <span>
                                <Input placeholder="Enter Group Name" value={this.state.groupName}
                                    style={{ width: 350 }}  onChange={(e) => this.handleGroupNameChange(e)} />
                            </span>
                        </Form.Item>

                        <Form.Item
                            name="Device"
                            label="Select one or more device(s)" >
                            <Select mode="multiple" placeholder="Select Device(s)"  maxTagCount="responsive" allowClear showArrow style={{ width: '100%' }}  value={this.state.selectedDeviceUdi}
                                onChange={(e) => this.handleDeviceSelect(e)} >
                                {this.props.deviceListData.map((make, index) => (
                                    <Option key={make.deviceId} value={make.udi}>{make.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default React.memo(DownloadResponse);