import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { SyncOutlined } from '@ant-design/icons';
var moment = require('moment'); // require

export class TimeseriesChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tsDataSeries: [],
      tsOptionsData: {},
      tsRefreshStatus: true,
      options: {
        chart: {
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            offsetX: -10,
          }
        },
        noData: {
          text: 'No Data Available',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: 'Open sans,sans-serif', 
          }
        },
        grid: {
          show: true,
          borderColor: '#DEDEDE',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
        },
        stroke: {
          width: [1, 0],
        },
        title: {
          text: 'ClO2 level',
          offsetY: 0,
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [2]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
          type: 'datetime',
        },
        yaxis: [{
          title: {
            text: 'ClO2 (ppb)',
            fontWeight:  400,
            color:  '#263238'
          },
        }, {
          opposite: true,
          title: {
            text: 'Shot Size (μL)',
            fontWeight:  400,
            color:  '#263238'
          }
        }]
      },
    };
  }

  componentDidMount() {
    this.setState({ tsRefreshStatus: true });
    this.prepareTSData(this.props.tsLabel, this.props.tsClo2Data, this.props.tsShotSizeData,this.props.timeFilter,this.props.tsHumidityData);
  }
  prepareTSData = (labelData, clo2Data, shotSizeData,timeFilter,humidityData) => {
   // console.log('hum',clo2Data,humidityData)
    this.setState({ tsRefreshStatus: true });
    var series = [{
      name: 'ClO2',
      type: 'line',
      data: clo2Data,
      color: '#7159A6'
    },
    {
      name: 'Humidity',
      type: 'line',
      data: humidityData,
      color: '#c393f0'
    },  
    {
      name: 'Generation (by Shot Size)',
      type: 'scatter',
      data: shotSizeData,
      color: '#FE8479'
    }
  ];

    var options2 = {
      chart: {
        parentHeightOffset: -10,
        redrawOnParentResize: true,
        type: 'line',
        animations: {
          enabled: false,
        },
        stacked: false,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show:true,
          offsetX: 15,
          offsetY: -5,
        }
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        show: true,
        position: 'top',
        offsetY:-40,
        offsetX:8,
        fontSize: '11px',
        horizontalAlign: 'right',
        onItemClick: {
          toggleDataSeries: true
      },
      },
      stroke: {
        show: true,
        curve: 'straight',
        width: [3,2, 6],
        colors:['#7159A6'],
        strokeLineCap: 'round',
      },
      title: {
        text: 'ClO2 Level',
        offsetX:0,
        offsetY: 10,
        style: {
          fontSize:  '0.825rem',
          fontFamily: 'Open sans,sans-serif', 
          fontWeight:700,   
          color: '#000000',
        },
      },
      grid: {
        show: true,
        borderColor: '#DEDEDE',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: true,
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          top: -18,
          right: 0,
          bottom: 0,
          left: 0
        },
      },
    labels: labelData,
      xaxis: {
        type: 'datetime',
        //categories: [labelData],
        //range: 'XAXISRANGE',
        hideOverlappingLabels: true,        
        tickAmount: 25,
        forceNiceScale: true,
        labels: {
          show: true,          
          rotate:-45,
          rotateAlways:true,
          showDuplicates: false,
          hideOverlappingLabels:true,
         
          //format: 'HH:mm:ss',
        //   datetimeFormatter: {
        //     year: 'yyyy',
        //     month: "MMM 'yy",
        //     day: 'dd MMM',
        //     hour: 'HH:mm:ss',
        //     min:'HH:mm:ss'
        // },
          datetimeUTC: false,
          formatter: function(val, timestamp) {
            if(timeFilter <= 60){
              //console.log('val--',val,'timestamp--',timestamp,timeFilter)
              return moment(timestamp).format("HH:mm:ss");
            }
            else if(timeFilter > 60 && timeFilter <= 720){
              return moment(timestamp).format("HH:mm");
            }
            else if(timeFilter > 720){
              return moment(timestamp).format("DD MMM HH:mm");
            }    
        }
        
        }
      },
      yaxis: [{
        min:0,
        seriesName: 'ClO2',
        forceNiceScale: true,
        showAlways:true,
        title: {
          text: 'ClO2 (ppb)',
          offsetX: -10,
          style: {
            fontSize: '12px',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-title',
        },
        }, 
        labels: {
          align:'left',
          offsetX: -8,
        },       
        tickAmount: 10,
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
        },   
      },
      {
        forceNiceScale: true,
        showAlways:false,
        opposite: true,
        tickAmount: 10,
        min:0,
        seriesName: 'Humidity',
        show: false,
        },
       {
        showForNullSeries: true,
        forceNiceScale: true,
        showAlways:true,
        opposite: true,
        seriesName: 'Humidity',
        min: 0,
        tickAmount: 10,
        labels: {
          align:'right',
          offsetX: -8,
        },  
        title: {        
            text: 'Humidity (%) / Shot Size (μL)', 
            offsetX: 10,         
            style: {
              fontSize: '12px',            
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-title',
          },          
        },     
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
        },
        markers: {
          size: [6, 6]
        },
       
      }
    ]
    };
    this.setState({ tsDataSeries: series });
    this.setState({ options: options2 });
    this.setState({ tsRefreshStatus: false });
  }

  
  componentDidUpdate(prevProps) {
    if (prevProps.tsLabel !== this.props.tsLabel || prevProps.tsClo2Data !== this.props.tsClo2Data || 
      prevProps.tsShotSizeData != this.props.tsShotSizeData || prevProps.timeFilter !== this.props.timeFilter || prevProps.tsHumidityData !== this.props.tsHumidityData){
      this.setState({ tsRefreshStatus: true });
      if (this.props.tsLabel !== undefined) {
        this.setState({ tsRefreshStatus: true });
        this.prepareTSData(this.props.tsLabel, this.props.tsClo2Data, this.props.tsShotSizeData,this.props.timeFilter,this.props.tsHumidityData);
      }
    }
  }
  render() {
    return (
      <div className="card">
        <div className="card-body">
          {this.props.tsRefreshStatus === true ? <SyncOutlined style={{ float: 'right',marginRight:'-1rem' ,marginTop:'-0.5rem'}} spin /> : <SyncOutlined spin style={{ color: 'white', float: 'right'
        ,marginRight:'-1rem' ,marginTop:'-0.5rem' }} />}        
            <ReactApexChart options={this.state.options} series={this.state.tsDataSeries} type="line" height={400} />          
        </div>
      </div>
    )
  }
}
export default TimeseriesChartComponent;