import React from 'react';
import DeviceService from "../../services/DeviceService";
import SharedService from '../../services/SharedService';
import OTARuntimeService from '../../services/OTARuntimeService';
import { Dropdown as DropdownReact } from 'react-bootstrap';
import { Menu, Dropdown, Select, Affix } from 'antd';
import DeviceMeta from '../../utils/components/modalComponent/deviceMeta';
import FleetOtaUpdate from '../../utils/components/modalComponent/fleetOtaUpdate';
import 'antd/dist/antd.css';
import { DownOutlined, SyncOutlined } from '@ant-design/icons';
import { GuageCardComponent } from '../../utils/components/guageCardComponent';
import { BreadcrumbComponent } from '../../utils/components/breadcrumbComponent';
import { TreeSelect } from 'antd';
import FleetCommand from '../../utils/components/modalComponent/fleetCommand';
import LocationTree from '../../utils/functions/useLocationTree';
import LocationService from '../../services/LocationService';

const { Option } = Select;
var chickletRoutingId = null;
// var leafNodesMap = [];
// var parentNodeMap = [];
let tempLocationIdsList = [];
const { SHOW_PARENT } = TreeSelect;
// var treeData = [];
// var resultTreeHierarchyList = [];
// var locationTreeIterationControl=0;

export class FleetDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      deviceId: [],
      deviceData: [],
      uniqueLocations: [],
      deviceTelemetryMapData: [],
      fleetDeviceMapData: [],
      fleetDeviceMapDataCompareState: [],
      totalDevices: [],
      shadow: [],
      solid: [],
      locationData: [],
      celesteCount: [],
      whiteCount: [],
      tealCount: [],
      peachCount: [],
      leafMap: {},
      locationDevicesId: 'All Locations',
      refreshState: [],
      value: [],
      parentDataState: {},
      data: [],
      modeColorState: '',
      modesList: [],
      open: false,
      deviceMetaData: {},
      controllerVersion: "",
      commsVersion: "",
      refreshIntervalState: 30000,
      modeData: [],
      selectedRefreshValue: "30s",
      selectedMode: [],
      fleetDataForModeFilterState: [],
      fleetCommandState: [],
      userRole: '',
      series: [],
      top: 100,
      visible: false,
      targetFirmwareCommsList: [],
      targetFirmwareControlsList: [],
      firmwareType: '',
      username: '',
      target: '',
      devicesForFleetCommand:[],
      fleetCommandModalVisible: false,
      locationTree: [],
      openMobileFilterBar: false,
      masterLocationData:[]
    }
    this.data = { deviceId: chickletRoutingId };
    tempLocationIdsList = [];
    // resultTreeHierarchyList = [];

  }

  radialOptions = {
    chart: {
      type: 'radialBar',
      sparkline: {
        enabled: true
      },
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
          speed: 0
        }
      }
    },
    colors: ["#11998e"],
    plotOptions: {

      radialBar: {
        startAngle: -135,
        endAngle: 135,

        hollow: {
          margin: 5,
          size: '60%',
          background: 'transparent',
          position: 'front',
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        },
        track: {
          show: true,
          background: '#dde0ef',
          strokeWidth: '100%',
          opacity: 1,
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: '1vh',
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: 500,
            color: '#000000',
            offsetY: 25
          },
          value: {
            color: '#000000',
            fontFamily: "'Open Sans', sans-serif",
            offsetY: -20,
            fontSize: '2vh',
            fontWeight: 600,
            formatter: function (val) {
              return [val]
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: 0
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.3,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 3,
        stops: [0, 100]
      },
      colors: [function ({ value, seriesIndex, w }) {
        if (value <= 0) {
          return '#f2f2f2'
        } else if (value > 0 && value > 70 && value < 130) {
          return '#00CCCC'
        } else if (value <= 70 && value > 0) {
          return '#ADEBFF'
        }
        else if (value > 130) {
          return '#FE8479'
        }
      }]
    },
    labels: ['ppb'],
    states: {
      hover: {
        filter: {
          type: 'none',
        }
      },
    }

  }

  fleetGuageCardOptions = {
    cardTitle: "Device: ",
    radialOptions: this.radialOptions,
    guage: {
      targetValuePadding: "5",
      guageCenterTitle: "ClO2"
    }  
  }


  handleMenuClick = (e) => {
    const {value, title} = e.item.props;
    clearInterval(this.clearIntervalTelemetryData); //stop inteval
    setTimeout(() => {
      // this.setNewInterval(e.item.props.value, e.item.props.title)
      this.setNewInterval(value, title)
    }, 500);
  };

  setNewInterval = (interval, intervalTitle) => {
    if (interval === '0') {
      this.setState({ selectedRefreshValue: intervalTitle });
      clearInterval(this.clearIntervalTelemetryData); //stop inteval
    }
    else {
      this.setState({ refreshIntervalState: Number(interval) });
      this.setState({ selectedRefreshValue: intervalTitle });
      this.clearIntervalTelemetryData = setInterval(() => {
        this.refresh5Sec();
      }, Number(interval));
    }
  }

  menu = (
    <Menu
      onClick={this.handleMenuClick}
      items={[
        {
          title: 'Off',
          label: 'Off',
          key: '1',
          value: '0',
        },
        {
          title: '10s',
          label: '10s',
          key: '2',
          value: '10000',
        },
        {
          title: '30s',
          label: '30s',
          key: '3',
          value: '30000',
        },
        {
          title: '1m',
          label: '1m',
          key: '4',
          value: '60000',
        },
        {
          title: '15m',
          label: '15m',
          key: '5',
          value: '900000',
        },
      ]}
    />
  );

  handleClick(event) {
    event.preventDefault();
    
  }

  onChangeTree = (value) => {
    console.log("value", value)
    this.setState({ value });
    tempLocationIdsList = [];
    this.getDevicesByLocationFilter(value);
    this.setState({deviceId: []});

  };

  onTrigger = (id) => {
    this.setState({ routeValue: id })
    chickletRoutingId = id;
    // let singleDeviceId = new FleetDashboard(id);
  };
  getTelemetryData = () => {
    this.setState({ deviceData: [] });
    DeviceService.getDevicesByAccId().then((resultByFilter) => {
      this.setState({ deviceTelemetryMapData: resultByFilter });
      this.setState({ totalDevices: resultByFilter.length })
      this.getDeviceName(resultByFilter);
      this.getThresholdRangeData(resultByFilter);
      this.setState({ fleetDataForModeFilterState: resultByFilter })
      // this.setState({fleetCommandState:resultByFilter});
      this.setState({ fleetDeviceMapDataCompareState: resultByFilter });
      this.setState({ refreshState: false });
    });
  }

  getDeviceDetails = (res) => {
    //this.findLocationHierarchy(res);
    let hierarchy = [];
    let hierarchyList = [];
    let hierarchyResult = '';
    console.log('res:location data:: ---- ',res)
    if (res.metadata != null) {
      this.setState({ deviceMetaData: res.metadata })
      console.log('commd--',res)
      this.setState( { commsVersion : res.commsVersion});
      this.setState( { controllerVersion : res.controllerVersion});
      if (res.metadata.name != null) {
        this.setState({ nickname: res.metadata.name });
      }
      else {
        this.setState({ nickname: "None" });
      }
      if (res.metadata.location != null) {

        if (res.metadata.parentHierarchy != "") {
          hierarchy.push(res.metadata.parentHierarchy.split('#'));
          for (let i = 1; i < hierarchy[0].length; i++) {
            for (let j = 0; j < this.state.masterLocationData.length; j++) {
              if (hierarchy[0][i] === this.state.masterLocationData[j].id) {
                hierarchyList.push({ "value": this.state.masterLocationData[j].value });
                hierarchyResult = hierarchyResult.concat(this.state.masterLocationData[j].value + '/')
              }
            }
          }
        }
        else {
          hierarchyResult = 'None/';
        }
        hierarchyResult = hierarchyResult.slice(0, -1);
        this.setState({ location: hierarchyResult });
        this.setState(prevState => ({
          deviceMetaData: {
            ...prevState.deviceMetaData,
            location: hierarchyResult
          }
        }));
      }
      else {
        this.setState({ location: "None" });
        this.setState(prevState => ({
          deviceMetaData: {
            ...prevState.deviceMetaData,
            location: "None"
          }
        }));
      }
      this.setState({ open: true });
    }
    else {
      this.setState({ name: "None" });
      this.setState({ location: "None" });
      this.setState({ deviceMetaData: [] })
    }
  }

  deviceMetaModalOpen = (itemId) => {
    let resultData = [];
    
    clearInterval(this.clearIntervalTelemetryData);  //stopping refresh intervals
    for (let i = 0; i < this.state.fleetDeviceMapDataCompareState.length; i++) {
      if (this.state.fleetDeviceMapDataCompareState[i].id === itemId) {
        resultData = this.state.fleetDeviceMapDataCompareState[i];
        break;
      }
    }
   
    this.getDeviceDetails(resultData);
  }

  fleetCommandModal = () => {
    let resultData=[];
    this.setState({ fleetCommandModalVisible: true });
    clearInterval(this.clearIntervalTelemetryData);  //stopping refresh intervals

    for (let i = 0; i < this.state.deviceTelemetryMapData.length; i++) {
      if (this.state.deviceTelemetryMapData[i].mode !== 'OFFLINE') {
        resultData.push({ "deviceName": this.state.deviceTelemetryMapData[i].udi, "deviceId": this.state.deviceTelemetryMapData[i].id, "nickname": this.state.deviceTelemetryMapData[i].metadata.name });
     }
    }
    
    this.setState({devicesForFleetCommand:resultData});
  }

  handleFleetCommandResponseModal = (state) => {
    this.setState({ fleetCommandModalVisible: state });
    this.clearIntervalTelemetryData = setInterval(() => { //starting refresh intervals on modal close
      this.refresh5Sec();
    }, this.state.refreshIntervalState);
  }
  handleResponseModal = (childData) => {
    this.setState({ open: childData });
    this.clearIntervalTelemetryData = setInterval(() => { //starting refresh intervals on modal close
      this.refresh5Sec();
    }, this.state.refreshIntervalState);
  }

  getDeviceTelemetryByFilter = (deviceId) => {
    this.filterDeviceTelemetryData(deviceId);
    this.setState({ fleetDataForModeFilterState: this.state.fleetDeviceMapData });
    this.getThresholdRangeData(this.state.fleetDeviceMapData);
  }

  getDeviceTelemetryByModeFilter = (mode) => {
    
    this.setState({ selectedMode: mode });
    let resultObj = [];
    if (mode.length === 0) {
      this.setState({ refreshState: true });
      setTimeout(() => {
        this.refresh5Sec();
      }, 500);
    }
    else {
      for (let i = 0; i < this.state.fleetDataForModeFilterState.length; i++) {
        for (let j = 0; j < mode.length; j++){
          if (mode[j] === this.state.fleetDataForModeFilterState[i].mode) {
            if (this.state.fleetDataForModeFilterState[i].target === 0 || this.state.fleetDataForModeFilterState[i] === null) {
              this.state.fleetDataForModeFilterState[i].maxTarget = 50;
            }
            else {
              let result = this.state.fleetDataForModeFilterState[i].target * (30 / 100);
              this.state.fleetDataForModeFilterState[i].maxTarget = this.state.fleetDataForModeFilterState[i].target + result;
            }
            resultObj.push(this.state.fleetDataForModeFilterState[i]);
          }
          this.getThresholdRangeData(resultObj);
        }
        
      }
      
      setTimeout(() => {
        this.setState({ refreshState: false });
      }, 500);

    }
  }

  getMasterLocationData = () => {
    LocationService.getLocationByFilter().then((locationByFilter) => {
      this.setState({ masterLocationData: locationByFilter })
    })
  }

  getDevicesByLocationFilter = (value) => {
    let resultPushList = [];
    if (value.length === 0) {
      tempLocationIdsList = [];
      this.setState({ fleetDeviceMapData: this.state.fleetDeviceMapDataCompareState });
      this.getDeviceName(this.state.fleetDeviceMapDataCompareState);
      this.setState({ fleetDataForModeFilterState: this.state.fleetDeviceMapDataCompareState })
      this.setState({ deviceId: [] });
      this.setState({ totalDevices: this.state.fleetDeviceMapDataCompareState.length })
      this.getThresholdRangeData(this.state.fleetDeviceMapDataCompareState);
    }
    else {
      const parentToChildHierarchyIds = false;
      tempLocationIdsList = LocationTree.getIdByLocationValue(this.state.locationTree, value, parentToChildHierarchyIds);
      
      console.log("tempLocationIdsList: ", tempLocationIdsList)
      for (let i = 0; i < tempLocationIdsList.length; i++) {
        let deviceId = tempLocationIdsList[i];
        if (this.getFleetDataByLocationFilter(deviceId) !== null) {
          resultPushList.push.apply(resultPushList, this.getFleetDataByLocationFilter(deviceId));

        }
      }
      this.setState({ refreshState: true });
      resultPushList = resultPushList.filter(value => JSON.stringify(value) !== '{}');
      this.setState({ fleetDeviceMapData: [] });
      this.setState({ fleetDeviceMapData: resultPushList });
      this.setState({ fleetDataForModeFilterState: resultPushList })
      this.setState({ totalDevices: resultPushList.length })
      this.getThresholdRangeData(resultPushList);
      this.getDeviceName(resultPushList);

      setTimeout(() => {
        this.setState({ refreshState: false });
      }, 700);
    
    }
  }

  getFleetDataByLocationFilter = (deviceId) => {
    let resultObj = [];
   
    for (let i = 0; i < (this.state.fleetDeviceMapDataCompareState).length; i++) {

      var locationIdsList = this.state.fleetDeviceMapDataCompareState[i].metadata.parentHierarchy;
    
      if (locationIdsList != null) {
        if (locationIdsList.includes(deviceId)) {
          resultObj.push(this.state.fleetDeviceMapDataCompareState[i]);

        }
      }
    }
   
    return resultObj;
  }

  getDeviceName = (deviceDataResult) => {
    let deviceNameMap = [];
    for (const dataObj of deviceDataResult) {
      if (deviceNameMap.find(x => x.udi === dataObj.udi)) {
      }
      else {
        const deviceDetailsItem = { 
          "deviceName": dataObj.udi, 
          "deviceId": dataObj.id, 
          "nickname": dataObj.metadata.name 
        }
        deviceNameMap.push(deviceDetailsItem);
      }
    }
    this.setState({ deviceData: deviceNameMap });

  }

  getThresholdWiseFleetDashboard = () => {
    let res = this.state.deviceTelemetryMapData;
    let newList = [];
    for (let i = 0; i < res.length; i++) {
      if (res[i].clo2 === 0 || res[i].clo2 < 40) {
        res[i].shadow = "#FFFAD2";
        res[i].solid = "#FFED49";
        newList.push(res[i]);
      }

      this.setState({ fleetDeviceMapData: newList });
    }
  }

  filterDeviceTelemetryData = (deviceIds, filtersApplied) => {
    this.setState({ refreshState: true });
    if (deviceIds.length === 0) {
      this.getDevicesByLocationFilter(this.state.value);
      if (filtersApplied === "onChange"){
        this.getDevicesByLocationFilter(this.state.value);
        if (this.state.selectedMode.length > 0){
          setTimeout(() => {
            this.getDeviceTelemetryByModeFilter(this.state.selectedMode);
          }, 100)
        }
      }
    } 
    else {

      if (filtersApplied === "onChange"){
        let devicesSelected = [];
        for (let i = 0; i < deviceIds.length; i++){
          let result = this.state.fleetDeviceMapDataCompareState.filter(item => {return item.id == deviceIds[i]})
          devicesSelected.push(result[0]);
        }
        console.log("devicesSelected: ", devicesSelected);
        if (this.state.selectedMode.length > 0){  
          var filteredDevices = []
          for (let j = 0; j < this.state.selectedMode.length; j++){
            console.log(this.state.selectedMode[j]);
            for (let k = 0; k < devicesSelected.length; k++){
              if (devicesSelected[k].mode == this.state.selectedMode[j]){
                filteredDevices.push(devicesSelected[k]);
              }
            }
          }
          devicesSelected = filteredDevices;
          console.log("filteredDevices: ", filteredDevices)
        }

        this.getThresholdRangeData(devicesSelected);
        this.setState({ fleetDataForModeFilterState: devicesSelected });
        this.setState({ fleetDeviceMapData: devicesSelected});
        this.setState({ totalDevices: devicesSelected.length});
        this.setState({ refreshState: false });
      }
      else{
        DeviceService.getDevicesByAccId().then((resultByFilter) => {
          let devicesSelected = [];
          for (let i = 0; i < deviceIds.length; i++){
            let result = resultByFilter.filter(item => {return item.id == deviceIds[i]})
            devicesSelected.push(result[0]);
          }
          
          this.getThresholdRangeData(devicesSelected);
          this.setState({ fleetDataForModeFilterState: devicesSelected });
          console.log("deviceSelected", devicesSelected);
          this.setState({ fleetDeviceMapData: devicesSelected});
          this.setState({ totalDevices: devicesSelected.length});
          // this.setState({fleetCommandState:resultByFilter});
          this.setState({ fleetDeviceMapDataCompareState: resultByFilter });
          this.setState({ refreshState: false });
        });
      }    
    }  
  }

  handleDeviceFilterChange = (e) => {
   
    this.setState({ deviceId: e });
    this.filterDeviceTelemetryData(e, "onChange");
  }

  handleModeFilterChange = (e) => {
    console.log("Mode selected: ", e);

    this.setState({ selectedMode: e });
    //this.refresh5Sec();
    this.getDeviceTelemetryByModeFilter(e);

  }

  getThresholdRangeData = (res) => {
  
    let whiteCount = 0
    let celesteCount = 0;
    let tealCount = 0;
    let peachCount = 0;
    if (res.length === 0) {
      this.setState({ tealCount: tealCount });
      this.setState({ celesteCount: celesteCount });
      this.setState({ whiteCount: whiteCount });
      this.setState({ peachCount: peachCount });
      this.setState({ fleetDeviceMapData: res });
      this.setState({ totalDevices: res.length })
    }
    else {
      let modeColorMapping = {
        SENSE_ONLY: {mode: "SENSE_ONLY", modeColor: "#00CCCC", icon: "mdi mdi-18px mdi-access-point"},
        STANDBY: {mode: "STANDBY", modeColor: "#FFD700", icon: "mdi mdi-18px mdi-power-standby"},
        OPERATIONAL: {mode: "OPERATIONAL", modeColor: "#5565AF", icon: "mdi mdi-18px mdi-cog-sync-outline"},
        RESEARCH: {mode: "RESEARCH", modeColor: "#7159A6", icon: "mdi mdi-18px mdi-book-search-outline"},
        OFFLINE: {mode: "OFFLINE", modeColor: "#FE8479", icon: "mdi mdi-18px mdi-power-plug-off"}, 
        None: {mode: "None", modeColor: "#000000", icon: "mdi mdi-18px mdi-radiobox-marked"}
      } 

      for (let i = 0; i < res.length; i++) {
        if (res[i].target === 0 || res[i].target === null) {
          res[i].maxTarget = 50;
          
        }
        else {
          let result = res[i].target * (30 / 100);
          res[i].maxTarget = res[i].target + result;
          
        }

        if (res[i].clo2 <= 0) {
          res[i].clo2 = Math.round(res[i].clo2);
          res[i].shadow = "#f2f2f2";
          res[i].solid = "#f2f2f2";
          whiteCount = whiteCount + 1;
        }
        else if (res[i].clo2 > 0 && res[i].clo2 > 70 && res[i].clo2 < 130) {// > 0 > 70 < 130
          res[i].clo2 = Math.round(res[i].clo2);   //teal
          res[i].shadow = "#00ffea";
          res[i].solid = "#00CCCC";
          tealCount = tealCount + 1;
        }
        else if (res[i].clo2 <= 70 && res[i].clo2 > 0) {
          res[i].clo2 = Math.round(res[i].clo2);
          res[i].shadow = "#c7f1ff";     //celeste
          res[i].solid = "#ADEBFF"; //< = 70     >0
          celesteCount = celesteCount + 1;
        }
        else if (res[i].clo2 > 130) {
          res[i].clo2 = Math.round(res[i].clo2); //peach
          res[i].shadow = "#ffcac5";
          res[i].solid = "#FE8479";
          peachCount = peachCount + 1;
        }

        if (modeColorMapping.hasOwnProperty(res[i].mode)){
          res[i].modeColor = modeColorMapping[res[i].mode].modeColor;
          res[i].icon = modeColorMapping[res[i].mode].icon;
          let listOfModes = ["SENSE_ONLY", "OPERATIONAL", "STANDBY", "RESEARCH", "OFFLINE"];
          let modeIndex = listOfModes.indexOf(res[i].mode);
          listOfModes.splice(modeIndex, 1);
          res[i].listOfModes = listOfModes;
        }
        else{
          res[i].mode = "None";
          res[i].modeColor = "#000000";
          res[i].icon = "mdi mdi-18px mdi-radiobox-marked";
          let listOfModes = ["SENSE_ONLY", "OPERATIONAL", "STANDBY", "RESEARCH", "OFFLINE"];
          let modeIndex = listOfModes.indexOf(res[i].mode);
          listOfModes.splice(modeIndex, 1);
          res[i].listOfModes = listOfModes;
        }
      }
    }
    this.setState({ tealCount: tealCount })
    this.setState({ celesteCount: celesteCount })
    this.setState({ whiteCount: whiteCount })
    this.setState({ peachCount: peachCount })
    this.setState({ fleetDeviceMapData: res });
    this.setState({ totalDevices: res.length });
  }

  sendCommand = (mode, selectedDeviceId, udi) => {
    SharedService.sendCommandForChangeMode(selectedDeviceId, udi, mode).then((res) => {
    });
  }
  getFilteredDeviceList = () => {
    let deviceNameMap = [];
    var deviceId = ""
    OTARuntimeService.getOTALiveDataByAccId().then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].jobStatus === "CREATE_IN_PROGRESS" || res[i].jobStatus === "CREATE_PENDING") {
          deviceId = res[i].deviceId;
         
        }
      }
      deviceNameMap = this.state.deviceData;
      for (let i = 0; i < deviceNameMap.length; i++) {
        if (deviceNameMap[i].deviceId === deviceId) {
          var index = deviceNameMap.indexOf(deviceNameMap[i]);
         
          if (index > -1) { // only splice array when item is found
            deviceNameMap.splice(index, 1); // 2nd parameter means remove one item only
           
          }
        }
      }
      this.setState({ filteredDeviceList: deviceNameMap });
    });
  }

  otaUpdateModalOpen = (firmwareType) => {
   
    if (firmwareType === 'comms') {
      clearInterval(this.clearIntervalTelemetryData);
      this.setState({ visible: true });
      this.setState({ firmwareType: 'comms' });
      this.getFilteredDeviceList();
      this.getTargetFirmwareComms();
    }
    else {
      clearInterval(this.clearIntervalTelemetryData);
      this.setState({ visible: true });
      this.setState({ firmwareType: 'controls' });
      this.getFilteredDeviceList();
      this.getTargetFirmwareControls();
    }
  }

  handleOtaUpdateModal = (childData) => {
   
    this.setState({ visible: childData });
    this.clearIntervalTelemetryData = setInterval(() => { //starting refresh intervals on modal close
      this.refresh5Sec();
    }, this.state.refreshIntervalState);
  }

  getTargetFirmwareComms = () => {
    
    SharedService.fetchS3ObjectsOTABucket('comms').then((targetFirmwareListData) => {
    
      this.setState({ targetFirmwareList: targetFirmwareListData })
    });
  }

  getTargetFirmwareControls = () => {
   
    SharedService.fetchS3ObjectsOTABucket('controls').then((targetFirmwareListData) => {
      
      this.setState({ targetFirmwareList: targetFirmwareListData })
    });
  }
  setTop = () => {
    this.setState({ top: 60 });
  }

  handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  refresh5Sec = () => {

    if ((this.state.deviceId === "" || this.state.deviceId === null || (this.state.deviceId).length === 0) && ((this.state.value).length === 0) && (this.state.selectedMode.length === 0)) { //0 0 0

      this.setState({ refreshState: true });
      tempLocationIdsList = [];
      this.setState({ value: [] });
      this.getTelemetryData();
    }
    if ((this.state.deviceId.length === 0 || this.state.deviceId === null) && ((this.state.value).length === 0) && (this.state.selectedMode.length !== 0)) { //0 0 1
     
      this.setState({ refreshState: true });
      tempLocationIdsList = [];
      this.setState({ value: [] });
      this.getDeviceTelemetryByModeFilter(this.state.selectedMode);
    }
    if (((this.state.value).length === 0) && (this.state.deviceId.length !== 0) && (this.state.selectedMode.length === 0)) { // 0 1 0
      
      this.setState({ refreshState: true });
      // this.getDeviceTelemetryByFilter(this.state.deviceId);
      this.filterDeviceTelemetryData(this.state.deviceId, "0-1-0");
 
    }
    if (((this.state.value).length > 0) && (this.state.deviceId.length === 0) && (this.state.selectedMode.length === 0)) { // 1 0 0
     
      tempLocationIdsList = [];
      this.setState({ refreshState: true });
      this.getDevicesByLocationFilter(this.state.value);
    }
    if (((this.state.value).length > 0) && (this.state.deviceId.length === 0) && (this.state.selectedMode.length !== 0)) { // 1 0 1
     
      // this.setState({refreshState:true});
      tempLocationIdsList = [];
      this.getDevicesByLocationFilter(this.state.value);
      this.getDeviceTelemetryByModeFilter(this.state.selectedMode);
    }
    if (((this.state.value).length > 0) && (this.state.deviceId.length !== 0) && (this.state.selectedMode.length === 0)) { //1 1 0
     
      console.log('onChangeRefresh 1-0 ', this.state.value);
      // this.setState({refreshState:true});
      tempLocationIdsList = [];
      // this.getDeviceTelemetryByFilter(this.state.deviceId);
      this.getDevicesByLocationFilter(this.state.value);
      this.filterDeviceTelemetryData(this.state.deviceId, "1-1-0");

    }
    if (((this.state.value).length === 0) && (this.state.deviceId.length !== 0) && (this.state.selectedMode.length !== 0)) { // 0 1 1
     
      console.log('onChangeRefresh 1-0 ', this.state.value);
      // this.setState({refreshState:true});
      tempLocationIdsList = [];
      // this.getDeviceTelemetryByFilter(this.state.deviceId);
      this.getDeviceTelemetryByModeFilter(this.state.selectedMode);

    }
    if (((this.state.value).length > 0) && (this.state.deviceId.length !== 0) && (this.state.selectedMode.length !== 0)) { // 1 1 1
      
      console.log('onChangeRefresh 1-0 ', this.state.value);
      // this.setState({refreshState:true});
      tempLocationIdsList = [];
      this.getDevicesByLocationFilter(this.state.value);
      this.getDeviceTelemetryByFilter(this.state.deviceId);
      this.getDeviceTelemetryByModeFilter(this.state.selectedMode);
    }
    console.log('fleetData--', this.state.fleetDeviceMapData, this.state.accountId)
  }
  async getUserRole() {
    try {
      await SharedService.getCurrentUser().then((userDataList) => {
        SharedService.getUserByEmailId(userDataList.attributes.email).then((userData) => {
          var userDataList = userData;
          if (userDataList === null || userDataList === [] || userDataList.length === 0) {
            this.setState({ userRole: '' });
          } else {
            var userRoleName = userDataList[0].userType;
            this.setState({ userRole: userRoleName });
            this.setState({ username: userDataList[0].username })
            console.log('username--', userDataList[0].username,'tYPE--', userDataList[0].userType )
          }
        });
      });

    } catch (err) {
      console.log('error on DeviceMeta::getUserRole:: ', err);
    }
  }

  toggleMobileFilterBar = () => {
    this.setState({ openMobileFilterBar: !this.state.openMobileFilterBar})
  }
  componentDidMount() {
    this.setState({ refreshState: true });
    this.getUserRole();
    this.getMasterLocationData();
    LocationTree.getLocationTree().then((locationTree) => {
      setTimeout(() => {
      this.setState({ locationTree: locationTree });
      }, 2000);
    });

    setTimeout(() => {
      this.getTelemetryData();
    }, 500);

    this.clearIntervalTelemetryData = setInterval(e => this.refresh5Sec(), this.state.refreshIntervalState);
  }

  componentWillUnmount() {
    clearInterval(this.clearIntervalTelemetryData);
  }

  render() {
    document.title = 'Fleet.LIVE';
    const tProps = {
      treeData: this.state.locationTree,
      showArrow: true,
      showSearch: false,
      value: this.state.value,
      data: this.state.data,
      //treeCheckStrictly:true,
      // treeLine:true,s
      onChange: this.onChangeTree,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: 'Locations',
      maxTagCount: "responsive",
      style: {
        width: '100%',
      },

    };


    return (

      <div className="container-fluid">
         <Affix offsetTop={64.4} onChange={(affixed) => console.log(affixed)}>
         <div className="container-fluid">     

          <BreadcrumbComponent parent="Dashboard" child="Fleet" live />  
         
          <div className="row">
            <div className="card pe-0">
              <div className="card-body pe-0 ps-0 pt-0 pb-0">
                <div className="row">
                  <div className="col d-flex align-items-center ps-0 pe-0">
                    <ul className="list-group list-group-horizontal d-flex align-items-center ps-0">
                      <li className="list-group-item d-flex justify-content-between align-items-start border-0">
                        <div className="me-auto">
                          <div className="fw-bold">Total Devices</div>
                        </div>
                        <span className="badge rounded-pill ms-1" style={{background: "#000000"}}>{this.state.totalDevices}</span>
                      </li>
                    </ul>
                  </div>

                  <div className="col pe-3 d-flex justify-content-end">
                    <div className="toolbar-menu align-self-center">
                      <ul className="list-group list-group-horizontal pe-0 d-flex align-items-center">
                        <li style={{width: "197px"}} className="list-group-item border-0 ps-1 pe-1">
                          <TreeSelect {...tProps} />
                        </li>

                        <li className="list-group-item border-0 ps-1 pe-1">
                          <Select mode="multiple" placeholder="Devices" maxTagCount="responsive"
                            id="device-select" label="Devices" style={{ width: "197px" }} allowClear showArrow value={this.state.deviceId.length  === 0 ? [] : this.state.deviceId}
                            onChange={this.handleDeviceFilterChange}>
                            {/* <Option value="">Devices</Option> */}
                            {this.state.deviceData.map((make, index) => (
                              <Option key={make.deviceId} value={make.deviceId}>{make.nickname}</Option>
                            ))}
                          </Select>
                        </li>

                        <li className="list-group-item border-0 ps-1 pe-1">
                          <Select mode="multiple" placeholder="Modes" maxTagCount="responsive" 
                            value={this.state.selectedMode.length === 0 ? [] : this.state.selectedMode} 
                            id="device-mode" label="Modes" style={{ width: "197px" }} onChange={this.handleModeFilterChange}
                            showArrow allowClear
                            >
                            
                            <Option value='OPERATIONAL'>OPERATIONAL</Option>
                            <Option value='SENSE_ONLY'>SENSE ONLY</Option>
                            <Option value='STANDBY'>STANDBY</Option>
                            <Option value='RESEARCH'>RESEARCH</Option>
                            <Option value='OFFLINE'>OFFLINE</Option>
                          </Select>
                        </li>

                        <li className="list-group-item border-0 ps-1 pe-1">
                        <Dropdown.Button trigger={['click']} onClick={this.refresh5Sec} overlay={this.menu} placement="bottom" icon={<DownOutlined />}>
                          <div className="d-inline-flex align-items-center">
                            <label >{this.state.selectedRefreshValue}  </label>
                            &nbsp; <SyncOutlined />
                          </div>
                          
                        </Dropdown.Button>
                      </li>
                      </ul>
                    </div>  

                    <ul className="list-group list-group-horizontal pe-0 d-flex align-items-center">

                      <li className="list-group-item border-0 ps-2 pe-0">
                        <div className="dropdown dropdown-arrow-none">
                          <DropdownReact size="lg">
                            <DropdownReact.Toggle size="lg" className="btn ps-4 float-right align-self-center text-dark bg-transparent border-0 hide-carret">
                              <i className="mdi  mdi-24px mdi-broadcast float-end"></i>
                            </DropdownReact.Toggle>
                            <DropdownReact.Menu>
                              {this.state.username === 'christobel' || this.state.username === 'web_test1' || this.state.username === 'webtest' || this.state.username === 'cmills' ? <div>
                                <DropdownReact.Item onClick={() => this.otaUpdateModalOpen('comms')}><i className="mdi mdi-plus-circle-outline mr-2"></i> OTA Comms Update </DropdownReact.Item>
                                <DropdownReact.Item onClick={() => this.otaUpdateModalOpen('controls')}><i className="mdi mdi-plus-circle-outline mr-2"></i> OTA Controls Update </DropdownReact.Item>
                              </div> : <div></div>}
                              <DropdownReact.Item className="a-href-highlight-remove" href='/otaHistory'><i className="mdi mdi-file-document-outline"/> <span>OTA History</span></DropdownReact.Item>
                            </DropdownReact.Menu>
                          </DropdownReact>
                        </div>
                      </li>

                      <li className="list-group-item border-0 ps-0 pe-0">
                        <div className="dropdown dropdown-arrow-none">
                          <DropdownReact size="lg">
                            <DropdownReact.Toggle size="lg" className="btn ps-2 float-right align-self-center text-dark bg-transparent border-0 hide-carret">
                              <i className="mdi  mdi-24px mdi-pencil-box-multiple-outline float-end"></i>
                            </DropdownReact.Toggle>
                            <DropdownReact.Menu>
                            <DropdownReact.Item className="a-href-highlight-remove" onClick={() => this.fleetCommandModal()}><i className="mdi mdi-pencil-outline mr-2"/> <span>Fleet Command</span></DropdownReact.Item>
                            </DropdownReact.Menu>
                          </DropdownReact>
                        </div>
                      </li>

                      <li className="list-group-item border-0 ps-0 pe-2 filter-toolbar">
                        <a onClick={this.toggleMobileFilterBar} className="a-href-highlight-remove">
                          <div className="dropdown dropdown-arrow-none">
                            <i className="mdi  mdi-24px mdi-filter-menu-outline float-end"></i>
                          </div>
                        </a>
                        
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.openMobileFilterBar ? 
            <div className="row filter-toolbar mt-2">
              <div className="card pe-0 ps-0">
                <div className="card-body pe-0 ps-0 pt-0 pb-0">
                  <div className="row">

                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 align-items-center">
                      <ul className="filter-item list-group list-group-horizontal align-items-center">
                        <li style={{width: "100%"}} className="list-group-item border-0">
                          <TreeSelect {...tProps} />
                        </li>
                      </ul>
                    </div>
                    
                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 align-items-center">
                      <ul className="list-group list-group-horizontal align-items-center">
                        <li style={{width: "100%"}} className="list-group-item border-0">
                          <Select mode="multiple" placeholder="Devices"  maxTagCount="responsive" 
                            style={{width: "100%"}} allowClear showArrow value={this.state.deviceId.length  === 0 ? [] : this.state.deviceId}
                            id="device-select" label="Devices"
                            onChange={this.handleDeviceFilterChange}>
                            {/* <Option value="">Devices</Option> */}
                            {this.state.deviceData.map((make, index) => (
                              <Option key={make.deviceId} value={make.deviceId}>{make.nickname}</Option>
                            ))}
                          </Select>
                        </li>
                      </ul>
                    </div>

                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 align-items-center">
                      <ul className="list-group align-items-center">  
                        <li style={{width: "100%"}} className="list-group-item border-0">
                          <Select mode="multiple" placeholder="Modes" maxTagCount="responsive" 
                            style={{width: "100%"}} value={this.state.selectedMode.length === 0 ? [] : this.state.selectedMode} 
                            id="device-mode" label="Modes" onChange={this.handleModeFilterChange}
                            showArrow allowClear
                            >
                            
                            <Option value='OPERATIONAL'>OPERATIONAL</Option>
                            <Option value='SENSE_ONLY'>SENSE ONLY</Option>
                            <Option value='STANDBY'>STANDBY</Option>
                            <Option value='RESEARCH'>RESEARCH</Option>
                            <Option value='OFFLINE'>OFFLINE</Option>
                          </Select>
                        </li>
                      </ul>
                    </div>

                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 align-items-center">
                      <ul className="list-group list-group-horizontal align-items-center">
                        <li className="list-group-item border-0">
                          <Dropdown.Button style={{width: "100%"}} trigger={['click']} onClick={this.refresh5Sec} overlay={this.menu} placement="bottom" icon={<DownOutlined />}>
                            <div className="d-inline-flex align-items-center">
                              <label >{this.state.selectedRefreshValue}  </label>
                              &nbsp; 
                              <SyncOutlined />
                            </div>

                          </Dropdown.Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          : null }
        </div>
        </Affix>

        <div className='container-fluid mb-2 mt-2 pe-2 ps-2'>
          <div className="row">
            {this.state.fleetDeviceMapData.map((item) => (
              <div key={item.id} className="col-xl-2 col-lg-4 col-md-4 col-sm-6 px-1">
                <div style={{position: "relative", zIndex: "1"}}>

                  <DropdownReact style={{position: "absolute", left: "83%", top: "1.4vh"}}>
                    <DropdownReact.Toggle className="text-dark bg-transparent border-0 hide-carret ps-0">
                      <i className="mdi mdi-cogs float-start"></i>
                    </DropdownReact.Toggle>
                    <DropdownReact.Menu>
                      <DropdownReact.Item onClick={() => this.sendCommand(item.listOfModes[0], item.id, item.udi)}><i className="mdi mdi-pencil-outline mr-2"></i> Go {item.listOfModes[0]}</DropdownReact.Item>
                      <DropdownReact.Item onClick={() => this.sendCommand(item.listOfModes[1], item.id, item.udi)}><i className="mdi mdi-pencil-outline mr-2"></i> Go {item.listOfModes[1]}</DropdownReact.Item>
                      {item.listOfModes[2] === 'RESEARCH' && (this.state.userRole === 'USER' || this.state.userRole === '' || this.state.userRole === null) ? null :
                        <DropdownReact.Item onClick={() => this.sendCommand(item.listOfModes[2], item.id, item.udi)}><i className="mdi mdi-pencil-outline mr-2"></i> Go {item.listOfModes[2]} </DropdownReact.Item>
                      }
                      {item.listOfModes[3] === 'OFFLINE' ? null : item.listOfModes[3] === 'RESEARCH' && (this.state.userRole === 'USER' || this.state.userRole === '' || this.state.userRole === null) ?
                        null : <DropdownReact.Item onClick={() => this.sendCommand(item.listOfModes[3], item.id, item.udi)}><i className="mdi mdi-pencil-outline mr-2"></i> Go {item.listOfModes[3]} </DropdownReact.Item>
                      }
                    </DropdownReact.Menu>     
                  </DropdownReact>

                  <DropdownReact style={{position: "absolute", left: "91%", top: "1.4vh"}}>
                    <DropdownReact.Toggle className="text-dark bg-transparent border-0 hide-carret ps-0">
                      <i className="mdi mdi-eye"></i>
                    </DropdownReact.Toggle>
                    <DropdownReact.Menu>                      
                      <DropdownReact.Item onClick={() => this.deviceMetaModalOpen(item.id)}><i className="mdi mdi-eye-outline mr-2"></i> Device Profile </DropdownReact.Item>
                    </DropdownReact.Menu>     
                  </DropdownReact>
                </div>
                
                <GuageCardComponent 
                  cardHeader = {{ link : {text: item.metadata.name, callBackOnTrigger: this.onTrigger, itemId: item.id}}} 
                  refreshState = {this.state.refreshState} 
                  guageCardOptions = {this.fleetGuageCardOptions} 
                  series={item.clo2} 
                  deviceModeIcon = {{iconClassName: item.icon, iconTitle: {text: item.mode, color: item.modeColor}}} 
                  target={item.maxTarget} 
                />
              </div>
            ))
            }
          </div>
        </div>
        <div>
        {this.state.open ? <DeviceMeta open={this.state.open} commsVersion={this.state.commsVersion} controllerVersion={this.state.controllerVersion} metadata={this.state.deviceMetaData} userRoleData={this.state.userRole} parentCallback={this.handleResponseModal} /> : null }
          
          <FleetOtaUpdate visible={this.state.visible} deviceListData={this.state.filteredDeviceList}
            targetFirmwareData={this.state.targetFirmwareList} firmwareType={this.state.firmwareType} parentCallback={this.handleOtaUpdateModal} />
          {this.state.fleetCommandModalVisible ? <FleetCommand visible={this.state.fleetCommandModalVisible} deviceList={this.state.devicesForFleetCommand}  userRoleData={this.state.userRole} parentCallback={this.handleFleetCommandResponseModal} /> : null}
        </div>
      </div>
    );
  }
}
export default FleetDashboard;