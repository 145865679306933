
import {listLocationsByAccId,queryDeviceForLocationDevicesId} from '../graphql/queries';
import { API } from 'aws-amplify';
import authService from './AuthService';
const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  

var accountId= '';

class LocationService{

      //get list of unique locations
      async getLocationByFilter(){       
        try {
            accountId = (await authService.getCurrentUser()).accountId;
            const locations = await API.graphql({query:listLocationsByAccId(accountId),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
            const locationsMapData = locations.data.listLocations.items;
            return locationsMapData;
          }
        catch (err) { 
            console.log('Error Occured getLocationByFilter:: ',err); return null; 
        }
    }
    async getDevicesByLocationFilter(locationDevicesId){
      try {
          accountId = (await authService.getCurrentUser()).accountId;
          const deviceList = await API.graphql({query:queryDeviceForLocationDevicesId(locationDevicesId,accountId),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
          const deviceListMapData = deviceList.data.listDevices.items;
         
          return deviceListMapData;
        } catch (err) { console.log('Error Occured getDevicesByLocationFilter:: ',err); return null; }
  }
}
export default new LocationService();