import { API} from 'aws-amplify';
import { queryOTAHistoryByAccIdTime,queryStateTelemetryByAccIdDevIdTime} from '../graphql/queries';
import React from 'react';
import authService from './AuthService';
import deviceService from './DeviceService';
var moment = require('moment'); // require
const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  
 var accountId= '';
class OTAHistoryService extends React.Component{ 

async getOTAHistoryByAccIdTime(fromTime,currentTime,limit){
    var otaHistoryChunk = [];
    var otaHistoryForReports = [];
    let nextToken = '';
    var deviceList = [];
    try{
        accountId = (await authService.getCurrentUser()).accountId;
        await deviceService.getDevicesByAccId().then((getDeviceListForAccount) => {
            deviceList = getDeviceListForAccount;
        });

        var _tempStartDateTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";
        for(var i=0;i<deviceList.length;i++){
        otaHistoryChunk = await API.graphql(({query:queryOTAHistoryByAccIdTime(deviceList[i].id,accountId,fromTime,currentTime,limit,nextToken),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));
        if(otaHistoryChunk.data.OTAHistoryByDate.items != null){
            otaHistoryForReports.push.apply(otaHistoryForReports,otaHistoryChunk.data.OTAHistoryByDate.items);
            nextToken = otaHistoryChunk.data.OTAHistoryByDate.nextToken;
            while(nextToken!=null){
               // console.log("Deep dive for more data at: ",moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z", "with limit: ");
               otaHistoryChunk = [];
               otaHistoryChunk = await API.graphql(({query:queryStateTelemetryByAccIdDevIdTime(accountId,fromTime,currentTime,limit,nextToken),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));                 nextToken = otaHistoryChunk.data.deviceStateByDate.nextToken;
               console.log('nextToken---',nextToken);
               otaHistoryForReports.push.apply(otaHistoryForReports,otaHistoryChunk.data.deviceStateByDate.items);
            }
        }
    }

        console.log("Got all state records: ",otaHistoryForReports.length, "in: ",moment(moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z").diff(moment(_tempStartDateTime),'seconds'), " seconds");

        for(let j=0;j<otaHistoryForReports.length;j++){
        otaHistoryForReports[j].eventDate = moment(otaHistoryForReports[j].eventDate).local().format('YYYY-MM-DD HH:mm:ss');
        otaHistoryForReports[j].createdAt = moment(otaHistoryForReports[j].createdAt).local().format('YYYY-MM-DD HH:mm:ss');
        otaHistoryForReports[j].updatedAt = moment(otaHistoryForReports[j].updatedAt).local().format('YYYY-MM-DD HH:mm:ss')

      }
      const otaHistoryForReportsSort = otaHistoryForReports.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0));
      return otaHistoryForReportsSort;
    }catch(err){ console.log('Error Occured :: getOTAHistoryByAccIdTime() ', err); return null; };
}
}
export default new OTAHistoryService();