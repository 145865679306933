import React from 'react';
import 'antd/dist/antd.css';

export class BreadcrumbComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          
        }
    }

    render() {
        return (
            <div className="row pb-1 pt-2 ps-1" style={{backgroundColor:"#f0f1f6", border:'1px'}}>
                <nav aria-label="breadcrumb justify-content-center align-self-center" >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">{this.props.parent}</a></li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {this.props.child}{this.props.live && <label><span style={{ "color": "red" }}>.LIVE</span></label>}
                        </li>
                    </ol>
                </nav>
            </div>       
        )
    }
}

export default BreadcrumbComponent;