import React from 'react';
import { Affix } from 'antd';
import ReportExportHistoryService from '../../services/ReportExportHistoryService';
import { BreadcrumbComponent } from '../../utils/components/breadcrumbComponent';
import SharedService from '../../services/SharedService';
import DeviceService from '../../services/DeviceService';
import DataTable from '../../utils/tables/DataTable';

var moment = require('moment'); // require

export class Downloads extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadData: [],
      loading:false,
      refreshIntervalState:30000,
      userRole: '',
      deviceList:[],
    }
  }
 
  getReportExportHistoryByAccId = () => {
    this.setState({loading:true});
    ReportExportHistoryService.getReportExportHistoryByAccId().then((downloadsList) => {     
      for(let i=0;i<downloadsList.length;i++){
        downloadsList[i].filter =downloadsList[i].filter.replace(/\\/g, " ");  
        downloadsList[i].filter = JSON.parse(downloadsList[i].filter);
        downloadsList[i].filter.reportFromDateTime = moment(downloadsList[i].filter.reportFromDateTime).local().format('YYYY-MM-DD HH:mm:ss')
        downloadsList[i].filter.reportToDateTime =  moment(downloadsList[i].filter.reportToDateTime).local().format('YYYY-MM-DD HH:mm:ss')

        //if(downloadsList[i].reportType === 'Device Events'){
          for(let j=0;j<this.state.deviceList.length;j++){
            if(downloadsList[i].filter.devices === this.state.deviceList[j].id){            
              downloadsList[i].deviceName = this.state.deviceList[j].udi
            }            
          }          
        //}       
      }    
      this.setState({ downloadData: downloadsList });
    });
    this.setState({loading:false});
  }

  async getMasterDeviceData (){
    await DeviceService.getDevicesByAccId().then((res) => { 
      this.setState({deviceList:res});
      this.getReportExportHistoryByAccId();
    });
  }

  async getUserRole() {
    try {
      await SharedService.getCurrentUser().then((userDataList) => {
        SharedService.getUserByEmailId(userDataList.attributes.email).then((userData) => {
          var userDataList = userData;
          if (userDataList === null || userDataList === '' || userDataList.length === 0) {
            this.setState({ userRole: '' });
          } else {
            var userRoleName = userDataList[0].userType;
            this.setState({ userRole: userRoleName });
          }
        });
      });

    } catch (err) {
      console.log('error on download.js :: getUserRole:: ', err);
    }
  }

  componentDidMount() {
    this.getMasterDeviceData();
    this.setState({loading:true});
    setTimeout(() => {     
           
      this.getUserRole();
      // this.getReportExportHistoryByAccId(); 
    }, 100)
    this.clearIntervalTelemetryData = setInterval(e => this.getReportExportHistoryByAccId(), this.state.refreshIntervalState);
  }

  
  render() {
    document.title = 'Report Downloads';
    return (
      <div className="container-fluid">
        <Affix className="z-index" offsetTop={30}>
          <div className="container-fluid">
            <BreadcrumbComponent parent="Reports" child="Downloads" />
          </div>
        </Affix>
        <div className='container-fluid mb-2 mt-2 '>
          <div className="row">
            <div className="card ">
              <div className="card-body">
              <DataTable refreshState={this.state.refreshState}
                tableData={this.state.downloadData}
                title="reportDownloads"
                userRole={this.state.userRole} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
export default Downloads;