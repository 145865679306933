import * as React from 'react';
import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom'
import '../../ota-update/otaUpdate.scss';


var moment = require('moment'); // require

export class DownloadResponse extends React.Component {
    formRef = React.createRef();
    constructor(props) {

        super(props)
        this.state = {
            visible: false,
            fromDateTime:'' ,
            toDateTime:''  ,
                          
        };

    }

    onCancel = () => {      
        this.setState({ visible: false });       
        this.props.parentCallback(false);
    }

    onChange(e) {
        this.setState({ jobName: e.target.value });
        this.checkFieldValidation();
    }   

     //Submit function
     closeModal = () => {       
        this.setState({ visible: false });
        this.props.parentCallback(false);             
    }

    componentDidUpdate(prevProps){
        if (prevProps.visible !== this.props.visible){
            this.setState({ visible: this.props.visible });
        }
    }


    render() {
        return (
            <div style={{ position: "relative" }}>
                <Modal
                    centered={true}
                    open={this.state.visible}
                    title='Report Downloads'
                    headStyle={{ backgroundColor: 'grey' }}
                    onOk={this.createOTAJob}
                    onCancel={this.onCancel}
                    width={600}
                    bodyStyle={{
                        height: 100
                    }}
                    footer={[
                        <Button key="back" onClick={this.onCancel}>
                            Download later
                        </Button>,

                        <>&nbsp;                            
                                 <Link to='/reportDownloads'><Button key="submit" type="primary" loading={this.state.loading} onClick={this.closeModal}>
                                 Grab it NOW!
                                </Button></Link>
                        </>
                    ]}
                >  
                <h6>Your report will be ready in the <b>Report Downloads</b> section soon. You can choose to <b>Grab it NOW! </b> 
                   or choose to do it later by clicking <b>Download later</b></h6>                 
                </Modal>
            </div>
        )
    }
}

export default React.memo(DownloadResponse);