
import { API  } from 'aws-amplify';
import { queryOTALiveByAccIdFirmwareType,queryOTALiveByAccId} from '../graphql/queries';
import React from 'react';
import authService from './AuthService';
var moment = require('moment'); // require
const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  
 var accountId= '';
class OTARuntimeService extends React.Component{ 
 
 async getOTALiveDataByAccIdFirmwareType(firmwareType){
        //console.log('getDevicesByAccId()',accountId);
        try {
            accountId = (await authService.getCurrentUser()).accountId;
            const otaLiveData = await API.graphql({query:queryOTALiveByAccIdFirmwareType(accountId,firmwareType),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
            const otaDeviceList = otaLiveData.data.listDeviceOTARuntimes.items;
            const otaDeviceListSort = otaDeviceList.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
            //console.log("getDevicesByAccId::otaDeviceListSort: ",otaDeviceListSort);
            for(var i=0;i<otaDeviceListSort.length;i++){
                otaDeviceListSort[i].createdAt = moment(otaDeviceListSort[i].createdAt).local().format('YYYY-MM-DD HH:mm:ss');
                otaDeviceListSort[i].updatedAt = moment(otaDeviceListSort[i].updatedAt).local().format('YYYY-MM-DD HH:mm:ss')
              }
              const otaDeviceListSortedList = otaDeviceListSort.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0));

            return otaDeviceListSortedList;
        } catch (err) { 
              console.log('Error Occured getOTALiveDataByAccId:: ',err); return null; 
        }
    }

async getOTALiveDataByAccId(){
    //console.log('getDevicesByAccId()',accountId);
    try {
        accountId = (await authService.getCurrentUser()).accountId;
        const otaLiveDataByAccId = await API.graphql({query:queryOTALiveByAccId(accountId),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
        const otaLiveDataByAccIdList = otaLiveDataByAccId.data.listDeviceOTARuntimes.items;
        const otaLiveDataByAccIdListSort = otaLiveDataByAccIdList.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
        //console.log("getOTALiveDataByAccId::otaLiveDataByAccIdListSort: ",otaLiveDataByAccIdListSort);
        for(var i=0;i<otaLiveDataByAccIdListSort.length;i++){
            otaLiveDataByAccIdListSort[i].createdAt = moment(otaLiveDataByAccIdListSort[i].createdAt).local().format('YYYY-MM-DD HH:mm:ss');
            otaLiveDataByAccIdListSort[i].updatedAt = moment(otaLiveDataByAccIdListSort[i].updatedAt).local().format('YYYY-MM-DD HH:mm:ss')
          }
          const otaLiveDataByAccIdListSortedList = otaLiveDataByAccIdListSort.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0));
        return otaLiveDataByAccIdListSortedList;
    } catch (err) { 
          console.log('Error Occured getOTALiveDataByAccId:: ',err); return null; 
    }
}
}
export default new OTARuntimeService();