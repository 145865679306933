import DeviceTelemetryService from '../../services/DeviceTelemetryService';
import React from 'react';

class TelemetrySharedFunction extends React.Component{

  async getClO2DataByDeviceIdAndDateTime(deviceId,fromDate,toDate) {
    console.log('deviceId:: ',deviceId," :: fromDate:: ",fromDate," :: toDate:: ",toDate);
    var avgValue = "";
      await DeviceTelemetryService.getHistoricalTelemetryByDevIdAccIdTime(deviceId,fromDate,toDate,2200).then((resultClO2Data) => { 
        console.log('resultClO2Data:: ',resultClO2Data);
        avgValue = this.getAvgClO2Data(resultClO2Data);
        console.log('avgValue:: ',avgValue);
       
    });
    return avgValue;
  }

   getAvgClO2Data = (resultClO2Data) =>{
    var totalClO2Value = 0;
    for(var i=0;i<resultClO2Data.length;i++){
      totalClO2Value = totalClO2Value+ Math.round(resultClO2Data[i].clo2.value);
    }
    console.log('totalClO2Value:: ',totalClO2Value);
    console.log('totalClO2Value-length:: ',resultClO2Data.length);
    console.log('avg clo2 value:: ',totalClO2Value/resultClO2Data.length);
    const avgValue = Math.round(totalClO2Value/resultClO2Data.length);
    return avgValue;
  }
}
export default new TelemetrySharedFunction();