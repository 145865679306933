import React from 'react';
import ReactApexChart from 'react-apexcharts';
import 'antd/lib/progress/style/index.css'
import { Progress } from 'antd';
import { PoweroffOutlined, SyncOutlined } from '@ant-design/icons';


export class LevelComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cartridgeLevelValue: 0
    }
  }

  render() {
    return (
      <div className="ps-0">
            <div className="card">
              <div className="card-body">
                <div className="row mb-1">
                  <div className="col-3">
                    <span className='card-title'>Cartridge</span>
                  </div>
                  <div className="col ps-0">
                    <Progress
                      strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                      }}
                      percent={this.props.cartridgeLevel} strokeWidth={25} strokeLinecap={'square'} size="small"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
  }
}
export default LevelComponent;