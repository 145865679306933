import React from "react";
import NotificationService from '../../services/NotificationService';
import { Affix,Menu, Dropdown } from 'antd';
import 'antd/dist/antd.css';
import { DataTable } from '../../utils/tables/DataTable';
import { DownOutlined, SyncOutlined } from '@ant-design/icons';
var moment = require('moment'); // require
export class Notifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationsData: [],
      refreshState:true,
      refreshIntervalState:30000,
      selectedRefreshValue: "30s",
    }
  }
  handleClick(event) {
    event.preventDefault();
  }
  getNotificationsData() {
    this.setState({refreshState: true});
    NotificationService.getNotificationsByAccId().then((result) => {
      for(var i=0;i<result.length;i++){
        result[i].createdAt = moment(result[i].createdAt).local().format('YYYY-MM-DD HH:mm:ss');
      }   
      this.setState({ notificationsData: result });
      this.setState({refreshState: false})
    });
  }

  

  handleMenuClick = (e) =>{
    console.log('handleMenuClick: called')
   // clearInterval(this.clearIntervalTelemetryData); //stop interval
    console.log('e::: ', e.item.props.value, '---', e.item.props.title);
    setTimeout(() => {
      this.setNewInterval(e.item.props.value, e.item.props.title)
    }, 500);
  };

  async setNewInterval(interval, intervalTitle) {
    console.log('setNewInterval: called')
    if (interval === '0') {
      this.setState({ refreshIntervalState: 0 });
      this.setState({ selectedRefreshValue: intervalTitle });
      clearInterval(this.clearIntervalTelemetryData); //stop interval
      clearInterval(this.clearIntervalTelemetryData); //stop interval
    }
    else {
      await (this.setState({ refreshIntervalState: Number(interval) }));
      await (this.setState({ selectedRefreshValue: intervalTitle }));
      await (clearInterval(this.clearIntervalTelemetryData)); //stop interval
      this.clearIntervalTelemetryData = setInterval(() => {
        this.refresh5Sec();
      }, Number(interval));
    }
  }

  refresh5Sec = () => {
    console.log("refresh5Sec:: ");
    this.getNotificationsData();
  }

  dismissNotification = (notification) => {
    try{
      NotificationService.updateNotificationIsDeleted(notification).then((result) => {
        console.log(result);
        let updatedData = this.state.notificationsData.filter(item => item.id !== notification.id);
        this.setState({notificationsData: updatedData});
      });
    }
    catch (error){
      console.log('error dismissNotification : ', error);
    }
  }
  dismissAllNotifications = () => {
    console.log("dismissAllNotifications: ", this.state.notificationsData);
    this.setState({notificationsData : []})
    for (let i = 0; i < this.state.notificationsData.length; i++){
      const notification = this.state.notificationsData[i];
      console.log("notification; ", notification);
      try{
        NotificationService.updateNotificationIsDeleted(notification).then((result) => {
          console.log(result);
        });
      }
      catch (error){
        console.log('error dismissNotification : ', error);
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.getNotificationsData();
    }, 100)
  this.clearIntervalTelemetryData = setInterval(e => this.getNotificationsData(), this.state.refreshIntervalState);
  }

  componentWillUnmount() {
    clearInterval(this.clearIntervalTelemetryData);
  }

  menu = (
    <Menu
      onClick={this.handleMenuClick}
      items={[
        {
          title: 'Off',
          label: 'Off',
          key: '1',
          value: '0',
        },
        {
          title: '10s',
          label: '10s',
          key: '2',
          value: '10000',
        },
        {
          title: '30s',
          label: '30s',
          key: '3',
          value: '30000',
        },
        {
          title: '1m',
          label: '1m',
          key: '4',
          value: '60000',
        },
        {
          title: '15m',
          label: '15m',
          key: '5',
          value: '900000',
        }
      ]}
    />
  );

  render() {
    document.title = 'Alerts & Notifications';
    return (
      <div className="container-fluid">
        <Affix className="z-index" offsetTop={30}>
          <div className="container-fluid">
            <div  className="row pb-1 pt-2"
              style={{ backgroundColor: "#f0f1f6", border: "1px" }}>
              <nav aria-label="breadcrumb justify-content-center align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Alerts & Notifications</li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="card pe-0">
                <div className="card-body pe-0 pt-1 pb-0">
                  <div className="row">
                    <div className="col d-flex align-items-center ps-0"></div>
                    <div className="col">
                      <ul className="list-group list-group-horizontal pe-1 d-flex align-items-center justify-content-end">
                        <li className="list-group-item border-0 pe-0">
                          
                          <Dropdown.Button trigger={['click']} onClick={this.refresh5Sec} overlay={this.menu} placement="bottom" icon={<DownOutlined />}>
                            <div className="d-inline-flex align-items-center">
                              <label >{this.state.selectedRefreshValue}  </label>
                              &nbsp; <SyncOutlined />
                            </div>
                          </Dropdown.Button>
                        </li>
                        <li className="list-group-item border-0 pe-0">
                          <button onClick={this.dismissAllNotifications} className={this.state.notificationsData.length > 0 ? "btn p-2 dismiss-all-btn" : "disabled btn p-2 dismiss-all-btn"}>
                          <i className="mdi mdi-bell-outline"></i>&nbsp;
                            Dismiss all
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Affix>
        <div className="container-fluid mb-2 mt-2 pe-0 ps-0">
          <div className="row">
            <DataTable refreshState={this.state.refreshState}
              tableData={this.state.notificationsData}
              dismissNotification={this.dismissNotification}
              title="notification"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Notifications;

