import {queryHistoricalTelemetryForReports,queryCurrentTelemetryByDevIdAccId,
  queryHistoricalTelemetryForReportsByTenantId,queryHistoricalTelemetryForReportsByTenantAndDeviceId} from '../graphql/queries';
import { API } from 'aws-amplify';
import authService from './AuthService';
import DeviceService from './DeviceService';
var accountId = '';

const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  
var moment = require('moment'); // require


class DeviceTelemetryService{

       //get history telemetry for reports and also for TS chart & data-grid
       async getHistoricalTelemetryByDevIdAccIdTime(deviceId,fromTime,currentTime,limit){
        var telemetryDataChunk = [];
        var telemetryDataForReports = [];     
        let nextToken = '';

        try{
            accountId = (await authService.getCurrentUser()).accountId;
            var _tempStartDateTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";
                nextToken = '';
                telemetryDataChunk = [];
                telemetryDataChunk = await API.graphql(({query:queryHistoricalTelemetryForReports(deviceId,accountId,fromTime,currentTime,limit,nextToken),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));
                telemetryDataForReports.push.apply(telemetryDataForReports,telemetryDataChunk.data.deviceTelemetryByDate.items);
                //console.log("Deep dived and got first chunk for device: ", deviceId, "with limit: ",limit," of size: ",telemetryDataForReports.length); 
                nextToken = telemetryDataChunk.data.deviceTelemetryByDate.nextToken; 
                while(nextToken!=null){
                   // console.log("Deep dive for more data at: ",moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z", "with limit: ",limit); 
                    telemetryDataChunk = [];
                    telemetryDataChunk = await API.graphql(({query:queryHistoricalTelemetryForReports(deviceId,accountId,fromTime,currentTime,limit,nextToken),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})); 
                    //console.log("Got next chunk with total records: ",telemetryDataChunk.data.deviceTelemetryByDate.items.length);
                    //console.log("Fetched list: ",telemetryDataChunk.data.deviceTelemetryByDate.items); 
                    nextToken = telemetryDataChunk.data.deviceTelemetryByDate.nextToken;
                    telemetryDataForReports.push.apply(telemetryDataForReports,telemetryDataChunk.data.deviceTelemetryByDate.items);
                }

         // console.log("Got all records: ",telemetryDataForReports.length, "in: ",moment(moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z").diff(moment(_tempStartDateTime),'minutes'), " minutes");
          for(var i=0;i<telemetryDataForReports.length;i++){
            telemetryDataForReports[i].eventDate = moment(telemetryDataForReports[i].eventDate).local().format('YYYY-MM-DD HH:mm:ss');
          }
          return telemetryDataForReports;
        }
        catch(err){ console.log('Error Occured :: getHistoricalTelemetryForReports:: ', err); return null; };
    }
     //get history telemetry for reports and also for TS chart & data-grid
     async getHistoricalTelemetryForReports(deviceId,fromTime,currentTime,limit,nextTokenData){
       console.log('entering getHistoricalTelemetryForReports:::: ')
        var telemetryDataChunk = [];
        var telemetryDataForReports = [];
        var deviceList = [];
        let nextToken = nextTokenData;
        try{
            accountId = (await authService.getCurrentUser()).accountId;  
            if(deviceId == ''){
              var _tempStartDateTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";           
              nextToken = nextTokenData;
              telemetryDataChunk = [];
              telemetryDataChunk = await API.graphql(({query:queryHistoricalTelemetryForReportsByTenantId("",accountId,fromTime,currentTime,limit,nextToken),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));
              telemetryDataForReports.push.apply(telemetryDataForReports,telemetryDataChunk.data.deviceTelemetryByTenant);
              nextToken = telemetryDataChunk.data.deviceTelemetryByTenant.nextToken;          
        
              for(let j=0;j<(telemetryDataChunk.data.deviceTelemetryByTenant.items).length;j++){
                telemetryDataChunk.data.deviceTelemetryByTenant.items[j].eventDate = moment(telemetryDataChunk.data.deviceTelemetryByTenant.items[j].eventDate).local().format('YYYY-MM-DD HH:mm:ss')
              }
              return telemetryDataChunk;
            }else{
              var _tempStartDateTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";           
                nextToken = nextTokenData;
                telemetryDataChunk = [];
                telemetryDataChunk = await API.graphql(({query:queryHistoricalTelemetryForReportsByTenantAndDeviceId(deviceId,accountId,fromTime,currentTime,limit,nextToken),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));
                telemetryDataForReports.push.apply(telemetryDataForReports,telemetryDataChunk.data.deviceTelemetryByDate);
                nextToken = telemetryDataChunk.data.deviceTelemetryByDate.nextToken;          
          
              for(let j=0;j<(telemetryDataChunk.data.deviceTelemetryByDate.items).length;j++){
                telemetryDataChunk.data.deviceTelemetryByDate.items[j].eventDate = moment(telemetryDataChunk.data.deviceTelemetryByDate.items[j].eventDate).local().format('YYYY-MM-DD HH:mm:ss')
              }
              return telemetryDataChunk;
            }
        }
        catch(err){ console.log('Error Occured :: getHistoricalTelemetryForReports:: ', err); return null; };
    }
    
    //get current telemetry on donuts and device details on breadcrumb
   async getCurrentTelemetryByDevIdAccId(deviceId){
       try{
            accountId = (await authService.getCurrentUser()).accountId;
           const currentTelmetryByDeviceId = await API.graphql(({query:queryCurrentTelemetryByDevIdAccId(accountId,deviceId),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));
           const currentTelmetryList  = currentTelmetryByDeviceId.data.listDevices.items;
           // for(var i=0;i<telemetryList.length;i++){
           //     telemetryList[i].eventDate = moment(telemetryList[i].eventDate).local().format('YYYY-MM-DD HH:mm:ss')
           // }
           return currentTelmetryList;
       }catch(err){ console.log('Error Occured :: getCurrentTelemetryByDevIdAccId() ', err); return null; };
   }
}

export default new DeviceTelemetryService();