import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { SyncOutlined } from '@ant-design/icons';


export class StateChartComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stateChartHeight: 120,
      stateChartOptionsArray: {
        chart: {
          id: 'chart',
          selection: {
            enabled: false,
            type: 'x',
          },
          parentHeightOffset: -10,
          redrawOnParentResize: true,
          redrawOnWindowResize: true,
          height: 100,
          width: '100%',
          type: 'rangeBar',
          offsetX: -5,
          offsetY: -10,
          // stacked: true
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          }
        },
        title: {
          text: 'Mode',
          offsetX: 0,
          offsetY: 20,
          style: {   
            fontSize:  '0.825rem',         
              fontFamily: "'Open Sans', sans-serif",          
              color: '#000000',
              fontWeight:700,
              cssClass: 'apexcharts-xaxis-title',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 0,
            barHeight: '100%',
            rangeBarOverlap: true,
            rangeBarGroupRows: true,
          }
        },
        stroke: {
          width: 1,
        },
        fill: {
          type: 'solid',
          opacity: 1
        },
        yaxis: {
          labels: {
          show: false
          },
          tooltip: { enabled: false }
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 4,
          labels: {
            show: true,
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          },
          tooltip: { enabled: false }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '8vh',
          fontFamily: "'Open Sans', sans-serif",
          fontWeight: 600,
          color: '#000000',
          offsetY:-15,
          onItemClick: {
            toggleDataSeries: false
        },
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: false,
        },
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            var data = w.globals.initialSeries[seriesIndex];
            return "Mode: " +data.name
          }
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            }
          },
        }
      },
    };
  }
  componentDidMount() {
    if (window.screen.width <= 476){
      this.setState({ stateChartHeight: 140 });
    }
    else{
      this.setState({ stateChartHeight: 120 });
    }
  }
  render() {
    return (
      <div className="card">
        {/* <div className="card-body"> */}
        <div className="card-title">
        <div className="col d-flex align-items-center float-end">
          {this.props.stateChartRefreshState === true ? <SyncOutlined spin /> : <SyncOutlined spin style={{ color: 'white' }} />}
        </div>
        </div>
        
          <div id="chart" >
            <ReactApexChart options={this.state.stateChartOptionsArray} series={this.props.stateChartSeriesData} type="rangeBar" height={this.state.stateChartHeight}/>
          </div>
        {/* </div> */}
      </div>
    )
  }
}
export default StateChartComponent;