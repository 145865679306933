import * as React from 'react';
import SharedService from "../../../services/SharedService";
import { Button, Form,Modal, Select,Input  } from 'antd';
import {Link} from 'react-router-dom'
import { Spin } from 'antd';
import '../../ota-update/otaUpdate.scss';

const { Option } = Select;

var moment = require('moment'); // require

export class SingleDeviceOtaUpdate extends React.Component {
    formRef = React.createRef();
    constructor(props) {

        super(props)
        this.state = {
            visible: false,
            loading: false,
            jobName:"",
            selectedDeviceUdi:"",
            targetSelection:"SNAPSHOT",
            targetFirmware:"",
            deviceList:[],
            targetFirmwareList:[],
            modalHeader:'',
            requestedBy:'',
            deviceId:'',
            firmwareType:'',
            disabled:true,
            spinLoader:true,
        };
    }

    onCancel = () => {
        console.log('on cancel', this.formRef);
        this.formRef.current.resetFields();
        this.setState({visible:false});
        this.setState({targetFirmware:""});
        this.setState({requestedBy:""});
        this.props.parentCallback(false);
       
    }

    onChange(e){
        this.setState({jobName:e.target.value});
        this.checkFieldValidation();
    }
    onRequestedByChange(e){
        this.setState({requestedBy:e.target.value});
        this.checkFieldValidation();
    }

     tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 16,
        },
      };
      layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };
      
    //Submit function
    createOTAJob = () =>{
        
        if(this.state.firmwareType === 'comms'){
            this.setState({loading:true});
            setTimeout(() => {
                this.setState({loading:false});
                this.setState({visible: false });
                this.props.parentCallback(false);
            }, 3000);
            console.log('jobName:: ',this.state.jobName,' --Selected Device Name:: ',this.state.selectedDeviceUdi,' ---target:: ',this.state.targetSelection
            ,'---targetFirmware:: ',this.state.targetFirmware,'---requestedBy--',this.state.requestedBy,'---firmType-',this.state.firmwareType);
    
            try{
                SharedService.createOTAJobForComms(this.state.jobName,this.state.targetSelection,this.state.selectedDeviceUdi,this.state.targetFirmware,this.state.deviceId,this.state.requestedBy)
                .then((createJobResponse) => {
                    console.log('createJobResponse:: ',createJobResponse);
                });
                this.setState({disabled:true});
                this.setState({targetFirmware:""});
                this.setState({requestedBy:""});
                this.formRef.current.resetFields();

            }catch(err){
                console.log('Error occured Create OTA Job:: ',err);
            }
        }
        else{
            this.setState({loading:true});
            setTimeout(() => {
                this.setState({loading:false});
                this.setState({visible: false });
                this.props.parentCallback(false);
            }, 3000);
            console.log('jobName:: ',this.state.jobName,' --Selected Device Name:: ',this.state.selectedDeviceUdi,' ---target:: ',this.state.targetSelection
            ,'---targetFirmware:: ',this.state.targetFirmware,'---requestedBy--',this.state.requestedBy,'---firmType-',this.state.firmwareType);
    
            try{
                SharedService.createOTAJobControls(this.state.jobName,this.state.targetSelection,this.state.selectedDeviceUdi,this.state.targetFirmware,this.state.deviceId,this.state.requestedBy)
                .then((createJobResponse) => {
                    console.log('createJobResponse:: ',createJobResponse);
                });
                this.setState({disabled:true});
                this.setState({targetFirmware:""});
                this.setState({requestedBy:""});
                this.formRef.current.resetFields();

            }catch(err){
                console.log('Error occured Create OTA Job:: ',err);
            }
        }
       
        
    }

    checkFieldValidation(){
        console.log('this.state.jobName--',this.state.jobName,'this.state.targetFirmware--',this.state.targetFirmware,'this.state.requestedBY',this.state.requestedBy)
        if(this.state.requestedBy.length < 1){
        if(this.state.jobName.length <= 2 && this.state.targetFirmware <= 2 ){
           
                console.log('in if checkFieldValidation');
                this.setState({disabled:true});
            }
            
        }
        else{
            if(this.state.requestedBy.length-1 > 1){
            if(this.state.jobName.length !== 0  && this.state.targetFirmware.length !== 0 ){
                console.log('in check else',this.state.requestedBy.length);
                
                    console.log('in check else 2',this.state.requestedBy.length);
                    this.setState({disabled:false})
                }
                
            }
            
        }
    }
    

    componentDidMount() {
        console.log('Component OtaUpdate Mounting here:: ');
        this.setState({targetSelection:"SNAPSHOT"});
        
    }

    componentDidUpdate(prevProps){
        if (prevProps.visible !== this.props.visible){
            this.setState({ visible: this.props.visible });
        }
        if (prevProps.selectedDeviceUdi !== this.props.selectedDeviceUdi || prevProps.firmwareType !== this.props.firmwareType || prevProps.targetFirmwareData !== this.props.targetFirmwareData){
            this.setState({ visible: this.props.visible });
        
            if(this.props.selectedDeviceUdi != undefined){        
                    this.setState({selectedDeviceUdi: this.props.selectedDeviceUdi});
                    this.setState({deviceId: this.props.deviceId});
                    this.setState({firmwareType: this.props.firmwareType});
                    this.setState({modalHeader:'OTA '+ this.props.firmwareType+ ' update for - ' + this.props.nickname});
            
                    this.setState({jobName: this.props.selectedDeviceUdi + '_' + Date.now()})
            }
            if(this.props.targetFirmwareData != undefined){         
                if(this.props.targetFirmwareData.length == 0){
                    this.setState({targetFirmwareList: []});
                    this.setState({spinLoader: true});
                    console.log('firmware data len:: ', this.props.targetFirmwareData.length)
                }else{
                    this.setState({targetFirmwareList: this.props.targetFirmwareData});
                    console.log('firmware data len:: ', this.props.targetFirmwareData.length)
                    this.setState({spinLoader:false});
                }
            }
        }
    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                <Modal style={{marginTop: "5rem", marginBottom: "4rem"}}
                 centered={true}
                    open={this.state.visible}
                    title={this.state.modalHeader}
                    headStyle={{ backgroundColor: 'grey' }}
                    onOk={this.createOTAJob}
                    onCancel={this.onCancel}
                    
                    width={600}
                    footer={[
                    <Button key="back"  onClick={this.onCancel}>
                        Cancel
                    </Button>,

                      <>&nbsp;
                    {this.state.firmwareType === 'comms' ? <Link to='/otaComms'><Button key="submit" type="primary"    loading={this.state.loading} onClick={this.createOTAJob}>
                    Submit</Button></Link>
                    : <Link to='/otaControls'><Button key="submit" type="primary"   loading={this.state.loading} onClick={this.createOTAJob}>
                    Submit
                </Button></Link>}
                    </>
                    ]}
                >

        <Form {...this.layout} ref={this.formRef}  name="control-ref" onFinish={this.onFinish}>
        <Form.Item
          name="Job Name"
          label="Job Name"          
          rules={[{required: true,},]} >
            <span >
                <Input placeholder="Enter Job Name" value={this.state.jobName}
                    style={{
                        width: "90%",
                    }}           
                    size="large"  onChange={(e) => this.onChange(e)}    
                />                                
            </span>
        </Form.Item>        
       
        
        <Form.Item
            name="targetSelection"
            label="Job Runtype"
            rules={[{ required: true, },]}>
            <Select
                defaultValue={this.state.targetSelection}
                style={{
                    width: "90%",
                }}
                onChange={(e) => this.setState({ targetSelection: e })}
                
            >
                <Option value="SNAPSHOT">SNAPSHOT</Option>
            </Select>
                                
            </Form.Item>

            <Form.Item
                name="targetFirmware"
                label="Target Firmware "
                rules={[{ required: true, },]}>
                 
                <Select
                    placeholder="Select target firmware"
                    style={{
                        width: "90%",
                    }}
                    value={this.state.targetFirmware}
                    onChange={(e) => this.setState({ targetFirmware: e })}
                    onKeyUp={this.checkFieldValidation}
                >
                    {this.state.targetFirmwareList.map((make, index) => (
                        <Option key={make.key} value={make.key}>{make.key}</Option>
                    ))}
                </Select>
                {this.state.spinLoader == true ? <> &nbsp;&nbsp;<Spin /></>
                :
                <></>}
                
            </Form.Item>
            <Form.Item
          name="Requested By"
          label="Requested By"          
          rules={[{required: true,},]}>          
          <Input placeholder="Enter Requester Name" value={this.state.requestedBy}
                style={{
                    width: "90%",
                }}       size="large"     onChange={(e) => this.onRequestedByChange(e)}
                />                                                   
        </Form.Item>


</Form>
                       
                </Modal>
            </div>
        )
    }
}

export default SingleDeviceOtaUpdate;