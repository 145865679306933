import React from 'react';
import LocationService from '../../services/LocationService';

class LocationTree extends React.Component{
    async getLocationTree(){
        //do stuff
        var resultTreeHierarchyList = [];
        LocationService.getLocationByFilter().then((locationByFilter) => {
            const [leafNodesMap, parentNodeMap] = this.getLeafNodesAndParentNodes(locationByFilter);
    
            for(let i = 0; i < parentNodeMap.length; i++){
                const populatedFloorList = this.getFloors(parentNodeMap[i].data, i, leafNodesMap);
                const parentNode = {
                    title: parentNodeMap[i].title,
                    value: (i.toString()),
                    key: (i.toString()),
                    children: populatedFloorList,
                    data: parentNodeMap[i].data,
                    isParent: true
                }
                resultTreeHierarchyList.push(parentNode);
            }
            // console.log("useLocationTree() :: resultTreeHierarchy: ", resultTreeHierarchyList);
        })
        return resultTreeHierarchyList;
    }
    
    getLeafNodesAndParentNodes = (locationByFilter) => {
        var parentNodeMap = [];
        var leafNodesMap = [];
        for(let i = 0; i < locationByFilter.length; i++){
            if(locationByFilter[i].parentID !== '0' && locationByFilter[i].parentID !== null){   
                const leafNodeObject = {
                    title: locationByFilter[i].value,
                    data: locationByFilter[i].id,
                    parentID: locationByFilter[i].parentID
                }
                leafNodesMap.push(leafNodeObject);
            }
            else{
                const listSize = parentNodeMap.length;
                const parentNodeObject = {
                    title: locationByFilter[i].value,
                    value: listSize.toString(),
                    key: listSize.toString(),
                    data: locationByFilter[i].id,
                    parentID: locationByFilter[i].parentID
                }
                parentNodeMap.push(parentNodeObject);
            }
        }
    
        return [leafNodesMap, parentNodeMap];
    }

    getRooms = (nodeIndex, wingId, floorListSize, wingListSize, leafNodesMap) => {
        var roomDataList = [];
        for(let i = 0; i < leafNodesMap.length; i++){
            if(wingId === leafNodesMap[i].parentID){
                const roomListsize = roomDataList.length;
                const roomData = {
                    title: leafNodesMap[i].title,
                    value: (nodeIndex + '-' + floorListSize + '-' + wingListSize + '-' + roomListsize).toString(),
                    key: (nodeIndex + '-' + floorListSize + '-' + wingListSize + '-' + roomListsize).toString(),
                    data: leafNodesMap[i].data,
                    parentID: leafNodesMap[i].parentID,
                    children: []
                };
                roomDataList.push(roomData);  //Room 1, Room2        
            }      
        }  
        return roomDataList;
    }
    
    getWing = (floorId, nodeIndex, floorListSize, leafNodesMap) => {
        var wingDataList = [];
        for(let i = 0; i < leafNodesMap.length; i++){
            if(floorId === leafNodesMap[i].parentID){
                const wingListSize = wingDataList.length;
                const wingId = leafNodesMap[i].data;     
                const populatedRoomList = this.getRooms(nodeIndex, wingId, floorListSize, wingListSize, leafNodesMap); 
                const wingData = {
                    title: leafNodesMap[i].title,
                    value: (nodeIndex + '-' + floorListSize + '-' + wingListSize).toString(),
                    key: (nodeIndex + '-' + floorListSize + '-' + wingListSize).toString(),
                    data: leafNodesMap[i].data,
                    parentID: leafNodesMap[i].parentID,
                    children: populatedRoomList
                }
                wingDataList.push(wingData);  //Room 1, Room2
            }      
        }  
    return wingDataList;
    } 
    
    getFloors(rootNodeParentId, nodeIndex, leafNodesMap) {  
        var floorDataList = [];
        for(let j = 0; j < leafNodesMap.length; j++){  //building 1   
            if(rootNodeParentId === leafNodesMap[j].parentID){       
                const floorListSize = floorDataList.length;
                const floorId = leafNodesMap[j].data;
                const populatedWingList = this.getWing(floorId, nodeIndex, floorListSize, leafNodesMap); 
                const floorData = {
                    title: leafNodesMap[j].title,
                    value: (nodeIndex + '-' + floorListSize).toString(),
                    key: (nodeIndex + '-' + floorListSize).toString(),
                    data: leafNodesMap[j].data,
                    parentID: leafNodesMap[j].parentID,
                    children: populatedWingList
                }
                floorDataList.push(floorData);
            }
        }
        return floorDataList;       
    }
    
    
    getValueByLocationId = (locationTreeHierarchy, locationId) => {
        var defaultLocationValue = [];
        if(locationId === null || locationId === ""){
            defaultLocationValue.push(locationTreeHierarchy[locationTreeHierarchy.length - 1].value);
        }
        for(let i = 0; i < locationTreeHierarchy.length; i++){
            if(locationId === locationTreeHierarchy[i].data){ //building
                defaultLocationValue.push(locationTreeHierarchy[i].value);
            }
            for(let j = 0; j < locationTreeHierarchy[i].children.length; j++){ //wing
                if(locationId === locationTreeHierarchy[i].children[j].data){
                defaultLocationValue.push(locationTreeHierarchy[i].children[j].value);
                }
                for(let k = 0; k < locationTreeHierarchy[i].children[j].children.length; k++){  //floor
                    if(locationId === locationTreeHierarchy[i].children[j].children[k].data){
                        defaultLocationValue.push(locationTreeHierarchy[i].children[j].children[k].value); 
                    }
                    for(let l = 0; l < locationTreeHierarchy[i].children[j].children[k].children.length; l++){  //room
                        if(locationId === locationTreeHierarchy[i].children[j].children[k].children[l].data){
                            defaultLocationValue.push(locationTreeHierarchy[i].children[j].children[k].children[l].value); 
                        }
                    }
                }
            }
        }
        console.log('default Location Value:: ', defaultLocationValue);
        return defaultLocationValue;
    }
    
    getIdByLocationValue = (locationTreeHierarchy, value, parentToChildHierarchy) => {
        var tempLocationList = [];
        for(let x = 0; x < value.length; x++){
            for(let i = 0; i < locationTreeHierarchy.length; i++){
                if(value[x] === locationTreeHierarchy[i].value){ //root
                    tempLocationList.push(locationTreeHierarchy[i].data);
                }
                for(let j = 0; j < locationTreeHierarchy[i].children.length; j++){ //wing
                    if(value[x] === locationTreeHierarchy[i].children[j].value){
                        if (parentToChildHierarchy){
                            tempLocationList.push(locationTreeHierarchy[i].data);
                        }
                        tempLocationList.push(locationTreeHierarchy[i].children[j].data);
                    }
                    for(let k = 0; k < locationTreeHierarchy[i].children[j].children.length; k++){  //floor
                        if(value[x] === locationTreeHierarchy[i].children[j].children[k].value){
                            if (parentToChildHierarchy){
                                tempLocationList.push(locationTreeHierarchy[i].data);
                                tempLocationList.push(locationTreeHierarchy[i].children[j].data);
                            }
                            tempLocationList.push(locationTreeHierarchy[i].children[j].children[k].data); 
                        }
                        for(let l = 0; l < locationTreeHierarchy[i].children[j].children[k].children.length; l++){     //room
                            if(value[x] === locationTreeHierarchy[i].children[j].children[k].children[l].value){    
                                if (parentToChildHierarchy){
                                    tempLocationList.push(locationTreeHierarchy[i].data);
                                    tempLocationList.push(locationTreeHierarchy[i].children[j].data);
                                    tempLocationList.push(locationTreeHierarchy[i].children[j].children[k].data);
                                }
                                tempLocationList.push(locationTreeHierarchy[i].children[j].children[k].children[l].data);           
                            }
                        }
                    }
                }
            }   
        }
    
        console.log('tempList:',tempLocationList);
        return tempLocationList;
    }
}

export default new LocationTree();
