/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      status
      type
      address {
        address1
        address2
        city
        stateProvince
        postalCode
        country
      }
      externalReferences
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        type
        address {
          address1
          address2
          city
          stateProvince
          postalCode
          country
        }
        externalReferences
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        status
        type
        address {
          address1
          address2
          city
          stateProvince
          postalCode
          country
        }
        externalReferences
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      congnitoUseId
      username
      email
      avatarKey
      description
      pushToken
      accountID
      userType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        congnitoUseId
        username
        email
        avatarKey
        description
        pushToken
        accountID
        userType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        congnitoUseId
        username
        email
        avatarKey
        description
        pushToken
        accountID
        userType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      name
      status
      title
      email
      phone
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        title
        email
        phone
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        status
        title
        email
        phone
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      value
      type
      parentID
      parentHierarchy
      parentName
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        type
        parentID
        parentHierarchy
        parentName
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        value
        type
        parentID
        parentHierarchy
        parentName
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const locationsByTenant = /* GraphQL */ `
  query LocationsByTenant(
    $accountID: String!
    $valueTypeParentID: ModelLocationLocationsByTenantCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsByTenant(
      accountID: $accountID
      valueTypeParentID: $valueTypeParentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        type
        parentID
        parentHierarchy
        parentName
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      udi
      parentHierarchy
      accountID
      mode
      cartridgeLevel
      clo2
      lastShotSize
      temperature
      humidity
      barometricPressure
      target
      metadata {
        id
        name
        udi
        status
        type
        externalReferences
        parentHierarchy
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        locationId
        location {
          id
          value
          type
          parentID
          parentHierarchy
          parentName
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      commsVersion
      controllerVersion
      model
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      deviceMetadataId
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDevices = /* GraphQL */ `
  query SyncDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      nextToken
      startedAt
    }
  }
`;
export const devicesByTenant = /* GraphQL */ `
  query DevicesByTenant(
    $accountID: String!
    $modeClo2CartridgeLevel: ModelDeviceDevicesByTenantCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByTenant(
      accountID: $accountID
      modeClo2CartridgeLevel: $modeClo2CartridgeLevel
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeviceMetadata = /* GraphQL */ `
  query GetDeviceMetadata($id: ID!) {
    getDeviceMetadata(id: $id) {
      id
      name
      udi
      status
      type
      externalReferences
      parentHierarchy
      deviceId
      device {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      locationId
      location {
        id
        value
        type
        parentID
        parentHierarchy
        parentName
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDeviceMetadata = /* GraphQL */ `
  query ListDeviceMetadata(
    $filter: ModelDeviceMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceMetadata(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        udi
        status
        type
        externalReferences
        parentHierarchy
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        locationId
        location {
          id
          value
          type
          parentID
          parentHierarchy
          parentName
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeviceMetadata = /* GraphQL */ `
  query SyncDeviceMetadata(
    $filter: ModelDeviceMetadataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeviceMetadata(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        udi
        status
        type
        externalReferences
        parentHierarchy
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        locationId
        location {
          id
          value
          type
          parentID
          parentHierarchy
          parentName
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const metadataByTenant = /* GraphQL */ `
  query MetadataByTenant(
    $accountID: String!
    $udi: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    metadataByTenant(
      accountID: $accountID
      udi: $udi
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        udi
        status
        type
        externalReferences
        parentHierarchy
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        locationId
        location {
          id
          value
          type
          parentID
          parentHierarchy
          parentName
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeviceTelemetry = /* GraphQL */ `
  query GetDeviceTelemetry($id: ID!) {
    getDeviceTelemetry(id: $id) {
      id
      clo2 {
        label
        value
        uom
        icon
      }
      temperature {
        label
        value
        uom
        icon
      }
      humidity {
        label
        value
        uom
        icon
      }
      barometricPressure {
        label
        value
        uom
        icon
      }
      generated
      lastShotSize {
        label
        value
        uom
        icon
      }
      target {
        label
        value
        uom
        icon
      }
      targetReached
      timeInModeMs
      sgp4x4
      sgp4x5
      sgp4x7
      sgp4x2
      deviceId
      device {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      eventDate
      rawEvent
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDeviceTelemetries = /* GraphQL */ `
  query ListDeviceTelemetries(
    $filter: ModelDeviceTelemetryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceTelemetries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clo2 {
          label
          value
          uom
          icon
        }
        temperature {
          label
          value
          uom
          icon
        }
        humidity {
          label
          value
          uom
          icon
        }
        barometricPressure {
          label
          value
          uom
          icon
        }
        generated
        lastShotSize {
          label
          value
          uom
          icon
        }
        target {
          label
          value
          uom
          icon
        }
        targetReached
        timeInModeMs
        sgp4x4
        sgp4x5
        sgp4x7
        sgp4x2
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        eventDate
        rawEvent
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeviceTelemetries = /* GraphQL */ `
  query SyncDeviceTelemetries(
    $filter: ModelDeviceTelemetryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeviceTelemetries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clo2 {
          label
          value
          uom
          icon
        }
        temperature {
          label
          value
          uom
          icon
        }
        humidity {
          label
          value
          uom
          icon
        }
        barometricPressure {
          label
          value
          uom
          icon
        }
        generated
        lastShotSize {
          label
          value
          uom
          icon
        }
        target {
          label
          value
          uom
          icon
        }
        targetReached
        timeInModeMs
        sgp4x4
        sgp4x5
        sgp4x7
        sgp4x2
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        eventDate
        rawEvent
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceTelemetryByDate = /* GraphQL */ `
  query DeviceTelemetryByDate(
    $deviceId: ID!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceTelemetryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceTelemetryByDate(
      deviceId: $deviceId
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clo2 {
          label
          value
          uom
          icon
        }
        temperature {
          label
          value
          uom
          icon
        }
        humidity {
          label
          value
          uom
          icon
        }
        barometricPressure {
          label
          value
          uom
          icon
        }
        generated
        lastShotSize {
          label
          value
          uom
          icon
        }
        target {
          label
          value
          uom
          icon
        }
        targetReached
        timeInModeMs
        sgp4x4
        sgp4x5
        sgp4x7
        sgp4x2
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        eventDate
        rawEvent
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceTelemetryByTenant = /* GraphQL */ `
  query DeviceTelemetryByTenant(
    $accountID: String!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceTelemetryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceTelemetryByTenant(
      accountID: $accountID
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clo2 {
          label
          value
          uom
          icon
        }
        temperature {
          label
          value
          uom
          icon
        }
        humidity {
          label
          value
          uom
          icon
        }
        barometricPressure {
          label
          value
          uom
          icon
        }
        generated
        lastShotSize {
          label
          value
          uom
          icon
        }
        target {
          label
          value
          uom
          icon
        }
        targetReached
        timeInModeMs
        sgp4x4
        sgp4x5
        sgp4x7
        sgp4x2
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        eventDate
        rawEvent
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeviceConfiguration = /* GraphQL */ `
  query GetDeviceConfiguration($id: ID!) {
    getDeviceConfiguration(id: $id) {
      id
      rawEvent
      eventDate
      deviceId
      device {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      telemetryPublishRateMs
      parameterPollingRateMs
      commsVersion
      controllerVersion
      model
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDeviceConfigurations = /* GraphQL */ `
  query ListDeviceConfigurations(
    $filter: ModelDeviceConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rawEvent
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        telemetryPublishRateMs
        parameterPollingRateMs
        commsVersion
        controllerVersion
        model
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeviceConfigurations = /* GraphQL */ `
  query SyncDeviceConfigurations(
    $filter: ModelDeviceConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeviceConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rawEvent
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        telemetryPublishRateMs
        parameterPollingRateMs
        commsVersion
        controllerVersion
        model
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceConfigByDate = /* GraphQL */ `
  query DeviceConfigByDate(
    $deviceId: ID!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceConfigByDate(
      deviceId: $deviceId
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rawEvent
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        telemetryPublishRateMs
        parameterPollingRateMs
        commsVersion
        controllerVersion
        model
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceConfigByTenant = /* GraphQL */ `
  query DeviceConfigByTenant(
    $accountID: String!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceConfigByTenant(
      accountID: $accountID
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rawEvent
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        telemetryPublishRateMs
        parameterPollingRateMs
        commsVersion
        controllerVersion
        model
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeviceState = /* GraphQL */ `
  query GetDeviceState($id: ID!) {
    getDeviceState(id: $id) {
      id
      mode
      cartridgeLevel {
        label
        value
        uom
        icon
      }
      deviceId
      device {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      eventDate
      rawEvent
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDeviceStates = /* GraphQL */ `
  query ListDeviceStates(
    $filter: ModelDeviceStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mode
        cartridgeLevel {
          label
          value
          uom
          icon
        }
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        eventDate
        rawEvent
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeviceStates = /* GraphQL */ `
  query SyncDeviceStates(
    $filter: ModelDeviceStateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeviceStates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mode
        cartridgeLevel {
          label
          value
          uom
          icon
        }
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        eventDate
        rawEvent
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceStateByDate = /* GraphQL */ `
  query DeviceStateByDate(
    $deviceId: ID!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceStateByDate(
      deviceId: $deviceId
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mode
        cartridgeLevel {
          label
          value
          uom
          icon
        }
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        eventDate
        rawEvent
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceStateByTenant = /* GraphQL */ `
  query DeviceStateByTenant(
    $accountID: String!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceStateByTenant(
      accountID: $accountID
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mode
        cartridgeLevel {
          label
          value
          uom
          icon
        }
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        eventDate
        rawEvent
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserPreference = /* GraphQL */ `
  query GetUserPreference($id: ID!) {
    getUserPreference(id: $id) {
      id
      user_id
      dark_mode
      low_cartridge
      high_temperature
      low_temperature
      device_disconnected
      default_location
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserPreferences = /* GraphQL */ `
  query ListUserPreferences(
    $filter: ModelUserPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        dark_mode
        low_cartridge
        high_temperature
        low_temperature
        device_disconnected
        default_location
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserPreferences = /* GraphQL */ `
  query SyncUserPreferences(
    $filter: ModelUserPreferenceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserPreferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        dark_mode
        low_cartridge
        high_temperature
        low_temperature
        device_disconnected
        default_location
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($id: ID!) {
    getNotifications(id: $id) {
      id
      alertId
      userPreferenceId
      username
      tokens
      deviceId
      deviceName
      message
      type
      value
      active
      eventDate
      lastSent
      isSeen
      isClicked
      isDeleted
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        alertId
        userPreferenceId
        username
        tokens
        deviceId
        deviceName
        message
        type
        value
        active
        eventDate
        lastSent
        isSeen
        isClicked
        isDeleted
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        alertId
        userPreferenceId
        username
        tokens
        deviceId
        deviceName
        message
        type
        value
        active
        eventDate
        lastSent
        isSeen
        isClicked
        isDeleted
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeviceOTAHistory = /* GraphQL */ `
  query GetDeviceOTAHistory($id: ID!) {
    getDeviceOTAHistory(id: $id) {
      id
      targetFirmware
      firmwareType
      jobRunType
      jobName
      jobId
      jobStatus
      requestedBy
      executedBy
      eventDate
      deviceId
      device {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDeviceOTAHistories = /* GraphQL */ `
  query ListDeviceOTAHistories(
    $filter: ModelDeviceOTAHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceOTAHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeviceOTAHistories = /* GraphQL */ `
  query SyncDeviceOTAHistories(
    $filter: ModelDeviceOTAHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeviceOTAHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const oTAHistoryByDate = /* GraphQL */ `
  query OTAHistoryByDate(
    $deviceId: ID!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceOTAHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OTAHistoryByDate(
      deviceId: $deviceId
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const oTAHistoryByTenant = /* GraphQL */ `
  query OTAHistoryByTenant(
    $accountID: String!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceOTAHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OTAHistoryByTenant(
      accountID: $accountID
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeviceOTARuntime = /* GraphQL */ `
  query GetDeviceOTARuntime($id: ID!) {
    getDeviceOTARuntime(id: $id) {
      id
      targetFirmware
      firmwareType
      jobRunType
      jobName
      jobId
      jobStatus
      requestedBy
      executedBy
      eventDate
      deviceId
      device {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDeviceOTARuntimes = /* GraphQL */ `
  query ListDeviceOTARuntimes(
    $filter: ModelDeviceOTARuntimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceOTARuntimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeviceOTARuntimes = /* GraphQL */ `
  query SyncDeviceOTARuntimes(
    $filter: ModelDeviceOTARuntimeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeviceOTARuntimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const oTARuntimeByDate = /* GraphQL */ `
  query OTARuntimeByDate(
    $deviceId: ID!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceOTARuntimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OTARuntimeByDate(
      deviceId: $deviceId
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const oTARuntimeByTenant = /* GraphQL */ `
  query OTARuntimeByTenant(
    $accountID: String!
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceOTARuntimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OTARuntimeByTenant(
      accountID: $accountID
      eventDate: $eventDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReportsExportHistory = /* GraphQL */ `
  query GetReportsExportHistory($id: ID!) {
    getReportsExportHistory(id: $id) {
      id
      reportType
      destinationUrl
      downloadStatus
      exportedBy
      filter
      exportDateTime
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReportsExportHistories = /* GraphQL */ `
  query ListReportsExportHistories(
    $filter: ModelReportsExportHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportsExportHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportType
        destinationUrl
        downloadStatus
        exportedBy
        filter
        exportDateTime
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReportsExportHistories = /* GraphQL */ `
  query SyncReportsExportHistories(
    $filter: ModelReportsExportHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReportsExportHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        reportType
        destinationUrl
        downloadStatus
        exportedBy
        filter
        exportDateTime
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const reportsExportHistoryByTenant = /* GraphQL */ `
  query ReportsExportHistoryByTenant(
    $accountID: String!
    $exportDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportsExportHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReportsExportHistoryByTenant(
      accountID: $accountID
      exportDateTime: $exportDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportType
        destinationUrl
        downloadStatus
        exportedBy
        filter
        exportDateTime
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCommandLogs = /* GraphQL */ `
  query GetCommandLogs($id: ID!) {
    getCommandLogs(id: $id) {
      id
      userName
      command
      commandAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCommandLogs = /* GraphQL */ `
  query ListCommandLogs(
    $filter: ModelCommandLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommandLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        command
        commandAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCommandLogs = /* GraphQL */ `
  query SyncCommandLogs(
    $filter: ModelCommandLogsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCommandLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userName
        command
        commandAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCRUDLogs = /* GraphQL */ `
  query GetCRUDLogs($id: ID!) {
    getCRUDLogs(id: $id) {
      id
      userName
      modelName
      mutationType
      request
      response
      errorResponse
      success
      isOverwritten
      queryTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCRUDLogs = /* GraphQL */ `
  query ListCRUDLogs(
    $filter: ModelCRUDLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCRUDLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        modelName
        mutationType
        request
        response
        errorResponse
        success
        isOverwritten
        queryTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCRUDLogs = /* GraphQL */ `
  query SyncCRUDLogs(
    $filter: ModelCRUDLogsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCRUDLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userName
        modelName
        mutationType
        request
        response
        errorResponse
        success
        isOverwritten
        queryTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const queryDevicesByAccId = (accountID) => {
  return `query {
    listDevices(filter: {accountID: {eq: "`+accountID+`"}}) {
      items {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      nextToken
      startedAt
      }
  }`
}

export const queryCurrentTelemetryByDevIdAccId = (accountID,deviceUUID) => {
  return `query {
    listDevices(filter: {id: {eq: "`+deviceUUID+`"}, accountID: {eq: "`+accountID+`"}}) {
      items {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      nextToken
      startedAt
      }
  }`
}

export const queryHistoricalTelemetryByDevIdAccIdTime = (accountID,deviceId,fromTime,currentTime,limitVal) => {
  if(deviceId == 0){  //for DeviceEventReport deviceId
    console.log("queryHistoricalTelemetryByDevIdAccIdTime - deviceId: ",deviceId);
    return `query {
      listDeviceTelemetries(limit: 30000,filter: {accountID: {eq: "`+accountID+`"},eventDate: {between: ["`+fromTime+`","`+currentTime+`"]}}) {
        items {
          id
          clo2 {
            label
            value
            uom
            icon
          }
          temperature {
            label
            value
            uom
            icon
          }
          humidity {
            label
            value
            uom
            icon
          }
          barometricPressure {
            label
            value
            uom
            icon
          }
          generated
          lastShotSize {
            label
            value
            uom
            icon
          }
          target {
            label
            value
            uom
            icon
          }
          targetReached
          timeInModeMs 
          sgp4x4
          sgp4x5
          sgp4x7
          sgp4x2
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target 
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    metadata {
                      id
                      name
                      udi
                      status
                      type
                      externalReferences
                      parentHierarchy
                      deviceId
                      locationId
                      accountID
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          eventDate
          rawEvent
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      nextToken
      startedAt
      }
    }`
  }else{
    return `query {
      deviceTelemetryByDate(deviceId: "`+deviceId+`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},limit:`+limitVal+`,sortDirection: DESC) {
        items {
          id
          clo2 {
            label
            value
            uom
            icon
          }
          temperature {
            label
            value
            uom
            icon
          }
          humidity {
            label
            value
            uom
            icon
          }
          barometricPressure {
            label
            value
            uom
            icon
          }
          generated
          lastShotSize {
            label
            value
            uom
            icon
          }
          target {
            label
            value
            uom
            icon
          }
          targetReached
          timeInModeMs 
          sgp4x4
          sgp4x5
          sgp4x7
          sgp4x2
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target 
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target 
                    metadata {
                      id
                      name
                      udi
                      status
                      type
                      externalReferences
                      parentHierarchy
                      deviceId
                      locationId
                      accountID
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          eventDate
          rawEvent
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      nextToken
      startedAt
      }
    }`
  }
}

export const queryHistoricalTelemetryForReports = (deviceId, accountID,fromTime,currentTime,limit,nextToken) => {
  if(nextToken == ''){ 
    console.log("queryHistoricalTelemetryForReports - deviceId: ",deviceId);
    return `query {
      deviceTelemetryByDate(deviceId: "`+deviceId+`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},filter: {accountID: {eq: "`+accountID+`"}},limit:`+limit+`,sortDirection: DESC) {
        items {
          id
          clo2 {
            label
            value
            uom
            icon
          }
          temperature {
            label
            value
            uom
            icon
          }
          humidity {
            label
            value
            uom
            icon
          }
          barometricPressure {
            label
            value
            uom
            icon
          }
          generated
          lastShotSize {
            label
            value
            uom
            icon
          }
          target {
            label
            value
            uom
            icon
          }
          targetReached
          timeInModeMs 
          sgp4x4
          sgp4x5
          sgp4x7
          sgp4x2
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target 
                    metadata {
                      id
                      name
                      udi
                      status
                      type
                      externalReferences
                      parentHierarchy
                      deviceId
                      locationId
                      accountID
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          eventDate
          rawEvent
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`
  }
  else{ 
    return `query {
      deviceTelemetryByDate(deviceId: "`+deviceId+`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},filter: {accountID: {eq: "`+accountID+`"}},limit:`+limit+`,nextToken:"`+nextToken+`", sortDirection: DESC) {
        items {
          id
          clo2 {
            label
            value
            uom
            icon
          }
          temperature {
            label
            value
            uom
            icon
          }
          humidity {
            label
            value
            uom
            icon
          }
          barometricPressure {
            label
            value
            uom
            icon
          }
          generated
          lastShotSize {
            label
            value
            uom
            icon
          }
          target {
            label
            value
            uom
            icon
          }
          targetReached
          timeInModeMs 
          sgp4x4
          sgp4x5
          sgp4x7
          sgp4x2
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target 
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target 
                    metadata {
                      id
                      name
                      udi
                      status
                      type
                      externalReferences
                      parentHierarchy
                      deviceId
                      locationId
                      accountID
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          eventDate
          rawEvent
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`
  }
}

export const queryHistoricalTelemetryForReportsByTenantId = (deviceId, accountID,fromTime,currentTime,limit,nextToken) => {
  if(nextToken == ''){ 
    //console.log("queryHistoricalTelemetryForReportsByTenantId - deviceId: ",deviceId);
    return `query {
      deviceTelemetryByTenant(accountID: "`+accountID+`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},limit:`+limit+`,sortDirection: DESC) {
        items {
          id
          clo2 {
            label
            value
            uom
            icon
          }
          temperature {
            label
            value
            uom
            icon
          }
          humidity {
            label
            value
            uom
            icon
          }
          barometricPressure {
            label
            value
            uom
            icon
          }
          generated
          lastShotSize {
            label
            value
            uom
            icon
          }
          target {
            label
            value
            uom
            icon
          }
          targetReached
          timeInModeMs 
          sgp4x4
          sgp4x5
          sgp4x7
          sgp4x2
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target 
                    metadata {
                      id
                      name
                      udi
                      status
                      type
                      externalReferences
                      parentHierarchy
                      deviceId
                      locationId
                      accountID
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          eventDate
          rawEvent
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`
  }
  else{ 
    return `query {
      deviceTelemetryByTenant(accountID : "`+accountID +`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},limit:`+limit+`,nextToken:"`+nextToken+`", sortDirection: DESC) {
        items {
          id
          clo2 {
            label
            value
            uom
            icon
          }
          temperature {
            label
            value
            uom
            icon
          }
          humidity {
            label
            value
            uom
            icon
          }
          barometricPressure {
            label
            value
            uom
            icon
          }
          generated
          lastShotSize {
            label
            value
            uom
            icon
          }
          target {
            label
            value
            uom
            icon
          }
          targetReached
          timeInModeMs 
          sgp4x4
          sgp4x5
          sgp4x7
          sgp4x2
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target 
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target 
                    metadata {
                      id
                      name
                      udi
                      status
                      type
                      externalReferences
                      parentHierarchy
                      deviceId
                      locationId
                      accountID
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          eventDate
          rawEvent
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`
  }
}

export const queryHistoricalTelemetryForReportsByTenantAndDeviceId = (deviceId, accountID,fromTime,currentTime,limit,nextToken) => {
  console.log('nextToken:: ',nextToken);
  if(nextToken == ''){ 
    //console.log("queryHistoricalTelemetryForReportsByTenantAndDeviceId - deviceId: ",deviceId);
    return `query {
      deviceTelemetryByDate(deviceId: "`+deviceId+`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},limit:`+limit+`,sortDirection: DESC,filter: {accountID: {eq: "`+accountID+`"}}) {
        items {
          id
          clo2 {
            label
            value
            uom
            icon
          }
          temperature {
            label
            value
            uom
            icon
          }
          humidity {
            label
            value
            uom
            icon
          }
          barometricPressure {
            label
            value
            uom
            icon
          }
          generated
          lastShotSize {
            label
            value
            uom
            icon
          }
          target {
            label
            value
            uom
            icon
          }
          targetReached
          timeInModeMs 
          sgp4x4
          sgp4x5
          sgp4x7
          sgp4x2
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target 
                    metadata {
                      id
                      name
                      udi
                      status
                      type
                      externalReferences
                      parentHierarchy
                      deviceId
                      locationId
                      accountID
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          eventDate
          rawEvent
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`
  }
  else{ 
    return `query {
      deviceTelemetryByDate(deviceId : "`+deviceId +`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},limit:`+limit+`,nextToken:"`+nextToken+`", sortDirection: DESC,filter: {accountID: {eq: "`+accountID+`"}}) {
        items {
          id
          clo2 {
            label
            value
            uom
            icon
          }
          temperature {
            label
            value
            uom
            icon
          }
          humidity {
            label
            value
            uom
            icon
          }
          barometricPressure {
            label
            value
            uom
            icon
          }
          generated
          lastShotSize {
            label
            value
            uom
            icon
          }
          target {
            label
            value
            uom
            icon
          }
          targetReached
          timeInModeMs 
          sgp4x4
          sgp4x5
          sgp4x7
          sgp4x2
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target 
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target 
                    metadata {
                      id
                      name
                      udi
                      status
                      type
                      externalReferences
                      parentHierarchy
                      deviceId
                      locationId
                      accountID
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          eventDate
          rawEvent
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }`
  }
}

export const queryDeviceForLocationDevicesId  = (locationDevicesId,accountId) => {
  return `query {
    listDevices(filter: {parentHierarchy: {contains: "`+locationDevicesId+`"},accountID: {eq: "`+accountId+`"}}) {
      items {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target 
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target 
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      nextToken
      startedAt
    }
  }`
}

export const listLocationsByAccId= (accountId) => {
return `query {
  listLocations(filter: {accountID: {eq: "`+accountId+`"}}) {
    items {
        id
        value
        type
        parentID
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
  }
 }`
}

export const listUserByEmailId = (userEmailId) =>{
   return `query {
  listUsers(filter: {email: {eq: "`+userEmailId+`"}}) {
    items {
      id
      congnitoUseId
      username
      email
      avatarKey
      description
      pushToken
      accountID
      userType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`};

export const queryOTALiveByAccIdFirmwareType = (accountID,firmwareType) => {
//console.log('accountId:',accountID,firmwareType)
return `query {
  listDeviceOTARuntimes(filter: {accountID: {eq: "`+accountID+`"},firmwareType: {eq: "`+firmwareType+`"}}) {
    items {
      id
      targetFirmware
      firmwareType
      jobRunType
      jobName
      jobId
      jobStatus
      requestedBy
      executedBy
      eventDate
      deviceId
      device {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target 
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target 
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  locationId
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  nextToken
  startedAt
  }
}`
};
export const queryOTAHistoryByAccIdTime = (deviceId,accountID,fromTime,currentTime,limit,nextToken) => {
if(nextToken === '' || nextToken === null){
  console.log('from--',fromTime,'current--',currentTime,'nextToken--',nextToken,'if')
  return `query {
    OTAHistoryByDate(deviceId: "`+deviceId+`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},filter: {accountID: {eq: "`+accountID+`"}},limit:`+limit+`,sortDirection: DESC){
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
        nextToken
        startedAt
      }
    
    }
  `
}

else{
  console.log('from--',fromTime,'current--',currentTime,'nextToken--',nextToken,'else')
  return `query {
    OTAHistoryByDate(deviceId: "`+deviceId+`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},filter: {accountID: {eq: "`+accountID+`"}},limit:`+limit+`,nextToken:"`+nextToken+`", sortDirection: DESC){
      items {
        id
        targetFirmware
        firmwareType
        jobRunType
        jobName
        jobId
        jobStatus
        requestedBy
        executedBy
        eventDate
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
        nextToken
        startedAt
      }
    
    }
  `
};
}



export const queryStateTelemetryByAccIdDevIdTime = (accountID,deviceId,fromTime,currentTime,limit,nextToken) => {
return `query {
  deviceStateByDate(deviceId: "`+deviceId+`", eventDate: {between: ["`+fromTime+`","`+currentTime+`"]},filter: {accountID: {eq: "`+accountID+`"}},limit:`+limit+`,sortDirection: DESC) {
    items {
      id
      mode
      cartridgeLevel {
        label
        value
        uom
        icon
      }
      deviceId
      device {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target 
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target 
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  locationId
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      eventDate
      rawEvent
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}` 
}
export const queryTopStateTelemetryByDevIdAccId = (accountID,deviceId,limit) => {
return `query {
deviceStateByDate(deviceId: "`+deviceId+`",filter: {accountID: {eq: "`+accountID+`"}},limit:`+limit+`,sortDirection: DESC) {
  items {
    id
    mode
    cartridgeLevel {
      label
      value
      uom
      icon
    }
    deviceId
    device {
      id
      udi
      parentHierarchy
      accountID
      mode
      cartridgeLevel
      clo2
      lastShotSize
      temperature
      humidity
      barometricPressure
      target 
      metadata {
        id
        name
        udi
        status
        type
        externalReferences
        parentHierarchy
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target 
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
              target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                locationId
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        locationId
        location {
          id
          value
          type
          parentID
          parentHierarchy
          parentName
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      commsVersion
      controllerVersion
      model
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      deviceMetadataId
    }
    eventDate
    rawEvent
    accountID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
  }
  nextToken
  startedAt
}
}`
};
export const queryOTALiveByAccId = (accountID) => {
//console.log('accountId:',accountID)
return `query {
  listDeviceOTARuntimes(filter: {accountID: {eq: "`+accountID+`"}}) {
    items {
      id
      targetFirmware
      firmwareType
      jobRunType
      jobName
      jobId
      jobStatus
      requestedBy
      executedBy
      eventDate
      deviceId
      device {
        id
        udi
        parentHierarchy
        accountID
        mode
        cartridgeLevel
        clo2
        lastShotSize
        temperature
        humidity
        barometricPressure
        target
        metadata {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
           target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target 
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  locationId
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        commsVersion
        controllerVersion
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deviceMetadataId
      }
      accountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  nextToken
  startedAt
  }
}`
};
export const queryNotificationsByAccId= (accountID,username,isDeleted) => {
return `query {
  listNotifications(filter: {accountID: {eq: "`+accountID+`"},username: {eq: "`+username+`"},isDeleted: {ne: `+isDeleted+`}}) {
    
      items {
        id
        alertId
        userPreferenceId
        username
        tokens
        deviceId
        deviceName
        message
        type
        value
        active
        eventDate
        lastSent
        isSeen
        isClicked
        isDeleted
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    nextToken
    startedAt
  }
}`
};

export const queryNotificationsByAccIdWhereIsSeen= (accountID,username,isDeleted, isSeen) => {
  return `query {
    listNotifications(filter: {accountID: {eq: "`+accountID+`"},username: {eq: "`+username+`"},isDeleted: {ne: `+isDeleted+`}, isSeen: {ne: `+isSeen+`}}) {
      
        items {
          id
          alertId
          userPreferenceId
          username
          tokens
          deviceId
          deviceName
          message
          type
          value
          active
          eventDate
          lastSent
          isSeen
          isClicked
          isDeleted
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      nextToken
      startedAt
    }
  }`
  };
export const queryStateTelemetryDataByAccIdDevIdTimeAndLimit = (accountID,deviceId,fromTime,limit) => {
  return `query {
    deviceStateByDate(deviceId: "`+deviceId+`", eventDate: {lt: "`+fromTime+`"},filter: {accountID: {eq: "`+accountID+`"}},limit:`+limit+`,sortDirection: DESC) {
      items {
        id
        mode
        cartridgeLevel {
          label
          value
          uom
          icon
        }
        deviceId
        device {
          id
          udi
          parentHierarchy
          accountID
          mode
          cartridgeLevel
          clo2
          lastShotSize
          temperature
          humidity
          barometricPressure
          target 
          metadata {
            id
            name
            udi
            status
            type
            externalReferences
            parentHierarchy
            deviceId
            device {
              id
              udi
              parentHierarchy
              accountID
              mode
              cartridgeLevel
              clo2
              lastShotSize
              temperature
              humidity
              barometricPressure
             target
              metadata {
                id
                name
                udi
                status
                type
                externalReferences
                parentHierarchy
                deviceId
                device {
                  id
                  udi
                  parentHierarchy
                  accountID
                  mode
                  cartridgeLevel
                  clo2
                  lastShotSize
                  temperature
                  humidity
                  barometricPressure
                  target 
                  metadata {
                    id
                    name
                    udi
                    status
                    type
                    externalReferences
                    parentHierarchy
                    deviceId
                    locationId
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  commsVersion
                  controllerVersion
                  model
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  deviceMetadataId
                }
                locationId
                location {
                  id
                  value
                  type
                  parentID
                  parentHierarchy
                  parentName
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              commsVersion
              controllerVersion
              model
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              deviceMetadataId
            }
            locationId
            location {
              id
              value
              type
              parentID
              parentHierarchy
              parentName
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          commsVersion
          controllerVersion
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deviceMetadataId
        }
        eventDate
        rawEvent
        accountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }` 
  }
  export const queryReportExportHistoryByAccId = (accountID) => {
    return `query {
      ReportsExportHistoryByTenant(accountID : "`+accountID +`",sortDirection: DESC) {
        items {
          id
          reportType
          destinationUrl
          downloadStatus
          exportedBy
          filter
          exportDateTime
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }` 
    }

  export const queryDevicesMetaDataByAccId = (accountID) => {
    return `query {
      listDeviceMetadata(filter: {accountID: {eq: "`+accountID+`"}}) {
        items {
          id
          name
          udi
          status
          type
          externalReferences
          parentHierarchy
          deviceId
          device {
            id
            udi
            parentHierarchy
            accountID
            mode
            cartridgeLevel
            clo2
            lastShotSize
            temperature
            humidity
            barometricPressure
            target
            metadata {
              id
              name
              udi
              status
              type
              externalReferences
              parentHierarchy
              deviceId
              device {
                id
                udi
                parentHierarchy
                accountID
                mode
                cartridgeLevel
                clo2
                lastShotSize
                temperature
                humidity
                barometricPressure
                target
                metadata {
                  id
                  name
                  udi
                  status
                  type
                  externalReferences
                  parentHierarchy
                  deviceId
                  device {
                    id
                    udi
                    parentHierarchy
                    accountID
                    mode
                    cartridgeLevel
                    clo2
                    lastShotSize
                    temperature
                    humidity
                    barometricPressure
                    target
                    metadata {
                      id
                      name
                      udi
                      status
                      type
                      externalReferences
                      parentHierarchy
                      deviceId
                      locationId
                      accountID
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    commsVersion
                    controllerVersion
                    model
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    deviceMetadataId
                  }
                  locationId
                  location {
                    id
                    value
                    type
                    parentID
                    parentHierarchy
                    parentName
                    accountID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  accountID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                commsVersion
                controllerVersion
                model
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                deviceMetadataId
              }
              locationId
              location {
                id
                value
                type
                parentID
                parentHierarchy
                parentName
                accountID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              accountID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            commsVersion
            controllerVersion
            model
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            deviceMetadataId
          }
          locationId
          location {
            id
            value
            type
            parentID
            parentHierarchy
            parentName
            accountID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
}`
};
