import { API } from 'aws-amplify';
import { queryNotificationsByAccId, queryNotificationsByAccIdWhereIsSeen} from '../graphql/queries';
import {updateNotifications} from '../graphql/mutations';
import React from 'react';
import authService from './AuthService';

var moment = require('moment'); // require
const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  
 var accountId= '';
 var username = '';
class NotificationService extends React.Component{

    async getNotificationsByAccId(){
        //console.log('getDevicesByAccId()',accountId);
        let isDeleted = true;
        username = (await authService.getCurrentUser()).userName;
        accountId = (await authService.getCurrentUser()).accountId;
        try {
            const notificationsList = await API.graphql({query:queryNotificationsByAccId(accountId,username,isDeleted),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
            const notificationsListData = notificationsList.data.listNotifications.items;
            const notificationsListSort = notificationsListData.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
            //console.log("getNotificationsByAccId::notificationsListSort: ",notificationsListSort);
            for(var i=0;i<notificationsListSort.length;i++){
                notificationsListSort[i].eventDate = moment(notificationsListSort[i].eventDate).local().format('YYYY-MM-DD HH:mm:ss');
              }
            return notificationsListSort;
        } catch (err) { 
              console.log('Error Occured getNotificationsByAccId:: ',err); return null; 
        }
    }

    async getNotificationsByAccIdWhereIsSeen(){
        //console.log('getDevicesByAccId()',accountId);
        let isDeleted = true;
        let isSeen = true;
        username = (await authService.getCurrentUser()).userName;
        accountId = (await authService.getCurrentUser()).accountId;
        try {
            const notificationsList = await API.graphql({query:queryNotificationsByAccIdWhereIsSeen(accountId,username,isDeleted, isSeen),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
            const notificationsListData = notificationsList.data.listNotifications.items;
            const notificationsListSort = notificationsListData.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
            //console.log("getNotificationsByAccId::notificationsListSort: ",notificationsListSort);
            for(var i=0;i<notificationsListSort.length;i++){
                notificationsListSort[i].eventDate = moment(notificationsListSort[i].eventDate).local().format('YYYY-MM-DD HH:mm:ss');
              }
            return notificationsListSort;
        } catch (err) { 
              console.log('Error Occured getNotificationsByAccId:: ',err); return null; 
        }
    }

    async updateNotificationIsDeleted(notification){
        username = (await authService.getCurrentUser()).userName;
        accountId = (await authService.getCurrentUser()).accountId;
        const notificationId = notification.id;
        const version = notification._version;
        const isDeleted = true;
        try{
            
            const inputData = {id:notificationId,_version:version,username:username,accountID:accountId,isDeleted:isDeleted};
            console.log('inputData--',inputData)
            const updateResponse = await API.graphql({ query: updateNotifications, variables: {input: inputData},authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken});

        }
        catch (err) { 
            console.log('Error Occured updateNotificationIsDeleted:: ',err); return null; 
      }
    }
}
export default new NotificationService();