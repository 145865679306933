import React from 'react';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

export class RangePickerComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
        }
    }

    render() {
        return(
              <RangePicker
                  style={{width: "100%"}}
                  format="YYYY-MM-DD"
                  onChange={ this.props.onChange }
                  allowClear={false}
                  onFocus={ this.props.onFocus }
                  disabled={this.props.isDatePickerDisabled}
                  value={this.props.datePickerValue}
              />
        );
    }
}

export default RangePickerComponent;