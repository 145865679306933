import * as React from 'react';
import './deviceMeta.scss';
import DeviceMetadataService from "../../../services/DeviceMetadataService";
import { Button, TreeSelect, Space, Modal, Input, Card } from 'antd';
import LocationTree from '../../functions/useLocationTree';

const treeLine = true;
const showLeafIcon = true;
let tempLocationList = [];
const { SHOW_PARENT } = TreeSelect;

export class DeviceMeta extends React.Component {
  constructor(props){
  
    super(props)
    this.state = {
      nickNameTextField:"",
      editableTextField:false,
      locationDeviceId:"",
      propsLocationValue:"",
      locationEditCalled:false,
      value: "",
      userRole:"",
      locationTree: [],
      activeTabKey: 'general',

    };
  }

  tabList = [
    {
      key: 'general',
      tab: 'General',
    },
    {
      key: 'firmware',
      tab: 'Firmware',
    }
  ];

onTabChange = (key) => {
  this.setState({activeTabKey: key});
};

onCancel = () =>{
 
  if(this.state.editableTextField === true){
    
    this.setState({editableTextField:false});
  }else{
    this.props.parentCallback(false);
  }
}

onSubmit = () =>{
  let hierarchyResult = '';
    this.setState({editableTextField:false});

    if(this.state.locationEditCalled === true){
      if (tempLocationList.length === 0){

        console.log("tempLocationList if [] empty: ", tempLocationList);
      }
      if(tempLocationList[0] === null || tempLocationList[0].length === 0 || tempLocationList[0] === []){     //if none selected in location hierarchy
        var tempLocationDeviceId = "";
      } 
      else{
        
        tempLocationDeviceId = tempLocationList[tempLocationList.length - 1];
        //console.log('tempLocationList length',tempLocationList.length)
        for(let i=0;i<tempLocationList.length;i++){
    
          if(tempLocationList[i].length === 0){    //None
            hierarchyResult = "";
          }
          else{
            console.log("tempLocationList.length > 0");
            hierarchyResult = hierarchyResult.concat('#' + tempLocationList[i]);
          }
        }
      }
      this.setState({locationEditCalled:false});
    }else{
      console.log("locationEditCalled : False");
      tempLocationDeviceId = this.state.locationDeviceId;
      console.log(this.props);
      tempLocationList = this.props.metadata.parentHierarchy;
      hierarchyResult = this.props.metadata.parentHierarchy;
      
    }
    console.log('Final nickNameTextField Data:: ',this.state.nickNameTextField);
    console.log('Previous locationId:: ',this.state.locationDeviceId);
    console.log('After update locationId:: ',tempLocationDeviceId);
    console.log('Previous locationIds:: ',this.props.metadata.parentHierarchy);
    console.log('After update locationIds:: ',tempLocationList);

    console.log('Strin After update locationIds:: ',hierarchyResult);

    if (this.state.nickNameTextField !== "" && this.state.nickNameTextField !== null){
      DeviceMetadataService.updateNickName(this.props.metadata.id,this.state.nickNameTextField,tempLocationDeviceId,this.props.metadata._version,hierarchyResult).then((responseUpdateMeta) => {
        console.log('responseUpdateMeta:: ',responseUpdateMeta);
      });
      console.log("save device meta updates");
    }
    else{

    }
    
    this.props.parentCallback(false);
}

editMetaData = () =>{
  this.setState({editableTextField: true});
  var locationTree = LocationTree.getLocationTree();
  LocationTree.getLocationTree().then((locationTree) => {

    setTimeout(() => {
      locationTree.push({
        title: "None", value: "-1", key: "-1", children: [], data: [], isParent: true
      })
      this.setState({ locationTree: locationTree });
   }, 1500);
   
 });

  console.log("deviceMeta :: locationDataHierarchy: ", locationTree);


  setTimeout(() => {
    const value = LocationTree.getValueByLocationId(this.state.locationTree, this.state.locationDeviceId)
    this.setState({ value: value[0]});
    
  }, 1700)

}

onEdit = () => {
  
  console.log(this.state.editableTextField);
  if (this.state.editableTextField === true){
    
    this.onSubmit();
  } else
  {
    this.editMetaData();
  }
}

componentDidMount(){
  console.log('Component Mounting here:: ');
  if(this.props.metadata.location !== undefined){
    if(this.props.metadata.location === "None"){
      this.setState({propsLocationValue:"None"});
    }else{
      this.setState({propsLocationValue: this.props.metadata.location});
    }
  }
  else if (this.props.metadata.location === null){
    this.setState({propsLocationValue:"None"});
  }
  this.setState({locationDeviceId: this.props.metadata.locationId});
  this.setState({nickNameTextField: this.props.metadata.name});
  this.setState({userRole: this.props.userRoleData});
  
}

onChangeTree = (value) => {
  console.log('onChange ', value);
  this.setState({locationEditCalled:true});
  this.setState({ value });
  tempLocationList=[];
  const parentToChildHierarchy = true;
  tempLocationList = LocationTree.getIdByLocationValue(this.state.locationTree, [value], parentToChildHierarchy);
};

  render () {
    return (
      <>
        <Modal style={{marginTop: "5rem", marginBottom: "4rem"}}
          width={700}
          title={
            this.state.editableTextField
              ? "Edit Device Profile"
              : "View Device Profile"
          }
          centered
          headStyle={{ backgroundColor: 'grey' }}
          bodyStyle={{padding: "0px"}}
          open={this.props.open}
          onOk={this.onEdit}
          onCancel={this.onCancel}
          okButtonProps={{ disabled: true }}
          cancelButtonProps={{
              disabled: true,
          }}
          cancelText={this.state.editableTextField === true ? "Back" : "Cancel"}
          okText={this.state.editableTextField === true ? "Save" : "Edit"}

          footer={[
            <Button key="back" onClick={this.onCancel}>
                {this.state.editableTextField === true ? "Back" : "Cancel"}
            </Button>,
            <>&nbsp;
                <Button type="primary" key="edit&save" onClick={this.onEdit}>
                  {this.state.editableTextField === true ? "Save" : "Edit"}
                </Button>
            </>

        ]}
        >
        <Card style={{width: '100%'}} 
          tabBarExtraContent={<a style={this.props.metadata.status === "ACTIVE" ? {color: "green"} : {color: "tomato"}} href="#">{this.props.metadata.status}</a>}
          tabList={this.tabList} 
          activeTabKey={this.state.activeTabKey}
          onTabChange={(key) => {
            this.onTabChange(key);
          }}
        >
          {this.state.activeTabKey === "general" ? 
            <div id="generalTab" style={{ fontSize: "1rem" }}>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3">
                    {this.state.editableTextField === true ? (
                      <div>
                        <b>Name: </b>
                        <br />
                        <Input
                          style={{ width: "100%" }}
                          id="outlined-basic"
                          variant="outlined"
                          label=" "
                          InputLabelProps={{ shrink: false }}
                          // size="small"
                          defaultValue={this.props.metadata.name}
                          onChange={(e) =>
                            this.setState({ nickNameTextField: e.target.value })
                          }
                        />
                      </div>
                    ) : (
                      <div><b>Name: </b> {this.props.metadata.name}</div>
                    )}
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3">

                  {this.state.editableTextField === true ? (
                    <div>
                      <b>Location: </b>
                      <br />
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <TreeSelect
                          style={{ width: "100%" }}
                          // size="small"
                          value={this.state.value}
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                            zIndex: "100000",
                          }}
                          treeData={this.state.locationTree}
                          showCheckedStrategy={SHOW_PARENT}
                          placeholder="Please select"
                          treeDefaultExpandAll
                          onChange={this.onChangeTree}
                          treeLine={
                            treeLine && {
                              showLeafIcon,
                            }
                          }
                        />
                      </Space>
                      <br />
                    </div>
                  ) : (
                    <div>
                      <b>Location: </b>
                     
                      {this.state.propsLocationValue
                        ? this.state.propsLocationValue
                        : "None"}
                    </div>
                  )}
                </div>
              </div>
              
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3">
                  <div><b>Thing Name: </b> {this.props.metadata.udi}</div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3">
                  <div>
                  <b>Type: </b>
                    {this.props.metadata.type ? this.props.metadata.type : "None"}
                  </div>
                </div>
              
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3">
                  <div> <b>Last Updated: </b> {this.props.metadata.updatedAt}</div>
                </div>
                </div>
              </div>
            </div>
          : 
          <div className="softwareTab"  style={{ fontSize: "1rem" }}>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3">
              <b>Comms Version: </b> 
                <div>{this.props.commsVersion}</div>
              </div>
              
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3"> 
                <b>Controller Version: </b>
                <div>{this.props.controllerVersion}</div>
              </div>
            </div> 
          </div>}
        </Card>
        </Modal>
      </>
    )
  }
}  

export default DeviceMeta;