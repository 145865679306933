import React from 'react';
import 'antd/lib/progress/style/index.css'
// import '../../../pages/dashboard/SingleDeviceStyle.scss';
import { GuageCardComponent } from '../guageCardComponent';

export class ThresholdComponent extends React.Component {
  constructor(props) {

    super(props)
    this.state = {
      mode: [],
      cartridgeLevelValue: 0,
      refreshState: [],
      modeState: '',
      modeIcon: '',
      modeColor: '',
    }
  }

  radialOptions = {
    chart: {
      type: 'radialBar',
      sparkline: {
        enabled: true
      },
      offsetY:10,
      animations: {
        enabled: false,

        dynamicAnimation: {
          enabled: false,
          speed: 0
        }
      }
    },
    plotOptions: {

      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 0,
          size: '100%',
          background: 'transparent',
          position: 'back',
        },
        track: {
          show: true,
          background: '#dde0ef',
          strokeWidth: '100%',
          opacity: 5,
          margin: -10, // margin is in pixels

        },
        dataLabels: {
          name: {
            show: true,
            fontSize: '0.825rem',
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: 600,
            color: '#000000',
            offsetY: 20
          },
          value: {
            offsetY: -20,
            color: '#000000',
            fontFamily: "'Open Sans', sans-serif",
            fontSize: '1.5rem',
            fontWeight: 700,
            formatter: function (val) {
              return [val]
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: 0
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        }
      },
    }
  }

  radialOptionsClo2 = {
    chart: {
      type: 'radialBar',

      sparkline: {
        enabled: true
      },
      animations: {
        enabled: false,

        dynamicAnimation: {
          enabled: false,
          speed: 0
        }
      }

    },
    colors: ["#11998e"],
    plotOptions: {

      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 0,
          size: '100%',
          background: 'transparent',
          position: 'back',
        },
        track: {
          show: true,
          background: '#dde0ef',
          strokeWidth: '100%',
          opacity: 5,
          margin: -10, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: '0.825rem',
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: 600,
            color: '#000000',
            offsetY: 20
          },
          value: {
            color: '#000000',
            fontFamily: "'Open Sans', sans-serif",
            offsetY: -20,
            fontSize: '2rem',
            fontWeight: 700,
            formatter: function (val) {
              return [val]
            }
          }
        }
      }
    },

    fill: {
      colors: [function ({ value, seriesIndex, w }) {
        if (value <= 0) {
          return '#f2f2f2'
        } else if (value > 0 && value > 70 && value < 130) {
          return '#00CCCC'
        } else if (value <= 70 && value > 0) {
          return '#ADEBFF'
        }
        else if (value > 130) {
          return '#FE8479'
        }
      }]
    },
    labels: ['ppb'],
    states: {
      hover: {
        filter: {
          type: 'none',
        }
      },
    }
  }

  radialOptionsHumidity = {

    colors: ["#11998e"],
    fill: {
      colors: [function ({ value, seriesIndex, w }) {
        if ((value >= 25 && value < 30)|| (value >=60 && value < 70)) {
          return '#Ffd700'
        } else if (value >= 30 && value < 60) {
          return '#00CCCC'
        } else if ((value > 0 && value < 25) || (value >= 70)) {
          return '#9f041b'
        }
        else if (value === 0) {
          return '#f0f1f6'
        }
      }]
    },
    labels: ['%'],
    ...this.radialOptions
  }

  radialOptionsBaroP = {
  
    colors: ["#11998e"],
    fill: {
      colors: [function ({ value, seriesIndex, w }) {
        if (value >= 28 && value <= 30) {
          return '#ffd700'
        } else if (value > 30 && value <= 33) {
          return '#00CCCC'
        } else if (value > 33 || value < 28) {
          return '#9f041b'
        }
      }]
    },
    labels: ['mbar'],
    ...this.radialOptions

  }
  radialOptionsTemp = {
    colors: ["#11998e"],
    fill: {
      colors: [function ({ value, seriesIndex, w }) {
        if ((value > 0 && value < 15) || (value >25 && value <=35)) {
          return '#ffd700' //yellow
        } else if (value >= 15 && value <= 25) {
          return '#00CCCC'  // green
        } else if (value > 35) {
          return '#9f041b' //red
        }
        else if (value === 0) {
          return '#f0f1f6'
        }
      }]
    },
    labels: ['°C'],
    ...this.radialOptions
  }

  clo2GuageCardOptions ={
    cardTitle: "ClO2",
    radialOptions: this.radialOptionsClo2,
    guage: {
      height: "auto",
      width: "100%",
      targetValuePadding: "5",
    }
  }

  humidityGuageCardOptions ={
    cardTitle: "Humidity",
    radialOptions: this.radialOptionsHumidity,
    guage: {
      height: "auto",
      width: "80%",
    },
  }

  tempGuageCardOptions ={
    cardTitle: "Temperature",
    radialOptions: this.radialOptionsTemp,
    guage: {
      height: "auto",
      width: "80%",
      
    }
  }

  baroPressureGuageCardOptions ={
    cardTitle: "Baro. Pressure",
    radialOptions: this.radialOptionsBaroP,
    guage: {
      height: "auto",
      width: "80%",
      
    }
  }

  render() {
    return (
      <div>
        <div className='row pb-1'>
          <div className='col ps-0'>
            <GuageCardComponent cardHeader = {{title: "ClO2"}} refreshState = {this.props.refreshState} guageCardOptions = {this.clo2GuageCardOptions} series={this.props.clo2} deviceModeIcon = {{iconClassName: this.props.modeIcon, iconTitle: {text: this.props.mode, color: this.props.modeColor}}} target={this.props.target} 
                       minClO2Value={this.props.minClO2Value} maxClO2Value={this.props.maxClO2Value}/>
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-4 col-lg-6 col-md-4 col-sm-4 ps-0'>
            <GuageCardComponent cardHeader = {{title: "Humidity"}} refreshState = {this.props.refreshState} guageCardOptions = {this.humidityGuageCardOptions} series={this.props.humidity} />
          </div>
          <div className='col-xl-4 col-lg-6 col-md-4 col-sm-4 ps-0'>
            <GuageCardComponent cardHeader = {{title: "Temperature"}} refreshState = {this.props.refreshState} guageCardOptions = {this.tempGuageCardOptions} series={this.props.temp} />
          </div>
          <div className='col-xl-4 col-lg-6 col-md-4 col-sm-4 ps-0'>
            <GuageCardComponent cardHeader = {{title: "Baro. Pressure"}} refreshState = {this.props.refreshState} guageCardOptions = {this.baroPressureGuageCardOptions} series={this.props.baroP} />
          </div>
        </div>
      </div>
    )
  }
}
export default ThresholdComponent;