import * as React from 'react';
import SharedService from "../../../services/SharedService";
import '../../ota-update/otaUpdate.scss';
import { Button, Form, Modal, Select, Input } from 'antd';
import { Link } from 'react-router-dom'
import { Spin } from 'antd';
const { Option } = Select;

export class FleetOtaUpdate extends React.Component {
    formRef = React.createRef();
    constructor(props) {

        super(props)
        this.state = {
            visible: false,
            loading: false,
            jobName: "",
            targetSelection: "SNAPSHOT",
            targetFirmware: "",
            deviceList: [],
            targetFirmwareList: [],
            modalHeader: '',
            selectedDeviceUdi: 'Select Device',
            firmwareType: "",
            requestedBy: '',
            filteredDeviceList: [],
            disabled: true,
            spinLoader: true,
        };
    }

    onCancel = () => {
        this.setState({ visible: false });
        this.setState({ disabled: true });
        this.setState({ targetFirmware: "" });
        this.formRef.current.resetFields();
        this.props.parentCallback(false);
    }


    checkFieldValidation() {
        console.log('this.state.jobName--', this.state.jobName, 'this.state.targetFirmware--', this.state.targetFirmware, 'this.state.requestedBY', this.state.requestedBy)
        if (this.state.jobName.length <= 2 && this.state.targetFirmware <= 2 && this.state.requestedBy.length < 1) {
            this.setState({ disabled: true });
        }
        else {
            if (this.state.jobName.length !== 0 && this.state.targetFirmware.length !== 0 && this.state.requestedBy.length - 1 > 1) {
                console.log('in check else', this.state.requestedBy.length);
                console.log('in check else 2', this.state.requestedBy.length);
                this.setState({ disabled: false })
            }
        }
    }

    //Submit function
    createOTAJob = () => {
        console.log('in create');
        if (this.state.firmwareType === 'comms') {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.setState({ visible: false });
                this.props.parentCallback(false);
            }, 3000);
            console.log('jobName:: ', this.state.jobName, ' --Selected Device Name:: ', this.state.selectedDeviceUdi, ' ---target:: ', this.state.targetSelection
                , '---targetFirmware:: ', this.state.targetFirmware, '---requestedBy--', this.state.requestedBy, '---firmType-', this.state.firmwareType);
            try {
                SharedService.createOTAJobForComms(this.state.jobName, this.state.targetSelection, this.state.selectedDeviceUdi, this.state.targetFirmware, this.state.deviceId, this.state.requestedBy)
                    .then((createJobResponse) => {
                        console.log('createJobResponse:: ', createJobResponse);
                    });
                this.setState({ disabled: true });
                this.setState({ targetFirmware: "" });
                this.formRef.current.resetFields();
            } catch (err) {
                console.log('Error occured Create OTA Job:: ', err);
            }
        }
        else {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.setState({ visible: false });
                this.props.parentCallback(false);
            }, 3000);
            console.log('jobName:: ', this.state.jobName, ' --Selected Device Name:: ', this.state.selectedDeviceUdi, ' ---target:: ', this.state.targetSelection
                , '---targetFirmware:: ', this.state.targetFirmware, '---requestedBy--', this.state.requestedBy, '---firmType-', this.state.firmwareType);
            try {
                SharedService.createOTAJobControls(this.state.jobName, this.state.targetSelection, this.state.selectedDeviceUdi, this.state.targetFirmware, this.state.deviceId, this.state.requestedBy)
                    .then((createJobResponse) => {
                        console.log('createJobResponse:: ', createJobResponse);
                    });
                this.setState({ disabled: true });
                this.setState({ targetFirmware: "" });
                this.formRef.current.resetFields();

            } catch (err) {
                console.log('Error occured Create OTA Job:: ', err);
            }
        }
    }

    handleDeviceSelect(e) {
        var nickname = '';
        var deviceId = '';
        this.setState({ selectedDeviceUdi: e })
        for (let i = 0; i < this.state.deviceList.length; i++) {
            if (e === this.state.deviceList[i].deviceName) {
                // nickname=this.state.deviceList[i].nickname;
                nickname = this.state.deviceList[i].deviceName;
                deviceId = this.state.deviceList[i].deviceId;
            }
        }
        this.setState({ jobName: nickname + '_' + Date.now() });
        this.setState({ deviceId: deviceId });
        this.checkFieldValidation();
    }

    handleJobNameChange(e) {
        this.setState({ jobName: e.target.value });
        this.checkFieldValidation();
    }

    handleRequestedByNameChange(e) {
        this.setState({ requestedBy: e.target.value });
        this.checkFieldValidation();
    }

    tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };
    layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };


    componentDidMount() {
        this.setState({ targetSelection: "SNAPSHOT" });
    }

    componentDidUpdate(prevProps){
        if (prevProps.visible !== this.props.visible){
            this.setState({ visible: this.props.visible });
            this.setState({ firmwareType: this.props.firmwareType });
            this.setState({ modalHeader: 'OTA ' + this.props.firmwareType + ' update' });
        }
        if (prevProps.deviceListData !== this.props.deviceListData){
            if (this.props.deviceListData !== undefined) {         //fetch deviceList from single-device Component
                if (this.props.deviceListData.length === 0) {
                    this.setState({ deviceList: [] });
                } else {
                    this.setState({ deviceList: this.props.deviceListData });
                }
            }
        }
        if (prevProps.firmwareType !== this.props.firmwareType || prevProps.targetFirmwareData !== this.props.targetFirmwareData){
            if (this.props.targetFirmwareData !== undefined) {         //fetch deviceList from single-device Component
                if (this.props.targetFirmwareData.length === 0) {
                    this.setState({ targetFirmwareList: [] });
                    this.setState({ spinLoader: true });
                } else {
                    this.setState({ targetFirmwareList: this.props.targetFirmwareData });
                    this.setState({ spinLoader: false });
                }
            }
        }
    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                <Modal style={{marginTop: "5rem", marginBottom: "5rem"}}
                    centered={true}
                    open={this.state.visible}
                    title={this.state.modalHeader}
                    headStyle={{ backgroundColor: 'grey' }}
                    onOk={this.createOTAJob}
                    okButtonProps={{ disabled: true }}
                    cancelButtonProps={{
                        disabled: true,
                    }}
                    width={950}
                    onCancel={this.onCancel}

                    footer={[
                        <Button key="back" onClick={this.onCancel}>
                            Cancel
                        </Button>,
                        <>&nbsp;
                            {this.state.firmwareType === 'comms' ? <Link to='/otaComms'><Button key="submit" type="primary" loading={this.state.loading} onClick={this.createOTAJob}>
                                Submit</Button></Link>
                                : <Link to='/otaControls'><Button key="submit" type="primary" loading={this.state.loading} onClick={this.createOTAJob}>
                                    Submit
                                </Button></Link>}
                        </>

                    ]}
                >
                    <Form {...this.layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                        <div className="row text-left">
                            <span className="row pe-0 ps-3">
                                <Form.Item style={{textAlign: "left", alignItems: "left", alignContent: "left"}} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 float-left text-left"
                                    name="Device"
                                    label="Device Name"
                                    rules={[{ required: true, },]}>
                                    <Select placeholder="Select Device" style={{ width: '100%' }} value={this.state.selectedDeviceUdi}
                                        onChange={(e) => this.handleDeviceSelect(e)} >
                                        {this.state.deviceList.map((make, index) => (
                                            <Option key={make.deviceId} value={make.deviceName}>{make.nickname}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    className="col-xl-6 col-lg-6 col-md-6 col-sm-12" 
                                    name="Job Name" label="Job Name" rules={[{ required: true, },]} >
                                    <span>
                                        <Input placeholder="Enter Job Name" value={this.state.jobName}
                                            style={{ width: '100%' }} size="large" onChange={(e) => this.handleJobNameChange(e)} />
                                    </span>
                                </Form.Item>
                            </span>
                            <span className="row pe-0 ps-3">

                                <Form.Item className="col-xl-6 col-lg-6 col-md-6 col-sm-12"
                                    name="targetSelection"
                                    label="Job Runtype"
                                    rules={[{ required: true, },]}>
                                    <Select defaultValue={this.state.targetSelection} style={{ width: '100%' }}
                                        onChange={(e) => this.setState({ targetSelection: e })}   >
                                        <Option value="SNAPSHOT">SNAPSHOT</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left" name="targetFirmware" label="Select Firmware " rules={[{ required: true, },]}>
                                    <Select placeholder="Select target firmware" style={{ width: '100%' }}
                                        value={this.state.targetFirmware}
                                        onChange={(e) => this.setState({ targetFirmware: e })}  >
                                        {this.state.targetFirmwareList.map((make, index) => (
                                            <Option key={make.key} value={make.key}>{make.key}</Option>
                                        ))}
                                    </Select>
                                    <br />
                                    {this.state.spinLoader === true ? <> &nbsp;&nbsp;<Spin /></> : <></>}
                                </Form.Item>
                                
                            </span>
                            <span className="row pe-0 ps-3">
                                <Form.Item className="col-xl-6 col-lg-6 col-md-6 col-sm-12 float-left"
                                    name="Requested By"
                                    label="Requested By"
                                    rules={[{ required: true, },]}>
                                    <Input placeholder="Enter Requester Name" value={this.state.requestedBy}
                                        style={{ width: '100%', }} size="large" onChange={(e) => this.handleRequestedByNameChange(e)}
                                    />
                                </Form.Item>    
                            </span>
                        </div>
                    </Form>

                </Modal>
            </div>
        )
    }
}

export default FleetOtaUpdate;