 
import React from "react";
import OTAHistoryService from '../../../services/OTAHistoryService';
import DeviceService from     '../../../services/DeviceService';
import SharedService from     '../../../services/SharedService';
import 'antd/dist/antd.css';
import { Space, Steps, Button } from 'antd';
import FleetOtaUpdate from '../../../utils/components/modalComponent/fleetOtaUpdate';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BreadcrumbComponent } from '../../../utils/components/breadcrumbComponent';
import { Dropdown, Menu } from 'antd';
import { Affix } from 'antd';
import { DatePicker, Select } from 'antd';
import { DataTable } from '../../../utils/tables/DataTable';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Step } = Steps;
var moment = require('moment'); // require
export class OtaHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bottom: 'bottomRight',
      rowData: [],
      jobStatusElement: '',
      targetFirmwareList: [],
      visible: false,
      deviceData: [],
      firmwareType: '',
      username: '',
      timeFilter:'true',
      typeOfDateFilter:'time',
      fromDateTime:moment().subtract(1440, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z",
      toDateTime:moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z",
      selectedTimeRange: [],
      refreshState:[],

    }
  }
  handleMenuClick = (e) => {
    if (e.item.props.value === 'comms') {
      this.setState({ visible: true });
      this.setState({ firmwareType: 'comms' });
      this.getTargetFirmwareComms();
    }
    else {
     
      this.setState({ visible: true });
      this.setState({ firmwareType: 'controls' });
      this.getTargetFirmwareControls();
    }

  };
  handleOtaUpdateModal = (childData) => {
    console.log('childData:: ', childData);
    this.setState({ visible: childData });

  }

  menu = (
    <Menu
      onClick={this.handleMenuClick}
      items={[
        {
          label: 'OTA Comms Update',
          key: '1',
          icon: <PlusCircleOutlined />,
          value: 'comms',
        },
        {
          label: 'OTA Controls Update',
          key: '2',
          icon: <PlusCircleOutlined />,
          value: 'controls',
        },

      ]}
    />
  );

  getStatus(jobStatus) {
    console.log("getStatus running");
    var uiElement = '';
    var current = 0;
    var status = null;

    if (jobStatus === 'CREATE_PENDING') {
      current = 0;
      uiElement = <Steps current={0} size="small">
        <Step title="Create Pending" />
        <Step title="Job Created" />
        <Step title="In Process" />
        <Step title="Success" />
      </Steps>
    }
    else if (jobStatus === 'IN-PROGRESS') {
      current = 2;
      status = "process"
      uiElement = <Steps current={2} status="process" size="small">
        <Step title="Create Pending" />
        <Step title="Job Created" />
        <Step title="In Process" />
        <Step title="Success" />
      </Steps>
    }
    else {
      if (jobStatus === 'SUCCESS') {
        current = 3;
        status = "finish";
        uiElement = <Steps current={3} status="finish" size="small">
          <Step title="Create Pending" />
          <Step title="Job Created" />
          <Step title="In Process" />
          <Step title="Success" />
        </Steps>
      }
      else if (jobStatus === 'FAILED') {
        current = 3;
        status = "error"
        uiElement = <Steps current={3} status="error" size="small">
          <Step title="Create Pending" />
          <Step title="Job Created" />
          <Step title="In Process" />
          <Step title="Failed" />
        </Steps>
      }
    }

    uiElement = <Steps current={current} status={status} size="small">
        <Step title="Create Pending" />
        <Step title="Job Created" />
        <Step title="In Process" />
        <Step title="Success" />
      </Steps>

    return uiElement;
  }

  getOTAHistoryByAccId = (timeRangeSelected) => {
    console.log('timeSelected==',timeRangeSelected)
    let currentTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";
    let fromTime = moment().subtract(timeRangeSelected, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";
    console.log('fromTime==',fromTime,'currentTime',currentTime)
    OTAHistoryService.getOTAHistoryByAccIdTime(fromTime,currentTime,2200).then((res) => {
      //console.log('TelemetryApiData.getDevicesByAccId:: ',res);

      console.log('ota result', res);
      this.setState({ rowData: res });
      this.setState({refreshState:false});
    });
  }


  getOTAHistoryByAccIdByDateRange = (fromDate, toDate) => {

    let currentTime = moment.utc(moment(toDate)).format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";
    let fromTime = moment.utc(moment(fromDate)).format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";

    OTAHistoryService.getOTAHistoryByAccIdTime(fromTime,currentTime,2200).then((res) => {
      console.log('ota result', res);
      this.setState({ rowData: res });
      this.setState({refreshState:false});
    });
  }

  getDeviceName = (deviceDataResult) => {
    let deviceNameMap = [];
    for (const dataObj of deviceDataResult) {
      if (deviceNameMap.find(x => x.udi === dataObj.udi)) {
      }
      else {
        deviceNameMap.push({ "deviceName": dataObj.udi, "deviceId": dataObj.id, "nickname": dataObj.metadata.name });
      }
    }
    this.setState({ deviceData: deviceNameMap });
  }

  getDeviceData = () => {
    this.setState({ deviceData: [] });
    DeviceService.getDevicesByAccId().then((resultByFilter) => {

      this.getDeviceName(resultByFilter);

    });
  }
  getTargetFirmwareComms = () => {
    console.log('comms');
    SharedService.fetchS3ObjectsOTABucket('comms').then((targetFirmwareListData) => {
      console.log('targetFirmwareList:: ', targetFirmwareListData);
      this.setState({ targetFirmwareList: targetFirmwareListData })
    });
  }

  getTargetFirmwareControls = () => {
    SharedService.fetchS3ObjectsOTABucket('controls').then((targetFirmwareListData) => {
      console.log('targetFirmwareList:: ', targetFirmwareListData);
      this.setState({ targetFirmwareList: targetFirmwareListData })
    });
  }

 

  async getUsername() {
    try {
      await SharedService.getCurrentUser().then((userDataList) => {
        SharedService.getUserByEmailId(userDataList.attributes.email).then((userData) => {
          var userDataList = userData;
          if (userDataList == null || userDataList == [] || userDataList.length == 0) {
            this.setState({ username: [] });
          } else {
            this.setState({ username: userDataList[0].username })
          }
        });
      });

    } catch (err) {
      console.log('error on DeviceMeta::getUserRole:: ', err);
    }
  }

  onChange = (value, dateString) => {
    this.setState({selectedDateRange:true});
    this.setState({refreshState:true})
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString[0],"--to Time- ",dateString[1]);
    this.setState({fromDateTime:dateString[0]});
    this.setState({toDateTime:dateString[1]});
    this.getOTAHistoryByAccIdByDateRange(dateString[0],dateString[1]);

  };

  onChangeFilterDateTime = (e) => {
    console.log('onChangeFilterDateTime::',e);
    if(e == 'time'){
      this.setState({timeFilter:'true'});
      this.setState({refreshState:true});
      this.setState({selectedTimeRange:1440});
      this.getOTAHistoryByAccId(1440);
    }else{
      this.setState({timeFilter:'false'});
      this.setState({refreshState:true}); 
      let tempFromTime = moment().subtract(1440, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";
      let tempToTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z";
      this.setState({fromDateTime:moment().subtract(1440, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z"});
      this.setState({toDateTime:moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z"});
      this.getOTAHistoryByAccIdByDateRange(tempFromTime,tempToTime);
    }

  }

  handleTimeFilterChange = (event) => {
    console.log('handleTimeFilterChange - time filter::',event)
      this.setState({selectedTimeRange:event});
      this.setState({refreshState:true})
      this.getOTAHistoryByAccId(event);
  };

  componentDidMount() {
    console.log('in OTA');
    this.getUsername();
    this.setState({ refreshState: true });
    setTimeout(() => {
      this.getOTAHistoryByAccId(1440);
      this.getDeviceData();

    }, 100)

   // this.clearIntervalTelemetryData = setInterval(e => this.refreshInterval(), 60000);

  }
  componentWillUnmount() {
    //clearInterval(this.clearIntervalTelemetryData);
  }

  render() {
    document.title = 'OTA History';
    return (
      <div className="row">
        <div className="container-fluid">
        <Affix offsetTop={72}>
          <div className="container-fluid">
            <BreadcrumbComponent parent="Reports" child="OTA History" />
            
            <div className="card px-2 ml-0 mb-2">
              <div className="card-body pt-2">
                <div className="row">
                <div className="col">
                  <ul className="row mb-0 justify-content-end">
                    <li className="col-xl-2 col-lg-3 col-md-3 col-sm-4 list-group-item py-1 px-2 border-0">
                      <Select
                        placeholder="Date & Time"
                        id="datefilter-select"
                        label="datefilter select"
                        style={{ width: "100%" }}
                        defaultValue={this.state.typeOfDateFilter}
                        onChange={this.onChangeFilterDateTime}
                      >
                        <Option key="time" value="time">
                          Time
                        </Option>
                        <Option key="datetime" value="datetime">
                          Date & Time
                        </Option>
                      </Select>
                    </li>
                    {this.state.timeFilter == "true" ? (
                      <li className="col-xl-2 col-lg-3 col-md-3 col-sm-4 list-group-item py-1 px-2 border-0">
                        <Select
                          placeholder="Time"
                          id="time-range-select"
                          label="Time"
                          style={{ width: "100%" }}
                          defaultValue={1440}
                          onChange={this.handleTimeFilterChange}
                        >
                          <Option value={2}>Now</Option>
                          <Option value={5}>Last 5 minutes</Option>
                          <Option value={15}>Last 15 minutes</Option>
                          <Option value={30}>Last 30 minutes</Option>
                          <Option value={60}>Last 1 hour</Option>
                          <Option value={180}>Last 3 hours</Option>
                          <Option value={360}>Last 6 hours</Option>
                          <Option value={720}>Last 12 hours</Option>
                          <Option value={1440}>Last 24 hours</Option>
                          <Option value={2880}>Last 2 days</Option>
                          <Option value={4320}>Last 3 days</Option>
                          <Option value={7200}>Last 5 days</Option>
                          <Option value={10080}>Last 7 days</Option>
                        </Select>
                      </li>
                    ) : (
                    <li className="col-xl-2 col-lg-3 col-md-3 col-sm-4 list-group-item py-1 px-2 border-0">
                      <Space direction="vertical" size={12}>
                        <RangePicker
                          showTime={{
                            format: "HH:mm",
                          }}
                          format="YYYY-MM-DD HH:mm"
                          onChange={this.onChange}
                          allowClear={false}
                          style={{ width: "100%" }}
                        />
                      </Space>
                    </li>
                    )}
                
                    {this.state.username === "christobel" ||
                    this.state.username === "web_test1" ||
                    this.state.username === "webtest" ||
                    this.state.username === "cmills" ? (
                      <li className="col-xl-2 col-lg-2 col-md-2 col-sm-4 list-group-item py-1 px-2 border-0">
                        <div>
                          <Dropdown  overlay={this.menu}>
                            <Button
                              style={{ backgroundColor: "dodgerblue", color: "white", width: "100%"}}
                            >
                              <Space>+ Add OTA</Space>
                            </Button>
                          </Dropdown>
                        </div>
                      </li>
                    ) : (
                      <div></div>
                    )}
                
                  </ul>
                </div>
                </div>
              </div>
            </div>
            
          </div>
        </Affix>
        </div>

        <div className="row mb-2 mt-2 ps-4 pe-0 pt-1">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-2"></div>

                <DataTable refreshState={this.state.refreshState} tableData = { this.state.rowData } title="otaHistory" />
              </div>
            </div>
          </div>
        </div>
        <FleetOtaUpdate
          visible={this.state.visible}
          deviceListData={this.state.deviceData}
          targetFirmwareData={this.state.targetFirmwareList}
          firmwareType={this.state.firmwareType}
          parentCallback={this.handleOtaUpdateModal}
        />
      </div>

    )
  }
}

export default OtaHistory;

