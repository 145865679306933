import React from "react";
import DeviceTelemetryService from '../../services/DeviceTelemetryService';
import SharedService from "../../services/SharedService";
import DeviceService from "../../services/DeviceService";
import "../../assets/styles/components/_data-table.scss";
import { Table,Progress } from 'antd';
import { Space, Affix } from 'antd';
import dayjs from 'dayjs';
import { DateTime } from 'luxon'
import { DatePicker, TimePicker, Select } from 'antd';
import { Button, Input } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import DownloadResponse from "../../utils/components/modalComponent/downloadResponse";
import FormValidation from "../../utils/components/modalComponent/formValidation";
import Highlighter from "react-highlight-words";
import { BreadcrumbComponent } from '../../utils/components/breadcrumbComponent';
import { RangePickerComponent } from '../../utils/components/filterComponent/rangePickerComponent';
import { TimePickerComponent } from '../../utils/components/filterComponent/timePickerComponent';
const { Option } = Select;

const { RangePicker } = DatePicker;
var moment = require('moment'); // require
var dataChunk = [];

var defaultValueTimeFilter = "Last 1 hour"
export class DeviceDiagsReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filtersDisabledState: true,
      dateTimeFilterDisabledState: true,
      timeFilterDisabledState: true,
      dateFilterDisabledState: true,
      reportTableState: false,
      exportDisabledState: true,
      dateRangeFilterChangeInvoked: false,
      maxTimeout: 60,
      typeOfDateFilter: 'time',
      timeFilter: 'true',
      dateTimeFilter: 'false',
      dateTimeSplitFilter: 'false',
      fromDateTime: moment().subtract(60, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z",
      toDateTime: moment().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z",
      selectedDateRange:[],
      fromDate:'',
      toDate:'',
      fromTime:'',
      toTime:'',
      timeIntervalRange: "60",
      searchText: '',
      searchedColumn: '',
      exportDialogVisible: false,
      formDialogVisible: false,
      dialogMessage: '',
      deviceMapState: [],
      latestDeviceNameState: [],
      deviceDiagsMapData: [],
      nickname: '',
      deviceId:''
    }
    this.searchInput = React.createRef();
  }

  static _deviceId = '';
  static _typeOfDateFilter = 'time';


  //Get list of devices for particular account / tenant
  getDeviceData = () => {
    DeviceService.getDevicesByAccId().then((deviceList) => {      
      console.log('List of devices in particular account :: ',deviceList);
      this.setState({deviceMapState:deviceList});
    });
  }

  //generateDiagsReport function
  generateDiagsReport = (deviceId,fromDateTime,toDateTime) => {
    console.log('Export csv report fromDate:: ', this.state.fromDateTime, '---toDate:: ', this.state.toDateTime);
    try {
      var offsetLocalTimeZone = this.getOffset();
      var localTimeZone = this.getTimeZone();
        SharedService.generateDiagsReportByDeviceIdLambda(deviceId,fromDateTime, toDateTime, this.state.typeOfDateFilter, offsetLocalTimeZone, localTimeZone)
        .then((createJobResponse) => {
          console.log('createJobResponse:: ', createJobResponse);
        });
    } catch (err) {
      console.log('Error occured Create generateDiagsReport :: ', err);
    }
  }

  // Compute aggregation report for particular device
  getDeviceDiagsData = (deviceId,fromDateTime,toDateTime,typeOfDateFilter) => {
    console.log("in :: getDeviceDiagsData");
    console.log('DeviceId:: ',deviceId,'---fromDate:: ', fromDateTime, '---toDate:: ', toDateTime, '--report type:: ', typeOfDateFilter);
    try {
      var offsetLocalTimeZone = this.getOffset();
      SharedService.viewDiagsReportByDeviceIdLambda(deviceId,fromDateTime, toDateTime, typeOfDateFilter, offsetLocalTimeZone)
        .then((createJobResponse) => {
          console.log('createJobResponse:: ', createJobResponse);

         if(createJobResponse!=null){
          if(createJobResponse.length >=0){
            this.setState({ deviceDiagsMapData: createJobResponse });
            this.setState({ exportDisabledState: false });
            setTimeout(() => {
              this.adjustTableColumnHeight();
            }, this.state.maxTimeout)
           this.setState({ reportTableState: false });
           this.setState({ filtersDisabledState: false });
          }
          else{
            this.setState({ exportDisabledState: true });
          }
         }else{
            this.setState({ exportDisabledState: true });
         }
        });
    } catch (err) {
      console.log('Error occured Create getDeviceDiagsData :: ', err);
    }

  }


  //Handle device list filter
  handleDeviceFilterChange = (event) => {
    console.log('handleDeviceFilterChange - device Id is ', event);
    //Schedule the page render by setting states of required variables
    this.setState({ deviceId: event });
    // this.setState({ filtersDisabledState: true });
    this.setState({ reportTableState: true });
    if(this.state.typeOfDateFilter === 'time'){
      this.createDeviceDiagsMapDataByTimeInterval(event, this.state.timeIntervalRange,this.state.typeOfDateFilter);
    }else{
      this.createDeviceDiagsMapDataByDateRange(event,this.state.fromDateTime, this.state.toDateTime,this.state.typeOfDateFilter);
    }
  }

  //Handle just the interval-based time filter
  handleTimeIntervalFilterChange = (event) => {
    console.log('handleTimeIntervalFilterChange - time filter::', event);
    this.setState({ timeIntervalRange: event });
    // this.setState({ filtersDisabledState: true })
    this.setState({ reportTableState: true });
    this.createDeviceDiagsMapDataByTimeInterval(this.state.deviceId, event,this.state.typeOfDateFilter);
  };
  
  //Handle date-time filter
  handleDateTimeRangeFilterChange = (value, dateString) => {
    this.setState({ selectedDateTimeRange: true });
        // this.setState({ filtersDisabledState: true })
    this.setState({ reportTableState: true });
    // console.log('Selected Date Time: ', value);
    // console.log('Formatted Selected Date Time: ', dateString[0], "--to Date Time- ", dateString[1]);
    let currentDateTime = moment(dateString[1]).format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
    let fromDateTime = moment(dateString[0]).format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
    // console.log('current date time:: ', fromDateTime, currentDateTime);
    this.setState({ fromDateTime: fromDateTime });
    this.setState({ toDateTime: currentDateTime });
    setTimeout(() => {
      this.createDeviceDiagsMapDataByDateRange(this.state.deviceId,fromDateTime, currentDateTime,this.state.typeOfDateFilter);
    }, this.state.maxTimeout)
    setTimeout(() => {
      this.adjustTableColumnHeight();
    }, this.state.maxTimeout)
  };


  // Named callback function for the Date RangePicker
  handleDateRangeFilterChange = (dates, dateString) => {
      console.log('Selected date range filter: ', dates);
      console.log('From Date: ', dateString[0], " To Date: ", dateString[1]);
      this.setState({ selectedDateRange: dates });
      this.setState({ fromDate: dateString[0] });
      this.setState({ toDate: dateString[1] });
      this.setState({ timeFilterDisabledState: false });
      this.setState({ dateRangeFilterChangeInvoked: true });
  };
  
  handleTimeRangeFilterChange = (time, timeString) => {
    console.log('Selected time range filter: ', time);
    console.log('From Time: ', timeString[0], " End Time: ", timeString[1]);
    this.setState({ reportTableState: true });
    this.setState({ dateRangeFilterChangeInvoked: false });
    // Check if end time is greater than or equal to start time
    if (timeString[0] && time && moment(timeString[1]).isBefore(moment(timeString[0]))) {
      this.formValidationModalOpen("Since this is Date-wise report, the End Time has to be after the Start Time.");
    }else{
      let fromDateTime = moment(this.state.fromDate+'T'+timeString[0]+':00').format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
      let toDateTime = moment(this.state.toDate+'T'+timeString[1]+':00').format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
      console.log('Formatted Selected Date Time: ', fromDateTime, "--to Date Time- ", toDateTime);
      this.setState({ fromDateTime: fromDateTime });
      this.setState({ toDateTime: toDateTime });
      setTimeout(() => {
        this.createDeviceDiagsMapDataByDateRange(this.state.deviceId,fromDateTime, toDateTime,this.state.typeOfDateFilter);
      }, this.state.maxTimeout)
      setTimeout(() => {
        this.adjustTableColumnHeight();
      }, this.state.maxTimeout)
  }
  };
  
      //Handle split date & time range filters
      handleDateRangeFilterFocus = (status) => {
      console.log('Selected date range filter is in focus: ', status);
      this.setState({ timeFilterDisabledState: false });
      };

  //Handle the report type filter - whether it is interval-based, calendar based, or split date & time filters
  handleReportFilterTypeChange = (event) => {
    console.log('handleReportFilterTypeChange::', event);
    let tempFromTime, tempToTime;
    if(this.state.deviceId === ''){
       this.formValidationModalOpen('You need to select a device for generating the summary report');
    }else{
      switch (event){ 
      case 'time':
        console.log('Event evaluated::', event);
        this.setState({ typeOfDateFilter: 'time'});
        this.setState({ timeFilter: 'true' });
        this.setState({ dateTimeFilter: 'false' });
        this.setState({ dateTimeSplitFilter: 'false' });
        this.setState({ timeFilterDisabledState: true });
        this.createDeviceDiagsMapDataByTimeInterval(this.state.deviceId, 60,event); 
        return 0;
      case 'datetime': 
        console.log('Event evaluated::', event);
        this.setState({ typeOfDateFilter: 'datetime'});
        this.setState({ timeFilter: 'false' });
        this.setState({ dateTimeFilter: 'true' });
        this.setState({ dateTimeSplitFilter: 'false' });
        this.setState({ deviceDiagsMapData: [] });
        this.setState({ exportDisabledState: true });
        this.setState({ dateTimeFilterDisabledState: false });
        this.setState({ timeFilterDisabledState: true });
        setTimeout(() => {
          this.adjustTableColumnHeight();
        }, this.state.maxTimeout)
        return 0;
      case 'datetimeSplit':
        console.log('Event evaluated::', event);
        this.setState({ typeOfDateFilter: 'datetimeSplit'});
        this.setState({ timeFilter: 'false' });
        this.setState({ dateTimeFilter: 'false' });
        this.setState({ dateTimeSplitFilter: 'true' });
        this.setState({ deviceDiagsMapData: [] });
        this.setState({ exportDisabledState: true });
        this.setState({ dateFilterDisabledState: false });
        this.setState({ timeFilterDisabledState: true });
        this.setState({ selectedDateRange: [] });
        setTimeout(() => {
          this.adjustTableColumnHeight();
        }, this.state.maxTimeout)
        return 0;
        default:
          console.log('No report type filter selected .. so defaulting it to time-interval filter');
          this.setState({ typeOfDateFilter: 'time'});
          this.setState({ timeFilter: 'true' });
          this.setState({ dateTimeFilter: 'false' });
          this.setState({ dateTimeSplitFilter: 'false' });
          return 0;
      }
    }
  }

  //on mount get the default time Interval filter selection and get the records
  createDeviceDiagsMapDataByTimeInterval = (dId, timeIntervalRangeSelected,typeOfDateFilter) => {
    console.log("Device is ",dId, "AND Time interval selected is ", timeIntervalRangeSelected);
    let currentTime = moment().format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
    let fromTime = moment().subtract(timeIntervalRangeSelected, 'm').format("yyyy-MM-DDTHH:mm:ss") + ".000Z";
    console.log('fromTime:: ', fromTime);
    console.log('current time:: ', currentTime);
    this.setState({ fromDateTime: fromTime });
    this.setState({ toDateTime: currentTime });
    this.getDeviceDiagsData(dId,fromTime,currentTime,typeOfDateFilter);
  }

  createDeviceDiagsMapDataByDateRange(deviceId,fromDateTime, toDateTime,typeOfDateFilter) {
    this.getDeviceDiagsData(deviceId,fromDateTime,toDateTime,typeOfDateFilter);
  }

  adjustTableColumnHeight = () => {
    if (window.screen.width <= 720){
      let thElement = document.querySelectorAll('.ant-table-thead tr th');
      let tdElement = document.querySelectorAll('.ant-table-tbody tr td');
      const tableHeadersSize = thElement.length;
      for (let i = 0; i < tableHeadersSize; i++){
        for (let j = i ; j < tdElement.length; j = j + tableHeadersSize){
          const thHeight = thElement[i].clientHeight;
          const tdHeight = tdElement[j].clientHeight;
          if (thHeight > tdHeight){
            // set td height as th height
            tdElement[j].setAttribute("style", "height:" + (thHeight) + "px");
          }
          else{
            // set th height as td height
            thElement[i].setAttribute("style", "height:" + (tdHeight + 1) + "px");
          }
        } 
      }
    }
  }

  componentDidMount() {
    if(window.screen.width > 720){
      for (const column of this.deviceEventColumns){
        if (column.title === "Date"){
          column.width = 100;
        }
        if (column.title === "Device Name"){
          column.width = 200;
        }
        if (column.title === "Avg ClO2 (ppb)"){
          column.width = 100;
        }
        if (column.title === "Min ClO2 (ppb)"){
          column.width = 100;
        }
        if (column.title === "Max ClO2 (ppb)"){
          column.width = 100;
        }
        if (column.title === "Avg RH (%)"){
          column.width = 100;
        }
        if (column.title === "Avg Temp (°C)"){
          column.width = 100;
        }
        if (column.title === "Avg Temp (°F)"){
          column.width = 100;
        }
        if (column.title === "Avg Bar.P (mbar)"){
          column.width = 100;
        }
        if (column.title === "Avg Shot (μL)"){
          column.width = 100;
        } 
        if (column.title === "Min Shot (μL)"){
          column.width = 100;
        } 
        if (column.title === "Max Shot (μL)"){
          column.width = 100;
        } 
        if (column.title === "Sum Shot (μL)"){
          column.width = 100;
        } 
        if (column.title === "Total Shots"){
          column.width = 100;
        } 
        if (column.title === "Avg sgp4x2_VOC"){
          column.width = 100;
        }
        if (column.title === "Min sgp4x2_VOC"){
          column.width = 100;
        }
        if (column.title === "Max sgp4x2_VOC"){
          column.width = 100;
        }
        if (column.title === "Avg sgp4x2_Nox"){
          column.width = 100;
        }
        if (column.title === "Min sgp4x2_Nox"){
          column.width = 100;
        }
        if (column.title === "Max sgp4x2_Nox"){
          column.width = 100;
        }
        if (column.title === "Avg sgp4x4_VOC"){
          column.width = 100;
        }
        if (column.title === "Min sgp4x4_VOC"){
          column.width = 100;
        }
        if (column.title === "Max sgp4x4_VOC"){
          column.width = 100;
        }
        if (column.title === "Avg sgp4x4_Nox"){
          column.width = 100;
        }
        if (column.title === "Min sgp4x4_Nox"){
          column.width = 100;
        }
        if (column.title === "Max sgp4x4_Nox"){
          column.width = 100;
        }
        if (column.title === "Avg sgp4x5_VOC"){
          column.width = 100;
        }
        if (column.title === "Min sgp4x5_VOC"){
          column.width = 100;
        }
        if (column.title === "Max sgp4x5_VOC"){
          column.width = 100;
        }
        if (column.title === "Avg sgp4x5_Nox"){
          column.width = 100;
        }
        if (column.title === "Min sgp4x5_Nox"){
          column.width = 100;
        }
        if (column.title === "Max sgp4x5_Nox"){
          column.width = 100;
        }
        if (column.title === "Avg sgp4x7_VOC"){
          column.width = 100;
        }
        if (column.title === "Min sgp4x7_VOC"){
          column.width = 100;
        }
        if (column.title === "Max sgp4x7_VOC"){
          column.width = 100;
        }
        if (column.title === "Avg sgp4x7_Nox"){
          column.width = 100;
        }
        if (column.title === "Min sgp4x7_Nox"){
          column.width = 100;
        }
        if (column.title === "Max sgp4x7_Nox"){
          column.width = 100;
        }
      }
    }
    
    setTimeout(() => {
      this.getDeviceData();
    }, 100)

    if(this.state.deviceId === ''){
      //this.formValidationModalOpen();
      this.setState({ filtersDisabledState: true });
    }else{
      this.setState({ filtersDisabledState : false });
      setTimeout(() => {
        this.createDeviceDiagsMapDataByTimeInterval(this.state.deviceId,60,'time');
      }, 100)

      setTimeout(() => {
        this.adjustTableColumnHeight();
      }, 2000) 
    }

    //console.log("Event map data: ", this.state.eventReportMapData);
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();   
    this.setState({ searchText: selectedKeys[0] });
    this.setState({ searchedColumn: dataIndex });
  };

  handleReset = (selectedKeys, confirm, dataIndex) => {
    confirm();  
    this.setState({ searchText: '' });
    this.setState({ searchedColumn: dataIndex });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8, }} >
        <Input ref={this.searchInput} placeholder={`Search ${dataIndex}`}  value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{  marginBottom: 8,  display: 'block', }} />
        <Space>
          <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />}   size="small"   style={{ width: 90}}> Search</Button>
          <Button   onClick={() => this.handleReset('', confirm, dataIndex)}  size="small" style={{width: 90 }}>Reset</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{  color: filtered ? '#1890ff' : undefined,}}/>
    ),
    onFilter: (value, record) =>
      record.device.metadata[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter highlightStyle={{backgroundColor: "#FFFF00",padding: 0 }} searchWords={[this.state.searchText]}  autoEscape
        textToHighlight={text ? text.toString() : ""} />) : (text)
    });

  deviceEventColumns = [
    { title: 'Date', dataIndex: "eventDate", 
      align: 'left', sorter: (a, b) => new Date(a.eventDate) - new Date(b.eventDate), sortDirections: ['descend', 'ascend'] },
    { title: 'Device Name', dataIndex: "name", 
    align: 'left' },
    { title: 'Avg ClO2 (ppb)', dataIndex: 'clo2', 
    align: 'left'},
    { title: 'Min ClO2 (ppb)', dataIndex: 'minclo2', 
    align: 'left'},
    { title: 'Max ClO2 (ppb)', dataIndex: 'maxclo2', 
    align: 'left'},
    { title: 'Avg RH (%)', dataIndex: "humidity", 
    align: 'left'},
    { title: 'Avg Temp (°C)', dataIndex: "temperature", 
    align: 'left'},
    { title: 'Avg Temp (°F)', dataIndex: "tempF", 
    align: 'left'},
    { title: 'Avg Bar.P (mbar)', dataIndex: "barometricPressure", 
     align: 'left'},
    { title: 'Avg Shot (μL)', dataIndex: "lastShotSize", 
    align: 'left'},
    { title: 'Min Shot (μL)', dataIndex: "minShotSize", 
    align: 'left'},
    { title: 'Max Shot (μL)', dataIndex: "maxShotSize", 
    align: 'left'},
    { title: 'Sum Shot (μL)', dataIndex: "totalShotSize", 
    align: 'left'},
    { title: 'Total Shots', dataIndex: "totalShots", 
    align: 'left'},
    { title: 'Avg sgp4x2_VOC', dataIndex: "sgp4x2_VOC", 
    align: 'left' },
    { title: 'Min sgp4x2_VOC', dataIndex: "min_sgp4x2_VOC", 
    align: 'left' },
    { title: 'Max sgp4x2_VOC', dataIndex: "max_sgp4x2_VOC", 
    align: 'left' },
    { title: 'Avg sgp4x2_Nox', dataIndex: "sgp4x2_Nox", 
    align: 'left' },
    { title: 'Min sgp4x2_Nox', dataIndex: "min_sgp4x2_Nox", 
    align: 'left' },
    { title: 'Max sgp4x2_Nox', dataIndex: "max_sgp4x2_Nox", 
    align: 'left' },
    { title: 'Avg sgp4x4_VOC', dataIndex: "sgp4x4_VOC",  
    align: 'left' },
    { title: 'Min sgp4x4_VOC', dataIndex: "min_sgp4x4_VOC", 
    align: 'left' },
    { title: 'Max sgp4x4_VOC', dataIndex: "max_sgp4x4_VOC", 
    align: 'left' },
    { title: 'Avg sgp4x4_Nox', dataIndex: "sgp4x4_Nox",  
    align: 'left' },
    { title: 'Min sgp4x4_Nox', dataIndex: "min_sgp4x4_Nox", 
    align: 'left' },
    { title: 'Max sgp4x4_Nox', dataIndex: "max_sgp4x4_Nox", 
    align: 'left' },
    { title: 'Avg sgp4x5_VOC', dataIndex: "sgp4x5_VOC", 
    align: 'left' },
    { title: 'Min sgp4x5_VOC', dataIndex: "min_sgp4x5_VOC", 
    align: 'left' },
    { title: 'Max sgp4x5_VOC', dataIndex: "max_sgp4x5_VOC", 
    align: 'left' },
    { title: 'Avg sgp4x5_Nox', dataIndex: "sgp4x5_Nox", 
    align: 'left' },
    { title: 'Min sgp4x5_Nox', dataIndex: "min_sgp4x5_Nox", 
    align: 'left' },
    { title: 'Max sgp4x5_Nox', dataIndex: "max_sgp4x5_Nox", 
    align: 'left' },
    { title: 'Avg sgp4x7_VOC', dataIndex: "sgp4x7_VOC", 
    align: 'left' },
    { title: 'Min sgp4x7_VOC', dataIndex: "min_sgp4x7_VOC", 
    align: 'left' },
    { title: 'Max sgp4x7_VOC', dataIndex: "max_sgp4x7_VOC", 
    align: 'left' },
    { title: 'Avg sgp4x7_Nox', dataIndex: "sgp4x7_Nox", 
    align: 'left' },
    { title: 'Min sgp4x7_Nox', dataIndex: "min_sgp4x7_Nox", 
    align: 'left' },
    { title: 'Max sgp4x7_Nox', dataIndex: "max_sgp4x7_Nox", 
    align: 'left' }
  ];

  handlePageChange = (pagination) => {
    console.log('handlePageChange:: called'); 
  }

  validateExportReadiness = () => {
    console.log('validating export readiness'); 
    console.log("this.state.deviceDiagsMapData : ", this.state.deviceDiagsMapData);
    if(this.state.deviceDiagsMapData.length <= 0){
      this.formValidationModalOpen('There is no summary (0 records) for the device & filters selected');
    }else{
    this.setState({ exportDialogVisible: true });
    console.log("Exporting log for deviceId: ",this.state.deviceId, " fromDateTime: ", this.state.fromDateTime, " toDateTime: ", this.state.toDateTime);
    this.generateDiagsReport(this.state.deviceId,this.state.fromDateTime,this.state.toDateTime);
    }
  }

  handleDownloadModal = (childData) => {
    this.setState({ exportDialogVisible: childData });
  }

  formValidationModalOpen = (dMessage) => {
    this.setState({ formDialogVisible: true });
    this.setState({ dialogMessage: dMessage});
  }

  handleFormValidationModal = (childData) => {
    this.setState({ formDialogVisible: childData });
  }

  getOffset = () =>{
    const d = new Date();
    let diff = d.getTimezoneOffset();
    return diff;
  }

  getTimeZone = () =>{
    const timezone = DateTime.local().toFormat('ZZZZ')
    console.log("Timezone : ", timezone);  
    return timezone;
  }

  renderFilters() {

    switch(this.state.typeOfDateFilter){
      case 'time':
        return(
          <li className="col-xl-2 col-lg-3 col-md-6 col-sm-6 list-group-item border-0 py-2 ps-3">
          <Select style={{width: "100%"}}
            labelid="time-range-select-label"
            id="time-range-select"
            defaultValue={defaultValueTimeFilter}
            label="Time"
            disabled={this.state.filtersDisabledState}
            onChange={this.handleTimeIntervalFilterChange} >
            <Option value={defaultValueTimeFilter} style={{ display: "none" }}>{defaultValueTimeFilter}</Option>
            <Option value={5}>Last 5 minutes</Option>
            <Option value={15}>Last 15 minutes</Option>
            <Option value={30}>Last 30 minutes</Option>
            <Option value={60}>Last 1 hour</Option>
            <Option value={180}>Last 3 hours</Option>
            <Option value={360}>Last 6 hours</Option>
            <Option value={720}>Last 12 hours</Option>
            <Option value={1440}>Last 24 hours</Option>
            <Option value={2880}>Last 2 days</Option>
            <Option value={4320}>Last 3 days</Option>
            <Option value={7200}>Last 5 days</Option>
            <Option value={10080}>Last 7 days</Option>
          </Select>
        </li>
        );
      case 'datetime':
        return(
        <li className="col-xl-4 col-lg-4 col-md-6 col-sm-12 list-group-item border-0 py-2 ps-3">
          <RangePicker
            style={{width: "100%"}}
            showTime={{
              format: 'HH:mm',
              defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('11:59:59', 'HH:mm:ss')],
            }}
            format="YYYY-MM-DD HH:mm"
            onChange={this.handleDateTimeRangeFilterChange}
            allowClear={false}
            disabled={this.state.dateTimeFilterDisabledState}
          />
        </li>
        );
      case 'datetimeSplit':
        return(
        <li className="col-xl-4 col-lg-4 col-md-6 col-sm-12 list-group-item border-0 py-2 ps-3">
        <RangePickerComponent
          datePickerValue={this.state.selectedDateRange}
          onChange={ this.handleDateRangeFilterChange }
          isDatePickerDisabled={ this.state.dateFilterDisabledState }
        />
       <TimePickerComponent
          onChange={ this.handleTimeRangeFilterChange }
          isTimePickerDisabled={ this.state.timeFilterDisabledState }
        />
        </li>
        );
      default:
        return(
          <li className="col-xl-2 col-lg-3 col-md-6 col-sm-6 list-group-item border-0 py-2 ps-3">
          <Select style={{width: "100%"}}
            labelid="time-range-select-label"
            id="time-range-select"
            defaultValue={defaultValueTimeFilter}
            label="Time"
            disabled={this.state.filtersDisabledState}
            onChange={this.handleTimeIntervalFilterChange} >
            <Option value={defaultValueTimeFilter} style={{ display: "none" }}>{defaultValueTimeFilter}</Option>
            <Option value={5}>Last 5 minutes</Option>
            <Option value={15}>Last 15 minutes</Option>
            <Option value={30}>Last 30 minutes</Option>
            <Option value={60}>Last 1 hour</Option>
            <Option value={180}>Last 3 hours</Option>
            <Option value={360}>Last 6 hours</Option>
            <Option value={720}>Last 12 hours</Option>
            <Option value={1440}>Last 24 hours</Option>
            <Option value={2880}>Last 2 days</Option>
            <Option value={4320}>Last 3 days</Option>
            <Option value={7200}>Last 5 days</Option>
            <Option value={10080}>Last 7 days</Option>
          </Select>
        </li>
        );
    }

  }

  render() {
    document.title = 'Device Summary Report';
    return (
      <div className="container-fluid px-3">
        <Affix offsetTop={64.2}>
          <div className="container-fluid px-3">

            <BreadcrumbComponent parent="Reports" child="Summary Report" />

            <div className="row">
              <div className="card ps-0">
                <div className="card-body py-0">
                  <div className="row">  
                    <div className="col">
                      <ul className="row mb-0 justify-content-end">
                        <li className="col-xl-2 col-lg-3 col-md-6 col-sm-6 list-group-item border-0 py-2 ps-3">
                          <Select placeholder="Devices"
                            id="device-select" label="Devices" style={{ width: "100%" }}
                             onChange={this.handleDeviceFilterChange}
                            >
                            {this.state.deviceMapState.map((make, index) => (
                              <Option key={make.id} value={make.id}>{make.metadata.name}</Option>
                            ))}
                          </Select>
                        </li>
                        <li className="col-xl-2 col-lg-3 col-md-6 col-sm-6 list-group-item border-0 py-2 ps-3">
                          <Select style={{width: "100%"}}
                            labelid="datefilter-select-label"
                            id="datefilter-select"
                            disabled={this.state.filtersDisabledState}
                            // value={this.state.deviceId}
                            defaultValue={this.state.typeOfDateFilter}
                            label="datefilter select"
                            onChange={this.handleReportFilterTypeChange} >
                            <Option key='time' value='time'>Time Interval based</Option>
                            <Option key='datetimeSplit' value='datetimeSplit'>Date-wise & Time Interval Each Day based</Option>
                            <Option key='datetime' value='datetime'>Date-Time range based</Option>
                          </Select>
                        </li>
                        { this.renderFilters() }
                        <li className="col-xl-2 col-lg-2 col-md-6 col-sm-6 list-group-item border-0 ps-3 py-2 pe-0">
                          <Button style={{width: "100%"}} disabled={this.state.exportDisabledState} type="primary" onClick={this.validateExportReadiness} icon={<DownloadOutlined style={{width:'20px'}}/>} size={'middle'}>Export</Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Affix>

        <div className="row mt-2 px-3">
          <div className="card px-0">
            <div className="card-body">
              <div>
                <Table
                  columns={this.deviceEventColumns}
                  size={'small'}
                  dataSource={this.state.deviceDiagsMapData}
                  pagination={{
                    showSizeChanger: false, pageSize: 25, onChange: (pagination, filters, sorter, currentPageData) => this.handlePageChange(pagination)
                  }}
                  scroll={{ x: 2400, y: 1800 }} 
                  loading={this.state.reportTableState}
                />
              </div>
            </div>
          </div>
        </div>
        <DownloadResponse visible={this.state.exportDialogVisible} parentCallback={this.handleDownloadModal} />
        <FormValidation visible={this.state.formDialogVisible} data={this.state.dialogMessage} parentCallback={this.handleFormValidationModal} />
      </div>
    )
  }
}

export default DeviceDiagsReport;