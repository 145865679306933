import { API } from 'aws-amplify';
import React from 'react';
import {updateDeviceMetadata} from '../graphql/mutations';
import { queryDevicesMetaDataByAccId} from '../graphql/queries';
import authService from './AuthService';
const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  
var accountId= '';
class DeviceMetadataService extends React.Component{

    //accountId = (await authService.getCurrentUser()).accountId;
    //update nickname
    async updateNickName(deviceId,nickName,locationDevicesId,version,hierarchyResult){
        // console.log('updateNickName--> ',deviceId,' nickName-->',nickName,
        // ' locationId-->',locationDevicesId,' version-->',version,' locationIdsList-->',hierarchyResult);
        try{
            const inputData = {id:deviceId,_version:version,name:nickName,locationId:locationDevicesId,parentHierarchy:hierarchyResult};
            const updateResponse = await API.graphql({ query: updateDeviceMetadata, variables: {input: inputData},authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken});
           // console.log('updateResponseData:: ',updateResponse);
            return updateResponse;
          
        //    return null;
        }catch(err) {console.log('Error Occured :: updateNickName() ',err); return null;}
    }

    //get list of devices for particular accountId (tenant)
    async getDevicesMetaDataByAccId(){
        accountId = (await authService.getCurrentUser()).accountId;
        try {
            const deviceMetaData = await API.graphql({query:queryDevicesMetaDataByAccId(accountId),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
            const deviceMetaDataList = deviceMetaData.data.listDeviceMetadata.items;
            const deviceMetaDataListSort = deviceMetaDataList.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
            console.log("getDevicesMetaDataByAccId::deviceMetaDataListSort: ",deviceMetaDataListSort);
            return deviceMetaDataListSort;
        } catch (err) { 
              console.log('Error Occured getDevicesByAccId:: ',err); return null; 
        }
    }
}
export default new DeviceMetadataService();