import React from 'react';
import 'antd/dist/antd.css';
import { TimePicker } from 'antd';

export class TimePickerComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
        }
    }

    render() {
        return(
              <TimePicker.RangePicker
                style={{width: "100%"}}
                format="HH:mm"
                onChange={this.props.onChange}
                allowClear={false}
                disabled={this.props.isTimePickerDisabled}
              />
        );
    }
}

export default TimePickerComponent;