import React from 'react';
import 'antd/dist/antd.css';
import { SyncOutlined } from '@ant-design/icons';
import ReactApexChart from 'react-apexcharts';
import { Link as ReactLink } from "react-router-dom";
import { Space } from 'antd';

export class GuageCardComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
  
        }
    }

    render() {
      return ( 
          <div className="card mb-2">
            <div className="card-body">
              <div className="card-title d-flex">
                {"link" in this.props.cardHeader ? 
                <div className="px-1 col-11 d-flex align-items-left justify-content-start float-start">
                  <ReactLink className="a-href-highlight-remove text-truncate pe-3" to="/singleDevice" onClick={() => {this.props.cardHeader.link.callBackOnTrigger(this.props.cardHeader.link.itemId)}} >{this.props.cardHeader.link.text}</ReactLink>
                </div>: null}

                {"title" in this.props.cardHeader ? <h5 className="card-title"> {this.props.cardHeader.title} </h5> : null}

              </div>
              <div id="chart" className="d-flex justify-content-center" >
                  <ReactApexChart options={this.props.guageCardOptions.radialOptions} series={[this.props.series]} width={this.props.guageCardOptions.guage.width} height={this.props.guageCardOptions.guage.height} type="radialBar" />
              </div>

              {("target" in this.props) ?
                <div className="row card-targets">
                  <div className={'col d-flex align-items-center justify-content-end pe-' + this.props.guageCardOptions.guage.targetValuePadding}>
                    <span className="range">{ 0 }</span> 
                  </div>
                  <div className={'col d-flex align-items-center justify-content-start ps-' + this.props.guageCardOptions.guage.targetValuePadding}>
                    <span className="range">{ this.props.target }</span>
                  </div>
                </div>
              : null}

              {("guageCenterTitle" in this.props.guageCardOptions.guage)? 
                <h5 className="card-title text-center">{ this.props.guageCardOptions.guage.guageCenterTitle }</h5>  
              : null}
              
              <div className="card-footer">
                
                {("deviceModeIcon" in this.props)?
                  <div className="col d-flex align-items-center float-start">
                    <i className={this.props.deviceModeIcon.iconClassName}></i>
                    <span style={{ color: this.props.deviceModeIcon.iconTitle.color }}> { this.props.deviceModeIcon.iconTitle.text }</span>
                  </div>  
                : null}
                
                <div className="col d-flex align-items-center float-end">
                 
                 
                  {/* <div className='weighted-avg-label'>
                 <Space> Min:<span style={{color:'#0096FF'}}>{this.props.minClO2Value} ppb</span> </Space> 
                  <Space>Max:<span style={{color:'#0096FF'}}>{this.props.maxClO2Value} ppb</span></Space> <Space></Space>
                  
                  </div> */}
                    
                  {this.props.refreshState === true ? <SyncOutlined spin/> : <SyncOutlined spin style={{ color: 'white' }} />}
                </div>
              </div>
            </div>
          </div>
      )
    }
}

export default React.memo(GuageCardComponent);