import React from "react";
import NotificationService from '../../services/NotificationService';
import { Affix,Menu, Dropdown,Button } from 'antd';
import 'antd/dist/antd.css';
import { DataTable } from '../../utils/tables/DataTable';
import { DownOutlined, SyncOutlined,PlusOutlined } from '@ant-design/icons';
import SharedService from "../../services/SharedService";
import DeviceMetadataService from "../../services/DeviceMetadataService";
import CreateThingGroup from '../../utils/components/modalComponent/createThingGroup';
var moment = require('moment'); // require
export class DeviceGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      groupData: [],
      createThingGroupModal:false,
      thingGroupVisible: false,
      deviceFilteredList:[],
      userRole:'',
      username:''
    }
  }

  getDeviceGroupData() {
    this.setState({refreshState: true});
    let thingGroupName = " ";
    let thingName = " ";
    let actionType = "view";
    SharedService.createDeviceGroup(thingName,thingName,actionType).then((result) => {      
      this.setState({ groupData: result });
     
    });
  }

  
  createThingGroupModalOpen = () => {
    this.setState({ createThingGroupModal: true });
    //this.setState({ thingGroupVisible: true });
    console.log('called')
   
  }

  handleThingGroupModal = (state) =>{
    this.setState({createThingGroupModal:state});
  }

  async getDeviceMetaData() {
    await DeviceMetadataService.getDevicesMetaDataByAccId().then((metaDataListData) => {    
      var metaDataWithLocation = [];
      for(var i=0;i<metaDataListData.length;i++){
        metaDataListData[i].createdAt = moment(metaDataListData[i].createdAt).local().format('YYYY-MM-DD HH:mm:ss');
        metaDataListData[i].updatedAt = moment(metaDataListData[i].updatedAt).local().format('YYYY-MM-DD HH:mm:ss');
      }     
      
      this.setState({deviceFilteredList:metaDataListData});
     
    });
  }

  async getUserRole() {
    try {
      await SharedService.getCurrentUser().then((userDataList) => {
        SharedService.getUserByEmailId(userDataList.attributes.email).then((userData) => {
          var userDataList = userData;
          if (userDataList === null || userDataList === [] || userDataList.length === 0) {
            this.setState({ userRole: '' });
          } else {
            var userRoleName = userDataList[0].userType;
            this.setState({ userRole: userRoleName });
            this.setState({ username: userDataList[0].username })
            console.log('username--', userDataList[0].username,'tYPE--', userDataList[0].userType )
          }
        });
      });

    } catch (err) {
      console.log('error on DeviceMeta::getUserRole:: ', err);
    }
  }
  componentDidMount() {
    setTimeout(() => {
        this.getUserRole();
      this.getDeviceGroupData();
      this.getDeviceMetaData();
    }, 100)

  }

  render() {
    document.title = 'Device Groups';
    return (
      <div className="container-fluid">
        <Affix className="z-index" offsetTop={30}>
          <div className="container-fluid">
            <div  className="row pb-1 pt-2"
              style={{ backgroundColor: "#f0f1f6", border: "1px" }}>
              <nav aria-label="breadcrumb justify-content-center align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Device Groups</li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="card pe-0">
                <div className="card-body pe-0 pt-1 pb-0">
                  <div className="row">
                    <div className="col d-flex align-items-center ps-0"></div>
                    <div className="col">
                      <ul className="list-group list-group-horizontal pe-1 d-flex align-items-center justify-content-end">
                       
                      {this.state.userRole === "ADMIN_OTA" ?<li className="list-group-item py-1 ps-0 px-2 border-0">
                            <Button style={{width: "100%"}} type="primary" onClick={this.createThingGroupModalOpen} icon={<PlusOutlined style={{width:'20px',verticalAlign:'0'}}/>} size={'middle'}>Group</Button>
                          </li> : <></>}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Affix>
        <div className="container-fluid mb-2 mt-2 pe-0 ps-0">
          <div className="row">
            <DataTable 
              tableData={this.state.groupData}
              title="deviceGroup"
            />
          </div>
          <div>
            <CreateThingGroup visible={this.state.createThingGroupModal} deviceListData={this.state.deviceFilteredList}
                parentCallback={this.handleThingGroupModal} />
            
        </div>
        </div>
      </div>
    )
  }
}

export default DeviceGroup;

