import React from "react";
import DeviceTelemetryService from '../../services/DeviceTelemetryService';
import SharedService from "../../services/SharedService";
import DeviceService from "../../services/DeviceService";
import "../../assets/styles/components/_data-table.scss";
import { Table,Progress } from 'antd';
import { Space, Affix } from 'antd';
import { DatePicker, Select } from 'antd';
import { Button, Input } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import DownloadResponse from "../../utils/components/modalComponent/downloadResponse";
import Highlighter from "react-highlight-words";
import { BreadcrumbComponent } from '../../utils/components/breadcrumbComponent';
const { Option } = Select;

const { RangePicker } = DatePicker;
var moment = require('moment'); // require
var dataChunk = [];

var defaultValueTimeFilter = "Last 1 hour"
export class DeviceEventsReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTimeRange: [],
      deviceTelemetryMapData: [],
      refreshState: true,
      timeFilter: 'true',
      fromDateTime: moment().subtract(60, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z",
      toDateTime: moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z",
      typeOfDateFilter: 'time',
      eventReportMapData: [],
      nextTokenForReports: '',
      timeRange: "60",
      searchText: '',
      searchedColumn: '',
      visible: false,
      deviceMapState: [],
      latestDeviceNameState: [],
      nickname: '',
      deviceId:''
    }
    this.searchInput = React.createRef();
  }


  getTelemetryData = () => {
    DeviceService.getDevicesByAccId().then((res) => {      
      console.log('resultGetTelemetry Data:: ',res);
      //this.setState({ latestDeviceNameState: res[0].metadata.name });
      //this.setState({ nickname: res[0].metadata.name });
      this.setState({deviceMapState:res});
    });
  }

  handleDeviceFilterChange = (e) => {
    this.setState({ deviceId: e });
    console.log('e: deviceId:: ',e);
    this.setState({ nextTokenForReports: '' });
    if(this.state.timeFilter == 'true'){
      this.createEventReportMapData('n',e, this.state.timeRange);
    }else{
      this.createEventReportMapDataByDateRange(e,this.state.fromDateTime, this.state.toDateTime);
    }
  }
  
  onChange = (value, dateString) => {
    this.setState({ nextTokenForReports: '' });
    this.setState({ selectedDateRange: true });
    this.setState({ refreshState: true });
    this.setState({ nextTokenForReports: '' });
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString[0], "--to Time- ", dateString[1]);
    let currentTime = moment.utc(moment(dateString[1])).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
    let fromTime = moment.utc(moment(dateString[0])).format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
    console.log('current time:: ', fromTime, currentTime);
    this.setState({ fromDateTime: fromTime });
    this.setState({ toDateTime: currentTime });
    setTimeout(() => {
      this.createEventReportMapDataByDateRange(this.state.deviceId,fromTime, currentTime);
    }, 5000)
    setTimeout(() => {
      this.adjustTableColumnHeight();
    }, 1000)
  };

  onChangeFilterDateTime = (e) => {
    console.log('onChangeFilterDateTime::', e);
    if (e === 'time') {
      this.setState({ timeFilter: 'true' });
      this.setState({ selectedTimeRange: 60 })
      this.setState({ refreshState: true });
      this.createEventReportMapData('n',this.state.deviceId, 60);
    } else {
      this.setState({ timeFilter: 'false' });
      this.setState({ refreshState: true });
      this.setState({ nextTokenForReports: '' });
      let tempFromTime = moment().subtract(60, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
      let tempToTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
      this.setState({ fromDateTime: moment().subtract(60, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z" });
      this.setState({ toDateTime: moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z" });
      setTimeout(() => {
        this.createEventReportMapDataByDateRange(this.state.deviceId,tempFromTime, tempToTime);
      }, 5000)
    }
  }

  getDeviceTelemetryData = (deviceId,fromTime, currentTime, limit, nextTokenForReports) => {
    if(deviceId != this.state.deviceId){
      nextTokenForReports = '';
    }
    if(currentTime != this.state.toDateTime){
      nextTokenForReports = '';
    }
    console.log("in :: getDeviceTelemetryData");
    console.log('getDeviceTelemetryData:: deviceId:: ',deviceId);
    DeviceTelemetryService.getHistoricalTelemetryForReports(deviceId,fromTime, currentTime, limit, nextTokenForReports).then((resultTelemetry) => {
      if(deviceId == ''){
        this.setState({ eventReportMapData: resultTelemetry.data.deviceTelemetryByTenant.items });
        if (resultTelemetry.data.deviceTelemetryByTenant.nextToken != null) {
          this.setState({ nextTokenForReports: resultTelemetry.data.deviceTelemetryByTenant.nextToken });
        }
      }else{
        this.setState({ eventReportMapData: resultTelemetry.data.deviceTelemetryByDate.items });
        if (resultTelemetry.data.deviceTelemetryByDate.nextToken != null) {
          this.setState({ nextTokenForReports: resultTelemetry.data.deviceTelemetryByDate.nextToken });
        }
      }
      setTimeout(() => {
        this.adjustTableColumnHeight();
      }, 500)
      this.setState({ refreshState: false });
    });
  }

  createEventReportMapData = (onMount,deviceId, timeRangeSelected) => {
    let currentTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
    let fromTime = moment().subtract(timeRangeSelected, 'm').utc().format("yyyy-MM-DDTHH:mm:ss.SSS") + "Z";
    let limit = 26;
    console.log('fromTime:: ', fromTime);
    console.log('current time:: ', currentTime);
    console.log('deviceId:: ', deviceId);
    this.setState({ timeRange: timeRangeSelected });
    this.setState({ fromDateTime: fromTime });
    this.setState({ toDateTime: currentTime });
    
    this.getDeviceTelemetryData(deviceId,fromTime, currentTime, limit, this.state.nextTokenForReports);

  }

  adjustTableColumnHeight = () => {
    if (window.screen.width <= 720){
      let thElement = document.querySelectorAll('.ant-table-thead tr th');
      let tdElement = document.querySelectorAll('.ant-table-tbody tr td');
      const tableHeadersSize = thElement.length;
      for (let i = 0; i < tableHeadersSize; i++){
        for (let j = i ; j < tdElement.length; j = j + tableHeadersSize){
          const thHeight = thElement[i].clientHeight;
          const tdHeight = tdElement[j].clientHeight;
          if (thHeight > tdHeight){
            // set td height as th height
            tdElement[j].setAttribute("style", "height:" + (thHeight) + "px");
          }
          else{
            // set th height as td height
            thElement[i].setAttribute("style", "height:" + (tdHeight + 1) + "px");
          }
        } 
      }
    }
  }

  createEventReportMapDataByDateRange(deviceId,fromTime, toTime) {
    console.log('next', this.state.nextTokenForReports)
    let limit = 26;
    this.getDeviceTelemetryData(deviceId,fromTime, toTime, limit, this.state.nextTokenForReports);
  }

  handleTimeFilterChange = (event) => {
    console.log('handleTimeFilterChange - time filter::', event)
    this.setState({ nextTokenForReports: '' });
    this.setState({ selectedTimeRange: event });
    this.setState({ refreshState: true })
    this.createEventReportMapData('n',this.state.deviceId, event);
  };

  componentDidMount() {
    if(window.screen.width > 720){
      for (const column of this.deviceEventColumns){
        if (column.title === "EventUUID"){
          column.width = 300;
        }
        if (column.title === "Name"){
          column.width = 200;
        }
        if (column.title === "DeviceUUID"){
          column.width = 300;
        }
        if (column.title === "Timestamp"){
          column.width = 200;
        }
        if (column.title === "Cartridge(%)"){
          column.width = 120;
        }
        if (column.title === "Generated"){
          column.width = 100;
        }
        if (column.title === "sgp4x2"){
          column.width = 150;
        }
        if (column.title === "sgp4x4"){
          column.width = 150;
        }
        if (column.title === "sgp4x5"){
          column.width = 150;
        }
        if (column.title === "sgp4x7"){
          column.width = 150;
        } 
      }
    }
    
    setTimeout(() => {
      this.createEventReportMapData('y', this.state.deviceId,60);
    }, 100)
    console.log("Event map data: ", this.state.eventReportMapData);
    this.getTelemetryData();
    setTimeout(() => {
      this.adjustTableColumnHeight();
    }, 2000) 
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();   
    this.setState({ searchText: selectedKeys[0] });
    this.setState({ searchedColumn: dataIndex });
  };

  handleReset = (selectedKeys, confirm, dataIndex) => {
    confirm();  
    this.setState({ searchText: '' });
    this.setState({ searchedColumn: dataIndex });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8, }} >
        <Input ref={this.searchInput} placeholder={`Search ${dataIndex}`}  value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{  marginBottom: 8,  display: 'block', }} />
        <Space>
          <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />}   size="small"   style={{ width: 90}}> Search</Button>
          <Button   onClick={() => this.handleReset('', confirm, dataIndex)}  size="small" style={{width: 90 }}>Reset</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{  color: filtered ? '#1890ff' : undefined,}}/>
    ),
    onFilter: (value, record) =>
      record.device.metadata[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter highlightStyle={{backgroundColor: "#FFFF00",padding: 0 }} searchWords={[this.state.searchText]}  autoEscape
        textToHighlight={text ? text.toString() : ""} />) : (text)
    });

  deviceEventColumns = [
    { title: 'EventUUID', dataIndex: "id", 
      width: 110, 
      align: 'left', fontWeight: '900', },

    { title: 'Name', dataIndex: ['device', 'metadata', 'name'], 
    // width: 250, 
    align: 'left' },
    { dataIndex: 'deviceId', title: 'DeviceUUID', 
    // width: 400, 
    align: 'left', fontWeight: '700' },
    { title: 'ClO2 (ppb)', dataIndex: ['clo2', 'value'], 
    // width: 100, 
    align: 'left', render: (text) => text.toFixed(2), sorter: (a, b) => a.clo2.value - b.clo2.value, },
    { title: 'Humidity (%)', dataIndex: ['humidity', 'value'], 
    // width: 100, 
    align: 'left', render: (text) => text.toFixed(2), sorter: (a, b) => a.humidity.value - b.humidity.value },
    { title: 'Temp (°C)', dataIndex: ['temperature', 'value'], 
    // width: 100, 
    align: 'left', render: (text) => text.toFixed(2), sorter: (a, b) => a.temperature.value - b.temperature.value },
    { title: 'Baro. P (mbar)', dataIndex: ['barometricPressure', 'value'], 
    // width: 120, 
    align: 'left', render: (text, record) => (text * 0.02953).toFixed(2), sorter: (a, b) => a.barometricPressure.value - b.barometricPressure.value },
    { title: 'Cartridge(%)', dataIndex: "cartridgeLevel", renderCell: this.renderProgress,
    //  width: 150, 
     align: 'left', 
    render: (text,record) =>{   
      return (
        <Progress
        strokeColor={{
          '0%': '#108ee9',
          '100%': '#87d068',
        }}
        percent={Math.floor(Math.random() * (98 - 96 + 1)) + 96}
      />
      );
    }
    },
    { title: 'Generated', dataIndex: "generated", 
    // width: 100,
     align: 'left', render: (text) => text.toString() },
    { title: 'Shot Size (μL)', dataIndex: ['lastShotSize', 'value'], 
    // width: 100, 
    align: 'left' ,render: (text) => text.toFixed(2), sorter: (a, b) => a.lastShotSize.value - b.lastShotSize.value },
    { title: 'sgp4x2', dataIndex: "sgp4x2", 
    // width: 150, 
    align: 'left' },
    { title: 'sgp4x4', dataIndex: "sgp4x4", 
    // width: 150, 
    align: 'left' },
    { title: 'sgp4x5', dataIndex: "sgp4x5", 
    // width: 150, 
    align: 'left' },
    { title: 'sgp4x7', dataIndex: "sgp4x7", 
    // width: 150, 
    align: 'left' },
    { title: 'Target', dataIndex: ['target', 'value'], 
    // width: 100, 
    align: 'left', },
    { title: 'Target Reached', dataIndex: "targetReached", 
    // width: 100, 
    align: 'left' },
    { title: 'Timestamp', dataIndex: "eventDate", 
    // width: 200, 
    align: 'left', sorter: (a, b) => new Date(a.eventDate) - new Date(b.eventDate), sortDirections: ['descend', 'ascend'] },
  ];

  handlePageChange = (pagination) => {
    console.log('handlePageChange:: called');
    let temp_data = [];    
    if (this.state.nextTokenForReports != null){
      this.setState({ refreshState: true });
      DeviceTelemetryService.getHistoricalTelemetryForReports(this.state.deviceId,this.state.fromDateTime, this.state.toDateTime, 26, this.state.nextTokenForReports).then((resultTelemetry) => {
        if (resultTelemetry != null){
          if(this.state.deviceId === ''){
            temp_data.push(resultTelemetry.data.deviceTelemetryByTenant.items);
            if (resultTelemetry.data.deviceTelemetryByTenant.nextToken != null) {
              this.setState({ nextTokenForReports: resultTelemetry.data.deviceTelemetryByTenant.nextToken });
            }
            else{
              this.setState({ nextTokenForReports: null });
            }
          }else{
            temp_data.push(resultTelemetry.data.deviceTelemetryByDate.items);
            if (resultTelemetry.data.deviceTelemetryByDate.nextToken != null) {
              this.setState({ nextTokenForReports: resultTelemetry.data.deviceTelemetryByDate.nextToken });
            }
            else{
              this.setState({ nextTokenForReports: null });
            }
          }
          dataChunk = this.state.eventReportMapData;
          let nextPaginatedData = dataChunk.concat(temp_data[0]);
          this.setState({ eventReportMapData: nextPaginatedData });
          this.setState({ refreshState: false });
          setTimeout(() => this.adjustTableColumnHeight(), 1000);
        }
      });
    } else{
      console.log("None token: ");
    }
    
  }

  downloadModalOpen = () => {
    this.setState({ visible: true });
    this.generateEventsReport();
  }

  handleDownloadModal = (childData) => {
    this.setState({ visible: childData });
  }

  //Submit function
  generateEventsReport = () => {
    // console.log('fromDate:: ', this.state.fromDateTime, '---toDate:: ', this.state.toDateTime);
    let reportType = 'Device Events'
    try {
      var offsetLocalTimeZone = this.getOffset();
        SharedService.generateEventsReportByDeviceId(this.state.deviceId,this.state.fromDateTime, this.state.toDateTime, reportType,offsetLocalTimeZone)
        .then((createJobResponse) => {
          console.log('createJobResponse:: ', createJobResponse);
        });
    } catch (err) {
      console.log('Error occured Create generateEventsReport :: ', err);
    }
  }
  getOffset = () =>{
    const d = new Date();
    let diff = d.getTimezoneOffset();
    return diff;
  }


  render() {
    document.title = 'Device Event Report';
    return (
      <div className="container-fluid px-3">
        <Affix offsetTop={64.2}>
          <div className="container-fluid px-3">

            <BreadcrumbComponent parent="Reports" child="Event Log" />

            <div className="row">
              <div className="card ps-0">
                <div className="card-body py-0">
                  <div className="row">  
                    <div className="col">
                      <ul className="row mb-0 justify-content-end">
                        <li className="col-xl-2 col-lg-3 col-md-6 col-sm-6 list-group-item border-0 py-2 ps-3">
                          <Select placeholder="Devices"
                            id="device-select" label="Devices" style={{ width: "100%" }}
                             onChange={this.handleDeviceFilterChange}
                            >
                            {this.state.deviceMapState.map((make, index) => (
                              <Option key={make.id} value={make.id}>{make.metadata.name}</Option>
                            ))}
                          </Select>
                        </li>
                        <li className="col-xl-2 col-lg-3 col-md-6 col-sm-6 list-group-item border-0 py-2 ps-3">
                          <Select style={{width: "100%"}}
                            labelid="datefilter-select-label"
                            id="datefilter-select"
                            disabled={this.state.refreshState}
                            // value={this.state.deviceId}
                            defaultValue={this.state.typeOfDateFilter}
                            label="datefilter select"
                            onChange={this.onChangeFilterDateTime} >
                            <Option key='time' value='time'>Time</Option>
                            <Option key='datetime' value='datetime'>Date & Time</Option>
                          </Select>
                        </li>

                        {this.state.timeFilter == 'true' ?
                          <li className="col-xl-2 col-lg-3 col-md-6 col-sm-6 list-group-item border-0 py-2 ps-3">
                            <Select style={{width: "100%"}}
                              labelid="time-range-select-label"
                              id="time-range-select"
                              defaultValue={defaultValueTimeFilter}
                              label="Time"
                              disabled={this.state.refreshState}
                              onChange={this.handleTimeFilterChange} >
                              <Option value={defaultValueTimeFilter} style={{ display: "none" }}>{defaultValueTimeFilter}</Option>
                              <Option value={5}>Last 5 minutes</Option>
                              <Option value={15}>Last 15 minutes</Option>
                              <Option value={30}>Last 30 minutes</Option>
                              <Option value={60}>Last 1 hour</Option>
                              <Option value={180}>Last 3 hours</Option>
                              <Option value={360}>Last 6 hours</Option>
                              <Option value={720}>Last 12 hours</Option>
                              <Option value={1440}>Last 24 hours</Option>
                              <Option value={2880}>Last 2 days</Option>
                              <Option value={4320}>Last 3 days</Option>
                              <Option value={7200}>Last 5 days</Option>
                              <Option value={10080}>Last 7 days</Option>
                            </Select>
                          </li>
                          :
                          <li className="col-xl-4 col-lg-4 col-md-6 col-sm-12 list-group-item border-0 py-2 ps-3">
                              <RangePicker
                                style={{width: "100%"}}
                                showTime={{
                                  format: 'HH:mm',
                                }}
                                format="YYYY-MM-DD HH:mm"
                                onChange={this.onChange}
                                allowClear={false}
                              />
                          </li>
                        }
                        <li className="col-xl-2 col-lg-2 col-md-6 col-sm-6 list-group-item border-0 ps-3 py-2 pe-0">
                          <Button style={{width: "100%"}} type="primary" onClick={this.downloadModalOpen} icon={<DownloadOutlined style={{width:'20px'}}/>} size={'middle'}>Export</Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Affix>

        <div className="row mt-2 px-3">
          <div className="card px-0">
            <div className="card-body">
              <div>
                <Table
                  columns={this.deviceEventColumns}
                  size={'small'}
                  dataSource={this.state.eventReportMapData}
                  pagination={{
                    showSizeChanger: false, pageSize: 25, onChange: (pagination, filters, sorter, currentPageData) => this.handlePageChange(pagination)
                  }}
                  scroll={{ x: 2400, y: 1800 }} 
                  loading={this.state.refreshState}
                />
              </div>
            </div>
          </div>
        </div>
        <DownloadResponse visible={this.state.visible} parentCallback={this.handleDownloadModal} />
      </div>
    )
  }
}

export default DeviceEventsReport;