import React from 'react';
import { Affix,Select,Button } from 'antd';
import DeviceMetadataService from '../../services/DeviceMetadataService';
import LocationService from '../../services/LocationService';
import { Dropdown as DropdownReact } from 'react-bootstrap';
import { BreadcrumbComponent } from '../../utils/components/breadcrumbComponent';
import { DataTable } from '../../utils/tables/DataTable';
import FleetOtaUpdate from '../../utils/components/modalComponent/fleetOtaUpdate';
import FleetCommand from '../../utils/components/modalComponent/fleetCommand';
import SharedService from '../../services/SharedService';
import OTARuntimeService from '../../services/OTARuntimeService';
import { SearchOutlined, DownloadOutlined ,PlusOutlined} from '@ant-design/icons';
import DownloadResponse from "../../utils/components/modalComponent/downloadResponse";
import { toHaveDisplayValue } from '@testing-library/jest-dom/dist/matchers';
import CreateThingGroup from '../../utils/components/modalComponent/createThingGroup';

const { Option } = Select;
var moment = require('moment'); // require

export class DeviceMetaData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      metaDataList: [],
      refreshIntervalState:30000,
      refreshState:true,
      deviceId:"",
      deviceFilteredList:[],
      userRole: '',
      visible: false,
      exportModal: false,
      targetFirmwareList:[],
      firmwareType: '',
      username: '',
      devicesForFleetCommand:[],
      fleetCommandModalVisible: false,
      filteredDeviceList:[],
      locationDataState:[],
      exportButtonState : false,
      createThingGroupModal:false,
      thingGroupVisible: false,
    }
  }
  getMasterLocationData = () => {
    LocationService.getLocationByFilter().then((locationByFilter) => {
      this.setState({ locationDataState: locationByFilter })
    })
  }

  async getDeviceMetaData() {
    await DeviceMetadataService.getDevicesMetaDataByAccId().then((metaDataListData) => {    
      var metaDataWithLocation = [];
      for(var i=0;i<metaDataListData.length;i++){
        metaDataListData[i].createdAt = moment(metaDataListData[i].createdAt).local().format('YYYY-MM-DD HH:mm:ss');
        metaDataListData[i].updatedAt = moment(metaDataListData[i].updatedAt).local().format('YYYY-MM-DD HH:mm:ss');
      }
      metaDataWithLocation =  this.findLocationHierarchy(metaDataListData);
      this.setState({ metaDataList: metaDataWithLocation });
      console.log('in meta--',metaDataListData)
      this.setState({deviceFilteredList:metaDataListData});
      this.setState({refreshState:false});
    });
  }


  findLocationHierarchy = (metaDataListData) => {    
    for(var i=0;i<metaDataListData.length;i++){
      let hierarchy = [];
      let hierarchyList = [];
      let hierarchyResult = '';
      
      if (metaDataListData[i].location != null) {
        if (metaDataListData[i].parentHierarchy != "") {
          hierarchy.push(metaDataListData[i].parentHierarchy.split('#'));
          for (let i = 1; i < hierarchy[0].length; i++) {
            for (let j = 0; j < this.state.locationDataState.length; j++) {
              if (hierarchy[0][i] === this.state.locationDataState[j].id) {
                hierarchyList.push({ "value": this.state.locationDataState[j].value });
                hierarchyResult = hierarchyResult.concat(this.state.locationDataState[j].value + '/')
              }
            }
          }
        }
        else {
          hierarchyResult = 'None/';
        }
        hierarchyResult = hierarchyResult.slice(0, -1);
      }
      else {
        hierarchyResult = 'None'
      }
      //console.log('hierarchyResult:: ',hierarchyResult);
      metaDataListData[i].location = hierarchyResult;
    }
    return metaDataListData;
  }

  componentDidMount() {
    this.getUserRole();
    this.getMasterLocationData();
    setTimeout(() => {
      this.getDeviceMetaData();
    }, 100);
   // this.clearIntervalTelemetryData = setInterval(e => this.getDeviceMetaData(), this.state.refreshIntervalState);
  }
  handleDeviceFilterChange = (e) =>{
    console.log('e',e)
    this.setState({refreshState:true});
      console.log(e);
      this.setState({ deviceId: e });
      if (e === "" || e.length === 0) {
        this.setState({exportButtonState:false});
        this.getDeviceMetaData();
      } else {
        console.log('e length--', e.length)
          var tempDeviceMetaList = [];
          this.setState({exportButtonState:true});
         for(var i=0;i<this.state.deviceFilteredList.length;i++){
          for(var j=0;j<e.length;j++){
            if(e[j] == this.state.deviceFilteredList[i].deviceId){
              tempDeviceMetaList.push(this.state.deviceFilteredList[i]);
           }
          }
             
         } 
        this.setState({ metaDataList: tempDeviceMetaList });
        this.setState({refreshState:false});
      }
  }
  async getUserRole() {
    try {
      await SharedService.getCurrentUser().then((userDataList) => {
        SharedService.getUserByEmailId(userDataList.attributes.email).then((userData) => {
          var userDataList = userData;
          if (userDataList === null || userDataList === [] || userDataList.length === 0) {
            this.setState({ userRole: '' });
          } else {
            var userRoleName = userDataList[0].userType;
            this.setState({ userRole: userRoleName });
            this.setState({ username: userDataList[0].username })
            console.log('username--', userDataList[0].username,'tYPE--', userDataList[0].userType )
          }
        });
      });

    } catch (err) {
      console.log('error on DeviceMeta::getUserRole:: ', err);
    }
  }
  fleetCommandModal = () => {
    let resultData=[];
    this.setState({ fleetCommandModalVisible: true });
    //clearInterval(this.clearIntervalTelemetryData);  //stopping refresh intervals
    for (let i = 0; i < this.state.deviceFilteredList.length; i++) {
      if (this.state.deviceFilteredList[i].device.mode != 'OFFLINE') {
        resultData.push({ "deviceName": this.state.deviceFilteredList[i].udi, "deviceId": this.state.deviceFilteredList[i].deviceId, "nickname": this.state.deviceFilteredList[i].name });
     }
    }
    this.setState({devicesForFleetCommand:resultData});
  }
  handleFleetCommandResponseModal = (state) => {
    this.setState({ fleetCommandModalVisible: state });
    // this.clearIntervalTelemetryData = setInterval(() => { //starting refresh intervals on modal close
    //   this.refresh5Sec();
    // }, this.state.refreshIntervalState);
  }
  otaUpdateModalOpen = (firmwareType) => {
   
    if (firmwareType === 'comms') {
     // clearInterval(this.clearIntervalTelemetryData);
      this.setState({ visible: true });
      this.setState({ firmwareType: 'comms' });
      this.getFilteredDeviceList();
      this.getTargetFirmwareComms();
    }
    else {
      //clearInterval(this.clearIntervalTelemetryData);
      this.setState({ visible: true });
      this.setState({ firmwareType: 'controls' });
      this.getFilteredDeviceList();
      this.getTargetFirmwareControls();
    }
  }
  getFilteredDeviceList = () => {
    let deviceNameMap = [];
    var deviceId = ""
    OTARuntimeService.getOTALiveDataByAccId().then((res) => {
      for (var i = 0; i < res.length; i++) {
        if (res[i].jobStatus === "CREATE_IN_PROGRESS" || res[i].jobStatus === "CREATE_PENDING") {
          deviceId = res[i].deviceId;
        }
      }
      deviceNameMap = this.state.deviceFilteredList;
      for (var i = 0; i < deviceNameMap.length; i++) {
        if (deviceNameMap[i].deviceId === deviceId) {
          var index = deviceNameMap.indexOf(deviceNameMap[i]);
          if (index > -1) { // only splice array when item is found
            deviceNameMap.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
      var deviceOTAList = [];
      for(var i=0;i<deviceNameMap.length;i++){
        deviceOTAList.push({ "deviceName": deviceNameMap[i].udi, "deviceId": deviceNameMap[i].deviceId, "nickname": deviceNameMap[i].name });
      }
      console.log('deviceOTAList:: ',deviceOTAList)
      this.setState({ filteredDeviceList: deviceOTAList });
    });
  }

  handleOtaUpdateModal = (childData) => {
   
    this.setState({ visible: childData });
    // this.clearIntervalTelemetryData = setInterval(() => { //starting refresh intervals on modal close
    //   this.refresh5Sec();
    // }, this.state.refreshIntervalState);
  }
  downloadModalOpen = () => {
    this.setState({ exportModal: true });
    this.generateMetadataMasters();
  }

  createThingGroupModalOpen = () => {
    this.setState({ createThingGroupModal: true });
    //this.setState({ thingGroupVisible: true });
    console.log('called')
   
  }

  handleThingGroupModal = (state) =>{
    this.setState({createThingGroupModal:state});
  }
  handleDownloadModal = (childData) => {
    this.setState({ exportModal: childData });
  }

  //Submit function
  generateMetadataMasters = () => {
    // console.log('fromDate:: ', this.state.fromDateTime, '---toDate:: ', this.state.toDateTime);
    let reportType = 'Device Metadata'
    try {
      var offsetLocalTimeZone = this.getOffset();
        SharedService.generateMetaDataExport(reportType,offsetLocalTimeZone)
        .then((createJobResponse) => {
          console.log('generateMetadataMasters:: ', createJobResponse);
        });
    } catch (err) {
      console.log('Error occured Create generateMetadataMasters :: ', err);
    }
  }

  getOffset = () =>{
    const d = new Date();
    let diff = d.getTimezoneOffset();
    return diff;
  }

  getTargetFirmwareComms = () => {
    SharedService.fetchS3ObjectsOTABucket('comms').then((targetFirmwareListData) => {
      this.setState({ targetFirmwareList: targetFirmwareListData })
    });
  }

  getTargetFirmwareControls = () => {
    SharedService.fetchS3ObjectsOTABucket('controls').then((targetFirmwareListData) => {
      this.setState({ targetFirmwareList: targetFirmwareListData })
    });
  }


  render() {
    document.title = 'Device Metadata';
    return (
      <div className="container-fluid px-3">
        <Affix offsetTop={64.2}>
          <div className="container-fluid px-3">
          <BreadcrumbComponent parent="Masters" child="Device Metadata" />
            <div className="row ">
              <div className="card pe-0">
                <div className="card-body pe-0 pt-1 pb-0">
                  <div className="row">
                    <div className="col">
                    </div>
                    <div className="col-xl-7 col-lg-8 col-md-12 col-sm-12">
                      <ul className="list-group list-group-horizontal pe-1 d-flex align-items-center justify-content-end">
                        <li style={{ width: "100%" }} className="list-group-item py-1 ps-0 px-2 border-0">
                              <Select mode="multiple" placeholder="Devices" maxTagCount="responsive"
                               allowClear showArrow 
                              id="device-select" label="Devices" style={{ width: "100%" }}
                              onChange={this.handleDeviceFilterChange}>
                              {/* <Option value="">Devices</Option> */}
                              {this.state.deviceFilteredList.map((make, index) => (
                                  <Option key={make.deviceId} value={make.deviceId}>{make.name}</Option>
                              ))}
                              </Select>
                          </li>
                          <li className="list-group-item py-1 ps-0 px-2 border-0">
                          {this.state.username === 'christobel' || this.state.username === 'web_test1' || this.state.username === 'webtest' || this.state.username === 'cmills' ? 
                            <div className="dropdown dropdown-arrow-none">
                              <DropdownReact size="lg">
                                <DropdownReact.Toggle size="lg" className="btn ps-3 pe-0 float-right align-self-center text-dark bg-transparent border-0 hide-carret">
                                  <i className="mdi  mdi-24px  mdi-broadcast float-end"></i>
                                  </DropdownReact.Toggle>
                                  <DropdownReact.Menu>
                                  <div>
                                    <DropdownReact.Item onClick={() => this.otaUpdateModalOpen('comms')}><i className="mdi mdi-plus-circle-outline mr-2"></i> OTA Comms Update </DropdownReact.Item>
                                    <DropdownReact.Item onClick={() => this.otaUpdateModalOpen('controls')}><i className="mdi mdi-plus-circle-outline mr-2"></i> OTA Controls Update </DropdownReact.Item>
                                  </div>
                                </DropdownReact.Menu>
                              </DropdownReact>
                            </div> 
                            : <div></div>}
                          </li>
                          <li className="list-group-item py-1 ps-0 px-2 border-0">
                            <div className="dropdown dropdown-arrow-none">
                              <DropdownReact size="lg">
                                <DropdownReact.Toggle size="lg" className="btn ps-3 pe-2 float-right align-self-center text-dark bg-transparent border-0 hide-carret">
                                  <i className="mdi  mdi-24px mdi-pencil-box-multiple-outline float-end"></i>
                                </DropdownReact.Toggle>
                                <DropdownReact.Menu>
                                  <DropdownReact.Item className="a-href-highlight-remove" onClick={() => this.fleetCommandModal()}><i className="mdi mdi-pencil-outline mr-2"/> <span>Fleet Command</span></DropdownReact.Item>
                                </DropdownReact.Menu>
                              </DropdownReact>
                            </div>
                          </li>
                          <li className="list-group-item py-1 ps-0 px-2 border-0">
                            <Button style={{width: "100%"}} disabled={this.state.exportButtonState} type="primary" onClick={this.downloadModalOpen} icon={<DownloadOutlined style={{width:'20px'}}/>} size={'middle'}>Export</Button>
                          </li>

                          {this.state.userRole === "ADMIN_OTA" ?<li className="list-group-item py-1 ps-0 px-2 border-0">
                            <Button style={{width: "100%"}} type="primary" onClick={this.createThingGroupModalOpen} icon={<PlusOutlined style={{width:'20px',verticalAlign:'0'}}/>} size={'middle'}>Group</Button>
                          </li> : <></>}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Affix>
        <div className="row mt-2 px-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-2"></div>
                <DataTable refreshState={this.state.refreshState} tableData = { this.state.metaDataList } title="mastersDeviceMeta" />
              </div>
            </div>
        </div>
        <div>
            <FleetOtaUpdate visible={this.state.visible} deviceListData={this.state.filteredDeviceList}
                targetFirmwareData={this.state.targetFirmwareList} firmwareType={this.state.firmwareType} parentCallback={this.handleOtaUpdateModal} />
            { this.state.fleetCommandModalVisible ? <FleetCommand visible={this.state.fleetCommandModalVisible} deviceList={this.state.devicesForFleetCommand}  userRoleData={this.state.userRole} parentCallback={this.handleFleetCommandResponseModal} /> : null }
        </div>
        <div>
            <CreateThingGroup visible={this.state.createThingGroupModal} deviceListData={this.state.deviceFilteredList}
                parentCallback={this.handleThingGroupModal} />
            
        </div>
          <DownloadResponse visible={this.state.exportModal} parentCallback={this.handleDownloadModal} />
      </div>
    );
  };
}
export default DeviceMetaData;