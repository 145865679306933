import { API} from 'aws-amplify';
import { queryDevicesByAccId,queryCurrentTelemetryByDevIdAccId} from '../graphql/queries';
import React from 'react';
import authService from './AuthService';
const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  
 var accountId= '';
class DeviceService extends React.Component{

     //get list of devices for particular accountId (tenant)
    async getDevicesByAccId(){
        //console.log('getDevicesByAccId()',accountId);
        accountId = (await authService.getCurrentUser()).accountId;
        try {
            const deviceTelemetry = await API.graphql({query:queryDevicesByAccId(accountId),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
            const deviceList = deviceTelemetry.data.listDevices.items;
            const deviceListSort = deviceList.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
            //console.log("getDevicesByAccId::deviceListSort: ",deviceListSort);
            return deviceListSort;
        } catch (err) { 
              console.log('Error Occured getDevicesByAccId:: ',err); return null; 
        }
    }


    //get current telemetry on donuts and device details on breadcrumb
    async getCurrentTelemetryByDevIdAccId(deviceId){
        try{
            accountId = (await authService.getCurrentUser()).accountId;
            const currentTelmetryByDeviceId = await API.graphql(({query:queryCurrentTelemetryByDevIdAccId(accountId,deviceId),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));
            const currentTelmetryList  = currentTelmetryByDeviceId.data.listDevices.items;
            // for(var i=0;i<telemetryList.length;i++){
            //     telemetryList[i].eventDate = moment(telemetryList[i].eventDate).local().format('YYYY-MM-DD HH:mm:ss')
            // }
            return currentTelmetryList;
        }catch(err){ console.log('Error Occured :: getCurrentTelemetryByDevIdAccId() ', err); return null; };
    }


    
}
export default new DeviceService();