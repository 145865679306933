/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:1c5378be-ab07-4746-bfe6-3ecab4d14e78",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_4og5XtnMK",
    "aws_user_pools_web_client_id": "6k12bvk5sf4ekriirq4kpadjqa",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ols2mhhl2bbl5bmuusldmvnnia.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "aws_appsync_apiKey": "da2-ouivpeetljf2zmctltiyoh5afu",
    "aws_cloud_logic_custom": [
        {
            "name": "appCommandApi",
            "endpoint": "https://rygyl0rgr1.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
