import React from "react";
import OTARuntimeService from '../../../services/OTARuntimeService';
import SharedService from '../../../services/SharedService';
import DeviceService from '../../../services/DeviceService';
import 'antd/dist/antd.css';
import { Button,Affix} from 'antd';
import FleetOtaUpdate from '../../components/modalComponent/fleetOtaUpdate';
import { DataTable } from '../../tables/DataTable';

export class OtaControls extends React.Component {
  constructor(props){
  super(props)
    this.state = {
        bottom:'bottomRight',
        rowData:[],
        jobStatusElement:'',
        visible:false,
        deviceData:[],
        refreshIntervalState:10000,
        username:'',
        refreshState: true,
    }
  }
 
  getDeviceData = () => {
    this.setState({deviceData:[]});
    DeviceService.getDevicesByAccId().then((resultByFilter) => {     
      
      let deviceNameMap = [];
      for(const dataObj of resultByFilter){
        if(deviceNameMap.find(x => x.udi === dataObj.udi)){
        }
        else{
          // console.log(dataObj.metadata.name)
          deviceNameMap.push({"deviceName":dataObj.udi,"deviceId":dataObj.id,"nickname":dataObj.metadata.name});
        }
      }
      this.setState({deviceData:deviceNameMap});
      
    });
  }

  otaUpdateModalOpen = () =>{
    console.log('modal open ota');
    clearInterval(this.clearIntervalOTAControlsData);  //stopping refresh intervals
    this.setState({visible:true});
    this.setState({firmwareType:'controls'}); 
    this.getFilteredDeviceList();     
    this.getTargetFirmwareControls();    
   }
   
   getTargetFirmwareControls = () =>{
    SharedService.fetchS3ObjectsOTABucket('controls').then((targetFirmwareListData) => {
        console.log('targetFirmwareList:: ',targetFirmwareListData);
        this.setState({targetFirmwareList:targetFirmwareListData})
    });
  }

   
   handleOtaUpdateModal = (childData) =>{
    console.log('modal close');
    this.setState({visible:childData});
    this.clearIntervalOTAControlsData=setInterval(() => { //starting refresh intervals on modal close
      this.getOTALiveDataByAccIdAndFirmwareType();
    }, this.state.refreshIntervalState);
  }
  getFilteredDeviceList=()=>{
    let deviceNameMap = [];
    var deviceId=""
    OTARuntimeService.getOTALiveDataByAccId().then((res) => {        
      for(var i=0;i<res.length;i++){
        if(res[i].jobStatus === "CREATE_IN_PROGRESS" || res[i].jobStatus === "CREATE_PENDING"){
           deviceId = res[i].deviceId;
           console.log('DEVICidv idddd:: ',res[i].deviceId);
        }
      }
       deviceNameMap = this.state.deviceData;        
        for(var i=0;i<deviceNameMap.length;i++){
          if(deviceNameMap[i].deviceId == deviceId){           
            var index = deviceNameMap.indexOf(deviceNameMap[i]);
            console.log('index:: ',index)
            if (index > -1) { // only splice array when item is found
              deviceNameMap.splice(index, 1); // 2nd parameter means remove one item only
              console.log('deviceNameMap for wala--',deviceNameMap);
            }
          }
        }
        this.setState({filteredDeviceList:deviceNameMap});
      }); 
  }


  getOTALiveDataByAccIdAndFirmwareType(){
    let result = [];
    OTARuntimeService.getOTALiveDataByAccIdFirmwareType('controls').then((res) => {
        
       this.getFilteredDeviceList();
        console.log('ota result',res);
        for(let i=0;i<res.length;i++){
          if(res[i].device === null){
           // this.setState({rowData:[]});
          //  this.setState({ refreshState: false});
          }
          else{  
            result.push(res[i]);  
          }
        }
        this.setState({rowData:result});
        this.setState( {refreshState: false} )
      }); 
  }

  async getUsername() {
    try {
      await SharedService.getCurrentUser().then((userDataList) => {
        SharedService.getUserByEmailId(userDataList.attributes.email).then((userData) => {
          var userDataList = userData;
          if (userDataList === null || userDataList === [] || userDataList.length === 0) {
            this.setState({ username: [] });
          } else {
            this.setState({ username: userDataList[0].username })
          }
        });
      });

    } catch (err) {
      console.log('error on otaControls::getUsername:: ', err);
    }
  }

componentDidMount(){
  console.log('in OTA');
  this.getUsername();
  setTimeout(() => {
    this.getOTALiveDataByAccIdAndFirmwareType();
    this.getDeviceData();

  },100)

  this.clearIntervalOTAControlsData = setInterval(e => this.getOTALiveDataByAccIdAndFirmwareType(),this.state.refreshIntervalState);

}

componentWillUnmount() {
    clearInterval(this.clearIntervalOTAControlsData);
}

render () {
  document.title='OTA Controls';
  return (
      <div className=" m-2">
        <Affix className="z-index" offsetTop={30}  >
          <div className="row p-1">
          <nav aria-label="breadcrumb justify-content-center" >
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                OTA Controls<label style={{"color":"red" }}>.LIVE</label>
              </li>
                { this.state.username === 'christobel' || this.state.username === 'web_test1' ||  this.state.username === 'webtest' ||  this.state.username === 'cmills' ?                     
                <div className="col mb-2"> 
                  <li className=" float-end border-0"   >
                    <Button type="primary" onClick={this.otaUpdateModalOpen} > + Add OTA </Button>
                  </li>             
                </div>
                :<div></div>}
            </ol>
            </nav>
          </div>
        </Affix>

        <div className="row">
          
            <div className="justify-content-between align-items-center tab-transparent">
              <div className="col-12 grid-margin">
                <div className="card">                    
                  <div className="card-body">        
                    <div className="row">                        
                      <DataTable refreshState={this.state.refreshState} tableData = { this.state.rowData } title="otaControl" />
                    </div>
                  </div>   
                </div>
            </div>
          </div>   
          <FleetOtaUpdate visible={this.state.visible} deviceListData={this.state.filteredDeviceList} targetFirmwareData={this.state.targetFirmwareList} firmwareType={'controls'} parentCallback = {this.handleOtaUpdateModal}/>   
        </div>
      </div>
    )
  }
}

export default OtaControls;

