import React, { Component } from 'react';
import SharedService from '../services/SharedService';
import { Auth } from 'aws-amplify';
import { Menu, Dropdown } from 'antd';
import NotificationService from '../services/NotificationService';
import { Link as ReactLink } from "react-router-dom";
import ReportExportHistoryService from '../services/ReportExportHistoryService';
import {  CloseOutlined } from '@ant-design/icons';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userProfileDataState: [],
      userNameState: [],
      tenantNameState: [],
      notificationsData:[],
      newNotification: true,
      newReportDownload: true,
      downloadsData:[],
      collapsedMenuState:false,
    }
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    
  }
  menu(){
    if(this.state.collapsedMenuState=== false){
      this.setState({collapsedMenuState:true})
  }
  else{
    this.setState({collapsedMenuState:false});
  }
  }
  async getLoggedInUserProfile() {
    try {
      await SharedService.getCurrentUser().then((userProfileData) => {
        //console.log("user profile data: ", userProfileData);
        this.setState({ userProfileDataState: userProfileData });
        this.setState({ tenantNameState: userProfileData.signInUserSession.accessToken.payload['cognito:groups'][0] });
        this.setState({ userNameState: userProfileData.username });
      });
    } catch (err) {
      console.log('error on getLoggedInUserProfile:: ', err);
    }
  }

  onClickNotification = () => {
    // this.setState({newNotification: false});
    this.getNotifications();  
  }

  onClickDownloads = () => {
    // this.setState({newReportDownload: false});
    this.getReportExportHistoryByAccId();
  }

  async getNotifications(){
    try{
     await NotificationService.getNotificationsByAccId().then((result) => {
        var notificationSortedList = [];
        var length= result.length;
        if(length>4){
          for(var i=0;i<4;i++){
            notificationSortedList.push(result[i]);
          }
        }else{
          for(var i=0;i<result.length;i++){
            notificationSortedList.push(result[i]);
          }
        }        
        // console.log("this.state.notificationsData = ", this.state.notificationsData, " | notificationSortedList = ", notificationSortedList)
        if (JSON.stringify(this.state.notificationsData) !== JSON.stringify(notificationSortedList)){
          this.setState({newNotification: true});
          console.log("New Notifications");
        }
        console.log("notifications: ", notificationSortedList);
        setTimeout(() => {
          this.setState({notificationsData:notificationSortedList});
        }, 1000)
        
       // console.log('notificationSortedList:: data:: ',notificationSortedList);
      });
    }
    catch(err){
      console.log('error on getNotifications:: ', err);
    }
  }

  async getReportExportHistoryByAccId(){
    try{
     await ReportExportHistoryService.getReportExportHistoryByAccId().then((result) => {
        var dowloadsSortedList = [];
        var length= result.length;
        if(length>4){
          for(var i=0;i<4;i++){          
              let str = result[i].destinationUrl;
              let pieces = str.split(/[\s/]+/);
              let last = pieces[pieces.length - 1];
              result[i].fileName=last;          
            dowloadsSortedList.push(result[i]);
          }
        }else{
          for(var i=0;i<result.length;i++){
            let str = result[i].destinationUrl;
            let pieces = str.split(/[\s/]+/);
            let last = pieces[pieces.length - 1];
            result[i].fileName=last;
            dowloadsSortedList.push(result[i]);
          }
        }        

        // console.log("this.state.downloadsData = ", this.state.downloadsData, " | dowloadsSortedList = ", dowloadsSortedList)
        // if (JSON.stringify(this.state.downloadsData) !== JSON.stringify(dowloadsSortedList)){
        //   this.setState({newReportDownload: true});
        //   console.log("New Report Downloads");
        // }

        setTimeout(() => {
          this.setState({downloadsData: dowloadsSortedList});
        }, 1000)
        
        //console.log('notificationSortedList:: data:: ',dowloadsSortedList);
      });
    }
    catch(err){
      console.log('error on getNotifications:: ', err);
    }
  }

  async signOut() {
    try {
      await Auth.signOut();
      //userHasAuthenticated(false);
      //window.location.reload(true);
      //navigationToLogin("/");
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  dismissNotification = (notification) => {
    console.log("Dismiss: ", notification);
    try{
      let notifications = this.state.notificationsData.filter(item => {
        return item.id !== notification.id;
      })

      this.setState({ notificationsData: notifications });
      NotificationService.updateNotificationIsDeleted(notification).then((result) => {
        console.log(result);
      });
    }
    catch (error){
      console.log('error signing out: ', error);
    }
  }

  dissmissAllNotifications = () => {
    console.log("dismiss all notification & alerts")
    for (let i=0; i < this.state.notificationsData.length; i++){
      console.log(this.state.notificationsData[i]);
      this.dismissNotification(this.state.notificationsData[i]);
    }

    this.setState({notificationsData: []});
  }

  componentDidMount() {
    this.getLoggedInUserProfile();
    this.getNotifications();
    this.getReportExportHistoryByAccId();
 
  }

  render () {
    const tenant = this.state.tenantNameState;
    let tenantAlias;
    if (tenant === 'org2_admin') {
      tenantAlias = '[ R&D ]';
    }
    else if (tenant === 'ORG1_ADMIN') {
      tenantAlias = '[ DEMO ]';
    }
    else if (tenant === 'metts_admin') {
      tenantAlias = '[ METTS ]';
    }
    else {
      tenantAlias = [this.state.tenantNameState];
    }

    const alertMenus = Object.entries(this.state.notificationsData).map((make, key) => {
      return (
        <>
          <Menu.Item style={{fontWeight: "400", fontFamily: "'Open Sans', sans-serif"}} className="dropdown-item" key={key + 1} >
          
            <div className="preview-thumbnail">
              <ReactLink className="a-href-highlight-remove cursor-pointer m-0" to='/notifications'>
                <div className="d-flex preview-icon bg-gradient-light">
                  <i className="mdi mdi-bell-outline"></i>
                  &nbsp;&nbsp;&nbsp;
                  <p style={{display: "inline-block", fontWeight: "600",fontSize:"0.8rem"}} className="mb-0">{make[1].deviceName}</p>
                </div>
              </ReactLink>
              <div style={{float: "right", width: "30px"}}>
                <span className="a-href-highlight-remove cursor-pointer m-0">
                  <i onClick={(e) => {e?.stopPropagation(); this.dismissNotification(make[1])}} style={{float: "right"}} className="mdi mdi-18px mdi-minus-circle-outline close-alert"></i>
                </span>
              </div>
            </div>
            <div className="preview-item-content align-items-start flex-column justify-content-center ps-4">
              <ReactLink className="a-href-highlight-remove cursor-pointer m-0" to='/notifications'>
                <p style={{fontSize:"0.8rem"}} className="ellipsis mb-0 text-wrap"> {make[1].message}</p>
              </ReactLink>

            </div>
     
          </Menu.Item>
        </>
      )
    });

    const downloadsMenus = Object.entries(this.state.downloadsData).map((make, key) => {
      return (
        <Menu.Item style={{fontFamily: "'Open Sans', sans-serif"}} className="dropdown-item preview-list" href="/reportDownloads" key={key + 1} >
          <ReactLink className="a-href-highlight-remove cursor-pointer m-0" to='/reportDownloads'>
            <div className="preview-thumbnail">
              <div className="d-flex preview-icon bg-gradient-light">
                <i className="mdi mdi-download-outline"></i>
                &nbsp;&nbsp;&nbsp;
                {make[1].fileName === ''? <div><i><CloseOutlined style={{color: 'red'}}/> Sorry! Report failed to generate!</i></div>:
                          <p style={{display: "inline-block", fontWeight: "600",fontSize:"0.8rem"}} className="mb-2 text-wrap">{make[1].fileName}</p>
                              }
              </div>
            </div>
            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center ps-4">
             
              <p  style={{fontSize:"0.8rem"}} className="ellipsis mb-0 text-wrap"> {make[1].downloadStatus}</p>
            </div>
          </ReactLink>
        </Menu.Item>
      )
    });

    let alertsMenu = (
      <div>
      <Menu className="pt-0">
        <Menu.Item key="-1" className='dropdown-menu-header bg-azure px-4 m-0' >
          <ReactLink className="a-href-highlight-remove text-center cursor-pointer m-0" to='/notifications'>
            <h6 style={{color: "white"}} className="px-3 pt-1">
            Alerts & Notifications
            </h6>
          </ReactLink>
          
        </Menu.Item>
        {this.state.notificationsData.length === 0 ? 
          <Menu.Item key="-2" className="dropdown-item preview-item">       
            <div className="dropdown-divider"></div>
            <ReactLink className="a-href-highlight-remove text-center cursor-pointer m-0" to='/notifications'>
              <h6 className="p-2 mb-0 text-center cursor-pointer"><i className="mdi mdi-bell-outline"></i>
                &nbsp;&nbsp; No new notifications</h6> 
            </ReactLink>
          </Menu.Item> : null  
        }
        {alertMenus}
        <Menu.Divider />
        <Menu.Item className={this.state.notificationsData.length === 0 ? "disabled btn border-0" : ""} onClick={() => this.dissmissAllNotifications()} key="0">
        
          <span className="border-0 a-href-highlight-remove text-center cursor-pointer m-0">
            <h6 className="px-3">
              {/* See all alerts & notifications */}
              Dismiss All
            </h6>
          </span>
        </Menu.Item> 
      </Menu>
      </div>
    );

    

    let downloadsMenu = (
      <Menu className="pt-0">
        <Menu.Item key="-1" className='dropdown-menu-header bg-azure px-4 m-0' >
          <ReactLink className="a-href-highlight-remove text-center cursor-pointer m-0" to='/reportDownloads'>
            <h6 style={{color: "white"}} className="px-3 pt-1">
            Report Downloads
            </h6>
          </ReactLink>
          
        </Menu.Item>
        {this.state.downloadsData.length === 0 ? 
          <Menu.Item key="-2" className="dropdown-item preview-item" href="/reportDownloads">       
            <div className="dropdown-divider"></div>
            <h6 className="p-2 mb-0 text-center cursor-pointer">
              No new downloads
            </h6> 
          </Menu.Item> : null  
        }
        {/* <Menu.Divider /> */}
        {downloadsMenus}
        <Menu.Divider />
        <Menu.Item key="0">
          <ReactLink className="a-href-highlight-remove text-center cursor-pointer m-0" to='/reportDownloads'>
            <h6 className="px-3">
              See all downloads
            </h6>
          </ReactLink>
        </Menu.Item>
      </Menu>
    );
  
    let profileMenuItems = (
      <Menu>
        <Menu.Item key="4">            
          <div className="dropdown-item align-items-center justify-content-between" href="!#" onClick={evt => evt.preventDefault()}>
            <span onClick={this.signOut}>Log out</span>
            &nbsp;&nbsp;
            <i className="mdi mdi-logout ml-1" onClick={this.signOut}></i>
          </div>
        </Menu.Item>
      </Menu>
    );
  
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a className="navbar-brand brand-logo" href="/"><img src={require('../assets/images/chorus-rgb.png')} style={{height: "44px", width: "140px", marginTop: "5px"}} alt="logo" /></a>
          <a className="navbar-brand brand-logo-mini" href="/"><img src={require('../assets/images/Chorus_App_Bug_Favicon.png')} style={{height: "23px"}} alt="logo" /></a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center navbar-toggler-focus" type="button" onClick={ () => {document.body.classList.toggle('sidebar-icon-only');this.menu();} }>
            {this.state.collapsedMenuState=== true?<span className="mdi mdi-24px mdi-menu-open"></span>:
            <span className="mdi mdi-24px mdi-menu"></span>}
          </button>
          
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item">
              <Dropdown overlay={alertsMenu} trigger={['click']}>
                <a onClick={(e) => {e.preventDefault(); this.onClickNotification();}} className="ant-dropdown-link nav-link count-indicator hide-carret">
                  <i className="mdi mdi-bell-outline"></i>
                  {this.state.newNotification && this.state.notificationsData.length > 0 ? <span className="count-symbol bg-danger"></span> : null }
                </a>
                
              </Dropdown>
            </li> 

            <li className="nav-item">
              <Dropdown overlay={downloadsMenu} trigger={['click']}>
                <a onClick={(e) => {e.preventDefault(); this.onClickDownloads();}} className="ant-dropdown-link nav-link count-indicator hide-carret">
                    <i className="mdi mdi-24px mdi-download-outline"></i>
                    {this.state.newReportDownload && this.state.downloadsData.length > 0 ? <span className="count-symbol bg-danger"></span> : null}
                </a>
                
              </Dropdown>
                
            </li>

            <li className="nav-item nav-profile nav-language">

              <Dropdown overlay={profileMenuItems} trigger={['click']}>
                <a onClick={e => e.preventDefault()} className="ant-dropdown-link nav-link count-indicator hide-carret">
                  <div className="nav-profile-text font-weight-normal">
                    <p className="mb-1 text-black">{this.state.userNameState}</p>
                    {tenantAlias}
                  </div> &nbsp;
                  <div className="nav-profile-img">
                    <img src={require("../assets/faces/face1.jpg")} alt="profile" />
                  </div>
                </a>
              </Dropdown>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Header;
