import * as React from 'react';
import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom'
import '../../ota-update/otaUpdate.scss';

var moment = require('moment'); // require

export class FormValidation extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            visible: false,                          
        };
    }

    onCancel = () => {      
        this.setState({ visible: false });       
        this.props.parentCallback(false);
    }

    //Submit function
    closeModal = () => {       
        this.setState({ visible: false });
        this.props.parentCallback(false);             
    }

    componentDidUpdate(prevProps){
        if (prevProps.visible !== this.props.visible){
            this.setState({ visible: this.props.visible });
        }
    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                <Modal
                    centered={true}
                    open={this.state.visible}
                    title='Sorry!'
                    headStyle={{ backgroundColor: 'grey' }}
                    onOk={this.onCancel}
                    width={600}
                    bodyStyle={{
                        height: 100
                    }}
                >  
                <h6>this.props.dialogMessage</h6>                 
                </Modal>
            </div>
        )
    }
}

export default React.memo(FormValidation);