import { API  } from 'aws-amplify';
import { queryTopStateTelemetryByDevIdAccId,queryStateTelemetryByAccIdDevIdTime,queryStateTelemetryDataByAccIdDevIdTimeAndLimit} from '../graphql/queries';
import React from 'react';
import authService from './AuthService';
const getAuthToken = () => 'custom-authorized';
const lambdaAuthToken = getAuthToken();  
 var accountId= '';
 var moment = require('moment'); // require
class DeviceStateService extends React.Component{


async getTopStateTelemetryByDevIdAccId(deviceId,limit){
    //console.log('getDevicesByAccId()',accountId);
    try {
        accountId = (await authService.getCurrentUser()).accountId;
        const top10Statetelemetry = await API.graphql({query:queryTopStateTelemetryByDevIdAccId(accountId,deviceId,limit),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})
        const statetelemetryList = top10Statetelemetry.data.deviceStateByDate.items;
    
        //console.log("getDevicesByAccId::deviceListSort: ",statetelemetryList);
        return statetelemetryList;
    } catch (err) { 
          console.log('Error Occured getDevicesByAccId:: ',err); return null; 
    }
}


    
async getStateTelemetryDataByAccIdDevIdTime(deviceId,fromTime,currentTime,limit){
    var stateDataChunk = [];
    var stateDataForReports = [];
    let nextToken = '';
    //console.log('deviceId in reports--',deviceId);
    try{
        accountId = (await authService.getCurrentUser()).accountId;       
        stateDataChunk = await API.graphql(({query:queryStateTelemetryByAccIdDevIdTime(accountId,deviceId,fromTime,currentTime,limit,nextToken),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));
        await stateDataForReports.push.apply(stateDataForReports,stateDataChunk.data.deviceStateByDate.items);
        nextToken = await stateDataChunk.data.deviceStateByDate.nextToken; 
        while(nextToken!=null){
           // console.log("Deep dive for more data at: ",moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z", "with limit: "); 
            stateDataChunk = [];
            stateDataChunk = await API.graphql(({query:queryStateTelemetryByAccIdDevIdTime(accountId,deviceId,fromTime,currentTime,limit,nextToken),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken})); 
            //console.log("Got next state chunk with total records: ",stateDataChunk.data.listDeviceStates.items.length);
            //console.log("State Fetched list: ",stateDataChunk.data.listDeviceStates.items); 
            nextToken = stateDataChunk.data.deviceStateByDate.nextToken;
            //console.log('nextToken---',nextToken);
            stateDataForReports.push.apply(stateDataForReports,stateDataChunk.data.deviceStateByDate.items);
        }      
      
      for(var i=0;i<stateDataForReports.length;i++){
        stateDataForReports[i].eventDate = moment(stateDataForReports[i].eventDate).local().format('YYYY-MM-DD HH:mm:ss')
      }
      
    return stateDataForReports;
     
    }catch(err){ console.log('Error Occured :: getStateTelemetryDataByAccIdDevIdTime() ', err); return null; };
}



    
async getStateTelemetryDataByAccIdDevIdTimeAndLimit(deviceId,fromTime,limit){
    var stateDataChunk = [];
    var stateDataForReports = [];
   
    //console.log('deviceId in reports--',deviceId);
    try{
        accountId = (await authService.getCurrentUser()).accountId;      
        stateDataChunk = await API.graphql(({query:queryStateTelemetryDataByAccIdDevIdTimeAndLimit(accountId,deviceId,fromTime,limit),authMode: 'AWS_LAMBDA',authToken: lambdaAuthToken}));
        stateDataForReports = stateDataChunk.data.deviceStateByDate.items;
       // console.log("Got all state records: ",stateDataForReports.length, "in: ",moment(moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS")+"Z").diff(moment(_tempStartDateTime),'seconds'), " seconds");
      for(var i=0;i<stateDataForReports.length;i++){                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
        stateDataForReports[i].eventDate = moment(stateDataForReports[i].eventDate).local().format('YYYY-MM-DD HH:mm:ss')
      }
      return stateDataForReports;
    }catch(err){ console.log('Error Occured :: getStateTelemetryDataByAccIdDevIdTimeAndLimit() ', err); return null; };
}
}
export default new DeviceStateService();