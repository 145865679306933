import * as React from 'react';
import SharedService from "../../../services/SharedService";
import '../../ota-update/otaUpdate.scss';
import { Button, Form, Modal, Select, Input } from 'antd';
const { Option } = Select;
var listOfModes = ["SENSE_ONLY", "OPERATIONAL", "STANDBY", "RESEARCH", "OFFLINE"];
export class FleetCommand extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            loading: false,
            deviceList: [],
            modalHeader: '',
            selectedDeviceUdi: 'Select Device',
            filteredDeviceList: [],
            disabled: true,
            spinLoader: true,
            selectedMode: 'Select Mode',
            fleetDeviceList: [],
            userRole: '',
            selectedDeviceList: []
        };
    }

    onCancel = () => {
        this.setState({ visible: false });
        this.props.parentCallback(false);
    }

    handleDeviceSelect(selectedDeviceList) {
        let fleetDeviceList = [];
        console.log('device select--', selectedDeviceList);
        for (let i = 0; i < selectedDeviceList.length; i++) {
            for (let j = 0; j < this.state.deviceList.length; j++) {
                if (selectedDeviceList[i] === this.state.deviceList[j].deviceName) {
                    fleetDeviceList.push(this.state.deviceList[j]);
                }
            }
        }
        this.setState({ selectedDeviceList: fleetDeviceList });
        console.log('Selected Devices--', fleetDeviceList)

    }

    componentDidMount(){
        this.setState({ visible: this.props.visible })
        this.setState({selectedDeviceUdi : 'Select Device'})
        this.setState({ modalHeader: 'Fleet command & control' });
        this.setState({ userRole: this.props.userRoleData });
        if (this.props.deviceList !== undefined) {         //fetch deviceList from single-device Component
            if (this.props.deviceList.length === 0) {
                this.setState({ deviceList: [] });
            } else {
                this.setState({ deviceList: this.props.deviceList });
            }
        }
    }

    sendCommandForAllDevices = () => {
        try {
            for (var i = 0; i < this.state.selectedDeviceList.length; i++) {
                var deviceUUID = this.state.selectedDeviceList[i].deviceId;
                var udi = this.state.selectedDeviceList[i].deviceName;
                var mode = this.state.selectedMode; //dynamic fetch from onclick
                console.log('device uuid--', deviceUUID, 'udi--', udi, 'mode--', mode)
                SharedService.sendCommandForChangeMode(deviceUUID, udi, mode).then((resultCommand) => {
                    console.log('command receive from device:: ', resultCommand)
                });
            }
            this.setState({ visible: false });
            this.props.parentCallback(false);
        } catch (err) { console.log('Error Occured :: sendCommandForAllDevices() ', err); return null; };
    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                <Modal
                    centered={true}
                    open={this.state.visible}
                    title={this.state.modalHeader}
                    headStyle={{ backgroundColor: 'grey' }}
                    onOk={this.sendFleetCommand}
                    okButtonProps={{ disabled: true }}
                    cancelButtonProps={{
                        disabled: true,
                    }}
                    onCancel={this.onCancel}
                    width={500}
                    footer={[
                        <Button key="back" onClick={this.onCancel}>
                            Cancel
                        </Button>,
                        <>&nbsp;
                            <Button type="primary" onClick={this.sendCommandForAllDevices}>
                                Submit</Button>
                        </>
                    ]}  >
                    <Form layout="vertical" requiredMark={true} name="control-ref" >
                        <Form.Item
                            name="Device"
                            label="Select one or more device(s)" >
                            <Select mode="multiple" placeholder="Select Device(s)" style={{ width: '100%' }} showArrow value={this.state.selectedDeviceUdi}
                                onChange={(e) => this.handleDeviceSelect(e)} >
                                {this.state.deviceList.map((make, index) => (
                                    <Option key={make.deviceId} value={make.deviceName}>{make.nickname}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="mode"
                            label="Target mode">
                            <Select style={{ width: '100%' }} placeholder="Select target mode"
                                onChange={(e) => this.setState({ selectedMode: e })}   >
                                <Option value='OPERATIONAL'>OPERATIONAL</Option>
                                <Option value='SENSE_ONLY'>SENSE ONLY</Option>
                                <Option value='STANDBY'>STANDBY</Option>
                                {this.state.userRole === 'USER' || this.state.userRole === '' || this.state.userRole === null ?
                                    <></> : <Option value='RESEARCH'>RESEARCH</Option>}

                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default FleetCommand;